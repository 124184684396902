import qs from 'query-string';
import { LOGIN_URL, SALESFORCE_LOGIN_URL } from 'src/constants';

import { jsonHeaders } from './utils';

const defaultErrorMessage = 'Unexpected error';

const baseFetch = (url: string, headers?, options?) =>
  fetch(url, {
    method: 'GET',
    headers: {
      ...jsonHeaders,
      ...headers
    },
    ...options
  });

export const getRedirectUrl = async (
  username: string,
  popup: boolean,
  deploymentId?: number
): Promise<AuthRedirectUrlResponse> => {
  const params = qs.stringify({
    username,
    platform: popup ? 'chrome' : 'web',
    callbackUrl: popup ? undefined : window.location.href,
    chromeReferrer: document.location.href,
    deploymentId
  });
  const response = await baseFetch(`${SALESFORCE_LOGIN_URL}?${params}`);

  if (response.ok) {
    const successResponse: AuthRedirectUrlSuccess = await response.json();
    return {
      success: true,
      url: successResponse.redirectUrl
    };
  }

  try {
    const errorResponse: AuthRedirectUrlError = await response.json();
    return {
      success: false,
      message: errorResponse.message || defaultErrorMessage
    };
  } catch (e) {
    return {
      success: false,
      message: defaultErrorMessage
    };
  }
};

export const login = async (token: string, refreshToken: string): Promise<AuthResponse> => {
  const body = JSON.stringify({ token, refreshToken });
  const response = await fetch(LOGIN_URL, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    const authToken = await response.json();
    return {
      success: true,
      token: authToken.token
    };
  }

  return {
    success: false,
    message: defaultErrorMessage
  };
};
