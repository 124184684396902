import { omit, pick } from 'ramda';

import { SLOT_OPTION } from './constants';
import { makeInternalId } from './services/utils';
import { RawAdministratorData } from './hooks/api/Administrators/queries';

export const mapSlot = (slot: SlotType) => {
  const type = slot.type === 'Decision Point' ? SLOT_OPTION.DECISION_POINT : SLOT_OPTION.PROMPT;

  return {
    ...pick(['id', 'node_path', 'name', 'detail'], slot),
    type,
    field_id: slot.slot_field?.field_id,
    ...(type === SLOT_OPTION.DECISION_POINT && {
      condition_true: slot.condition_true?.map(mapSlot),
      condition_false: slot.condition_false?.map(mapSlot)
    }),
    internal_id: makeInternalId()
  };
};

export const formatDeployment = (data: DeploymentType) => omit(['used_licenses'], data);

export const formatAdministrator = (data: RawAdministratorData) =>
  pick(['first_name', 'last_name', 'profile_image_url', 'email'], data);

export const formatOrganization = (data: OrganizationType) => pick(['name', 'licenses', 'is_active'], data);
