import { ToggleSwitch } from '../../../ToggleSwitch';
import React, { useState } from 'react';
import { EmailChip } from '../../../Chip';
import { CommonButton } from '../../../Buttons/CommonButton';
import { GroupFieldWrapper, SingleLineWrapper, TagContainer, Wrapper } from './Common';
import { FieldLabel } from '../../Common';

export const PicklistValueSettings = ({ value, setData }) => {
  const { multi_picklist } = value;

  const initialPicklist = [
    { label: 'Not Started', value: 'Open' },
    { label: 'In Progress', value: 'On Hold' },
    { label: 'Completed', value: 'In Progress' }
  ];

  const [picklistValues, setPicklistValues] = useState(initialPicklist);

  const removePicklistValue = valueToRemove => {
    const updatedState = picklistValues.filter(value => value.value !== valueToRemove);
    setPicklistValues(updatedState);
  };

  const resetPicklistValues = () => setPicklistValues(initialPicklist);

  return (
    <Wrapper>
      <FieldLabel label='Picklist Settings' info='Select the object name' />
      {!!value && (
        <GroupFieldWrapper>
          <SingleLineWrapper>
            <FieldLabel label='Multi Selection?' info='Select the object name' />
            <ToggleSwitch
              on={!!multi_picklist}
              handleToggle={value => setData('multi_picklist', value.target.checked)}
              name={'multi_picklist'}
              isDisabled={false}
            />
          </SingleLineWrapper>
          <SingleLineWrapper>
            <FieldLabel label='Values' info='Select the object name' />
            <CommonButton color='greyDark' label='Reset' onClick={resetPicklistValues} />
          </SingleLineWrapper>
          <TagContainer>
            {picklistValues.map(item => (
              <EmailChip icon={false} item={item.label} handleDelete={removePicklistValue} />
            ))}
          </TagContainer>
        </GroupFieldWrapper>
      )}
    </Wrapper>
  );
};
