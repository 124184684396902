import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { colors } from '../../../theme';
import { ToolTip } from '../../ToolTip';
import { Body } from '../../../theme/typography/Text';
import { BaseIconProps } from '../../Icon/BaseIcon';

interface SimpleButtonProps {
  icon?: React.FC<BaseIconProps>;
  disabled?: boolean;
  label?: string;
  onClick: (data: any) => void;
  outline?: boolean;
  badge?: string;
  style?: React.CSSProperties;
  info?: string;
}

const BadgeWrapper = styled.div(({ color }) => {
  return css`
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 20px;
    border-radius: 4px;
    background-color: ${colors[color]};
    position: relative;
    top: -8px;
    right: -40px;
  `;
});

const BadgeLabel = styled.p`
  font-family: Lato, serif;
  font-weight: 400;
  color: white;
  text-align: center;
`;

const StyledBody = styled(Body)<{ disabled: boolean }>`
  margin: 0px;
  color: ${({ disabled, theme }) => (disabled ? theme.texts.disabled : theme.texts.primary)};
`;

const Badge = ({ number, color = 'darkPurple' }) => (
  <BadgeWrapper color={color}>
    <BadgeLabel>{number}</BadgeLabel>
  </BadgeWrapper>
);

const ButtonWrapper = styled.button<{ disabled: boolean; outline?: boolean }>(
  ({ disabled, outline, theme }) => css`
    border: none;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    display: flex;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    ${outline
      ? css`
          border: 2px solid ${theme.borders.first};
          border-radius: 4px;
          padding: 9px;
        `
      : null}
  `
);

export const SimpleButton: React.FC<SimpleButtonProps> = ({
  icon: IconComponent,
  info,
  label,
  badge,
  onClick,
  outline = false,
  disabled = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <ToolTip message={info}>
      <ButtonWrapper onClick={onClick} disabled={disabled} {...props} outline={outline}>
        {badge ? <Badge number={badge} /> : null}
        {IconComponent ? <IconComponent color={disabled ? theme.icons.disabled : theme.icons.primary} /> : null}
        {label ? <StyledBody disabled={disabled}>{label}</StyledBody> : null}
      </ButtonWrapper>
    </ToolTip>
  );
};
