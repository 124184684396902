import React, { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { CommonSelect } from '../../../Select';
import { Line } from 'react-chartjs-2';
import { Title2 } from '../../../../theme/typography/Text';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const labels = ['10', '20', '30', '40', '50', '60', '70'];

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.layers.first};
  flex: 1;
  margin-right: 20px;
  display: flex;
  height: 280px;
  padding: 20px;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
`;

const ChartWrapper = styled.div`
  max-height: 400px;
  padding: 10px;
  display: flex;
  width: 99%;
  flex: 1;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FirstBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SecondBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledTitle = styled(Title2)`
  margin: 0;
`;

const StyledLegend = styled.p`
  font-family: 'Open Sans', serif;
  font-size: 18px;
  color: ${({ theme }) => theme.texts.primary};
`;

const StyledPercentage = styled.div(({ color }) => {
  return css`
    font-family: 'Open Sans', serif;
    font-weight: 700;
    font-size: 18px;
    color: ${color};
  `;
});

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
  { value: 'blue', label: 'Blue', color: '#0052CC' },
  { value: 'purple', label: 'Purple', color: '#5243AA' }
];

export const ComparingLineChart = () => {
  const theme = useTheme();

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            display: false
          }
        },
        y: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false,
            drawBorder: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      }
    }),
    [theme]
  );

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: labels.map(() => 10 * Math.random()),
          borderColor: theme.colors.primary,
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: 'Dataset 2',
          data: labels.map(() => 10 * Math.random()),
          borderColor: theme.colors.secondary,
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    }),
    [theme]
  );

  return (
    <Wrapper>
      <FirstBlock>
        <StyledTitle>Usage statistic</StyledTitle>
        <ChartWrapper>
          <Line options={options} data={data} />
        </ChartWrapper>
      </FirstBlock>
      <SecondBlock>
        <CommonSelect options={colourOptions} value={{ label: 'Success Conversations', value: '1' }} />
        <LegendWrapper>
          <StyledLegend>New York Sales</StyledLegend>
          <StyledPercentage color={theme.colors.primary}>77%</StyledPercentage>
        </LegendWrapper>
        <LegendWrapper>
          <StyledLegend>Los Angeles Sales</StyledLegend>
          <StyledPercentage color={theme.colors.secondary}>75%</StyledPercentage>
        </LegendWrapper>
      </SecondBlock>
    </Wrapper>
  );
};
