import { ToggleSwitch } from '../../../ToggleSwitch';
import React, { useState } from 'react';
import { ActivitySubtitle, GroupFieldWrapper, SingleLineWrapper, TagContainer, Wrapper } from './Common';
import { FieldLabel } from '../../Common';
import MiniTable from './MiniTable';
import CollapsibleTab from '../../../Tab';
import { EmailChip } from '../../../Chip';
import { colors } from '../../../../theme';

const accountFields = [
  { label: 'Account Name', id: 'Name' },
  { label: 'Account Number', id: 'AccountNumber' },
  { label: 'Account Owner', id: 'OwnerId' },
  { label: 'Account Site', id: 'AccountSite' },
  { label: 'Account Source', id: 'AccountSource' },
  { label: 'Annual Revenue', id: 'AnnualRevenue' },
  { label: 'Billing Address', id: 'BillingAddress' },
  { label: 'Billing City', id: 'BillingCity' },
  { label: 'Billing Country', id: 'BillingCountry' },
  { label: 'Billing Postal Code', id: 'BillingPostalCode' },
  { label: 'Billing State', id: 'BillingState' },
  { label: 'Billing Street', id: 'BillingStreet' },
  { label: 'Created By', id: 'CreatedBy' },
  { label: 'Created Date', id: 'CreatedDate' },
  { label: 'Description', id: 'Description' },
  { label: 'Fax', id: 'Fax' },
  { label: 'Industry', id: 'Industry' },
  { label: 'Last Modified By', id: 'LastModifiedBy' },
  { label: 'Last Modified Date', id: 'LastModifiedDate' },
  { label: 'Number of Employees', id: 'NumberOfEmployees' },
  { label: 'Phone', id: 'Phone' },
  { label: 'Shipping Address', id: 'ShippingAddress' },
  { label: 'Shipping City', id: 'ShippingCity' },
  { label: 'Shipping Country', id: 'ShippingCountry' },
  { label: 'Shipping Postal Code', id: 'ShippingPostalCode' },
  { label: 'Shipping State', id: 'ShippingState' },
  { label: 'Shipping Street', id: 'ShippingStreet' },
  { label: 'Sic', id: 'Sic' },
  { label: 'Sic Description', id: 'SicDesc' },
  { label: 'Site', id: 'Site' },
  { label: 'Ticker Symbol', id: 'TickerSymbol' },
  { label: 'Type', id: 'Type' },
  { label: 'Website', id: 'Website' }
];

export const LookupPanel = ({ value, setData }) => {
  const { multi_lookup, object_name } = value;
  const [selectedFields, setSelectedFields] = useState([]);

  const removeSelectedFields = valueToRemove => {
    const updatedState = selectedFields.filter(value => value.id !== valueToRemove);
    console.log(updatedState);
    setSelectedFields(updatedState);
  };

  return (
    <Wrapper>
      <FieldLabel label='Lookup Settings' info='Select the object name' />
      {!!value && (
        <GroupFieldWrapper>
          <SingleLineWrapper>
            <FieldLabel label='Multi Selection?' info='Select the object name' />
            <ToggleSwitch
              on={!!multi_lookup}
              handleToggle={value => setData('multi_lookup', value.target.checked)}
              name={'multi_lookup'}
              isDisabled={false}
            />
          </SingleLineWrapper>
          <CollapsibleTab title='Display Settings' info='Column info'>
            <MiniTable
              lookupFields={accountFields}
              objectName={object_name?.label || ''}
              selectedFields={selectedFields}
              maxItems={4}
              setSelectedFields={setSelectedFields}
              columns={[
                { label: 'Name', key: 'name' },
                { label: 'Id', key: 'id' }
              ]}
            />
            <TagContainer style={{ maxHeight: 120, borderRadius: 8, marginTop: 10 }}>
              {selectedFields.map(item => (
                <EmailChip icon={false} item={item} handleDelete={removeSelectedFields} />
              ))}
              {!selectedFields.length && (
                <ActivitySubtitle style={{ color: colors.greyLight, textAlign: 'center' }}>
                  Columns to display will be shown here
                </ActivitySubtitle>
              )}
            </TagContainer>
            <ActivitySubtitle style={{ marginTop: 5, color: colors.darkPurple, textAlign: 'center' }}>
              Max 4 columns
            </ActivitySubtitle>
          </CollapsibleTab>
        </GroupFieldWrapper>
      )}
    </Wrapper>
  );
};
