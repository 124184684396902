import Lottie from 'lottie-react';
import styled from 'styled-components';
import spinnerData from '../assets/lottie/spinner.json';
import { RollioTheme } from '../theme/RollioTheme';
import React from 'react';

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25;
`;

const LoadingWrapper = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

interface Props {
  loop?: boolean;
  size?: number;
  onPrimary?: boolean;
}

interface SpinnerProps {
  size?: number;
  fullscreen?: boolean;
  onPrimary?: boolean;
}

const AnimatedSpinner = styled(Lottie).attrs<Props>(({ loop = true }) => ({
  loop
}))<{ theme: RollioTheme; onPrimary: boolean }>`
  width: ${({ size = 40 }) => size}px;
  height: ${({ size = 40 }) => size}px;

  svg {
    path,
    g {
      fill: ${({ theme, onPrimary }) => (onPrimary ? theme.layers.first : theme.colors.primaryVariant)};
      stroke: ${({ theme, onPrimary }) => (onPrimary ? theme.layers.first : theme.colors.primary)};
    }
  }
`;

const Spinner: React.FC<SpinnerProps> = ({ onPrimary = false, size, fullscreen }) =>
  fullscreen ? (
    <Backdrop>
      <LoadingWrapper>
        <AnimatedSpinner animationData={spinnerData} onPrimary={onPrimary} size={size} />
      </LoadingWrapper>
    </Backdrop>
  ) : (
    <AnimatedSpinner animationData={spinnerData} onPrimary={onPrimary} size={size} />
  );
export default Spinner;
