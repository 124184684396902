import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOrganizationDatasources, useOrganizationsListData } from './api/Organizations/hooks';
import {
  getActiveDatasource,
  getActiveDeployment,
  getActiveOrganization,
  getDatasources,
  getDeployments,
  getOrganizations,
  setActiveDatasource,
  setActiveDeployment,
  setActiveOrganization,
  setDatasources,
  setDeployments,
  setOrganizations
} from '../store/reducers/globalFilter';
import { useDeploymentsByDatasource } from './api/Deployments/hooks';

interface RoleFilterType {
  name: string;
}

interface OrganizationFilterType {
  name: string;
  id: string;
}

export const useGlobalFilter = (role: RoleFilterType, organization: OrganizationFilterType) => {
  //ToDo: Implement the logic to fetch all by role
  const fetchAllOrganizations = role.name === 'Internal';
  const { id, name } = organization;
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const datasources = useSelector(getDatasources);
  const deployments = useSelector(getDeployments);
  const selectedOrg = useSelector(getActiveOrganization);
  const selectedDatasource = useSelector(getActiveDatasource);
  const selectedDeployment = useSelector(getActiveDeployment);
  const { data: fetchedOrganizations } = useOrganizationsListData({
    offset: 0,
    limit: 200,
    sort: { column: 'name', direction: 'DESC' }
  });
  const { data: fetchedDatasources } = useOrganizationDatasources(
    selectedOrg && selectedOrg.value !== 'all' ? selectedOrg.value : null
  );

  const { data: fetchedDeployments } = useDeploymentsByDatasource(
    selectedDatasource && selectedDatasource.value !== 'all' && selectedDatasource.value !== 'none'
      ? selectedDatasource.value
      : null
  );

  useEffect(() => {
    if (fetchedOrganizations?.data.length) {
      dispatch(setOrganizations(fetchedOrganizations.data));
      if (fetchedOrganizations.data.length === 1) {
        dispatch(setActiveOrganization(fetchedOrganizations.data[0]));
      }
    }
  }, [fetchedOrganizations]);

  useEffect(() => {
    if (fetchedDatasources?.length) {
      dispatch(setDatasources(fetchedDatasources));

      if (fetchedDatasources.length === 1) {
        const { name, id } = fetchedDatasources[0];
        dispatch(setActiveDatasource({ label: name, value: id }));
      } else if (fetchedDatasources.length > 1) {
        dispatch(setActiveDatasource({ label: 'All Datasources', value: 'all' }));
      }
    } else {
      if (datasources.length) {
        dispatch(setDatasources([]));
        dispatch(setActiveDatasource({ label: 'No Datasources', value: 'none' }));
      }
    }
  }, [fetchedDatasources]);

  useEffect(() => {
    if (fetchedDeployments?.length) {
      dispatch(setDeployments(fetchedDeployments));

      if (fetchedDeployments?.length === 1) {
        const { name, id } = fetchedDeployments[0];
        dispatch(setActiveDeployment({ label: name, value: id }));
      } else if (fetchedDeployments.length > 1) {
        dispatch(setActiveDeployment({ label: 'All Deployments', value: 'all' }));
      }
    } else {
      if (datasources.length) {
        dispatch(setDeployments([]));
        dispatch(setActiveDeployment({ label: 'No Deployments', value: 'none' }));
      }
    }
  }, [fetchedDeployments]);

  return {
    organizations,
    deployments,
    datasources,
    selectedOrg,
    selectedDeployment,
    selectedDatasource
  };
};
