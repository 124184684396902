import React, { useMemo } from 'react';
import Select, {
  components,
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  IndicatorSeparatorProps
} from 'react-select';
import styled, { useTheme } from 'styled-components';
import { RollioTheme } from '../../theme/RollioTheme';
import { BaseIconProps } from '../Icon/BaseIcon';
import Spinner from '../Spinner';

export type SelectType = 'common' | 'data' | 'small' | 'plain';

export interface SelectValue {
  label: string;
  value: string | number;
  type?: string;
}

type SelectProps = {
  options: SelectValue[];
  icon?: React.FC<BaseIconProps>;
  disabled?: boolean;
  type?: SelectType;
  placeholder?: string;
  value?: SelectValue;
  isLoading?: boolean;
  onChange?: (newValue: SelectValue) => void;
  minimumOptions?: number;
};

const CommonSelectStyles = (theme: RollioTheme) => ({
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: theme.layers.first,
    borderStyle: 'none',
    color: theme.texts.primary,
    minWidth: 200,
    minHeight: 45,
    maxWidth: 400,
    padding: 4,
    fontFamily: 'Lato',
    borderRadius: '4px'
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontFamily: 'Lato',
    backgroundColor: theme.layers.first,
    color: theme.texts.primary,
    borderRadius: '4px',
    maxWidth: 400
  }),
  menuPortal: (styles: CSSObjectWithLabel) => ({
    ...styles,
    zIndex: 9999
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, color: theme.texts.primary }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? theme.miscellaneous.highlight
      : isFocused
      ? theme.layers.second
      : undefined,
    color: isDisabled ? '#ccc' : isSelected ? theme.texts.onColor : theme.texts.primary,
    maxWidth: 400,
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? (isSelected ? theme.colors.background : theme.layers.second) : undefined
    }
  })
});

const IconWrapper = styled.div`
  max-width: 60px;
  max-height: 60px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommonSelect: React.FC<SelectProps> = ({
  options,
  icon: SelectIcon,
  type = 'common',
  placeholder,
  value,
  onChange,
  disabled,
  isLoading,
  minimumOptions = 2
}) => {
  const isDisabled = useMemo(() => options.length < minimumOptions || disabled, [options, disabled]);
  const theme = useTheme();

  const Styles = useMemo(
    () => ({
      common: CommonSelectStyles(theme)
    }),
    [theme]
  );

  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <components.Control {...props}>
        {SelectIcon ? (
          <IconWrapper>
            <SelectIcon color={theme.icons.primary} />
          </IconWrapper>
        ) : null}
        {children}
      </components.Control>
    );
  };

  const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps) => {
    return isDisabled ? null : <span {...innerProps} />;
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return isDisabled && type !== 'plain' ? null : <components.DropdownIndicator {...props} />;
  };

  const selectComponents = useMemo(
    () =>
      type !== 'data'
        ? {
          DropdownIndicator,
          IndicatorSeparator,
          LoadingIndicator: () => <Spinner size={35} />
        }
        : { DropdownIndicator, Control, IndicatorSeparator },
    [type, Control, DropdownIndicator, IndicatorSeparator]
  );

  return (
    <>
      <Select
        components={selectComponents}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        placeholder={placeholder ?? ''}
        menuShouldScrollIntoView={false}
        isSearchable={true}
        onChange={onChange}
        isLoading={isLoading}
        value={value}
        options={options}
        styles={Styles[type]}
      />
    </>
  );
};
