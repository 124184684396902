import styled from 'styled-components';
import { RollioTheme } from '../../theme/RollioTheme';
import { alpha } from '../../theme/utils';

export interface BaseIconProps {
  disabled?: boolean;
  iconType?: IconType;
  size?: IconSize;
  outline?: boolean;
  color?: string;
}

export type IconType = 'primary' | 'secondary' | 'interactive' | 'error' | 'success' | 'warning' | 'info';
export type IconSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'huge';

const iconSizes: { [key: string]: number } = {
  xsmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 40,
  xxlarge: 48,
  huge: 56
};

const colorByType = (iconType: IconType, theme: RollioTheme) => {
  switch (iconType) {
    case 'primary':
      return theme.icons.primary;
    case 'secondary':
      return theme.icons.secondary;
    case 'interactive':
      return theme.colors.primary;
    case 'error':
      return theme.support.error;
    case 'success':
      return theme.support.success;
    case 'warning':
      return theme.support.warning;
    case 'info':
      return theme.support.info;
  }
};

export const BaseIcon = styled.div.attrs<BaseIconProps & { theme: RollioTheme }>(
  ({ theme, color, size = 'medium', iconType = 'primary' as IconType, disabled }) => ({
    color: alpha(color ?? colorByType(iconType, theme), disabled ? 0.5 : 1),
    size: iconSizes[size]
  })
)``;
