import React from 'react';
import styled from 'styled-components';
import { RollioTheme } from '../../theme/RollioTheme';

interface SwitchProps {
  on: boolean;
  handleToggle: any;
  name: string;
  isDisabled?: boolean;
}

const CheckboxContainer = styled.div`
  position: relative;
`;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const CheckboxLabel = styled.label<{ checked: boolean; theme: RollioTheme }>`
  background-color: ${({ theme, checked }) => (checked ? theme.colors.primary : theme.layers.first)};
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Ball = styled.div<{ checked: boolean }>`
  background-color: ${({ theme, checked }) => (checked ? theme.texts.onColor : theme.miscellaneous.toggleOff)};
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2.2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
  transform: ${({ checked }) => (checked ? 'translateX(24px)' : 'none')};
`;

export const ToggleSwitch: React.FC<SwitchProps> = ({ name, on, handleToggle, isDisabled }) => {
  return (
    <CheckboxContainer>
      <CheckboxInput
        type='checkbox'
        id={name}
        name={name}
        className='checkbox'
        checked={on}
        onChange={!isDisabled ? handleToggle : null}
      />
      <CheckboxLabel htmlFor={name} checked={on}>
        <Ball checked={on} />
      </CheckboxLabel>
    </CheckboxContainer>
  );
};
