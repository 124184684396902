import React from 'react';
import { getBezierPath, Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import { colors } from '../../../theme';
import { IconClose } from '../../Icon/IconClose';
import { IconAdd } from '../../Icon/IconAdd';

export const NODE_TYPES = {
  INITIAL: 'initial',
  ENTITY: 'entity',
  DECISION_POINT: 'decision',
  BOT_ACTION: 'bot',
  CONFIRMATION: 'end'
};

const nodesDesign = {
  initial: {
    icon: 'initial',
    color: colors.green
  },
  entity: {
    icon: 'entity',
    color: 'rgba(119, 189, 211, 0.54)'
  },
  decision: {
    icon: 'decision',
    color: '#97A7E1'
  },
  bot: {
    icon: 'bot',
    color: '#6F86D6'
  },
  end: {
    icon: 'end',
    color: '#000'
  }
};

const entityDesign = {
  picklist: {
    icon: 'picklist',
    color: colors.darkPurple
  },
  lookup: {
    icon: 'lookup',
    color: 'rgba(119, 189, 211, 0.54)'
  },
  date: {
    icon: 'calendar',
    color: '#97A7E1'
  },
  text: {
    icon: 'text',
    color: '#6F86D6'
  }
};

const AddButton = styled.button`
  border-radius: 50px;
  height: 12px;
  width: 12px;
  border: 0.8px solid ${colors.auxiliaryGrey};
  background-color: white;
  flex: 1;
  cursor: pointer;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    background-color: ${colors.inputGrey};
  }

  &:active {
    background-color: ${colors.disabledGrey};
  }
`;

const RemoveButton = styled.button`
  border-radius: 50px;
  height: 10px;
  width: 10px;
  border: 0.8px solid ${colors.auxiliaryGrey};
  background-color: white;
  flex: 1;
  cursor: pointer;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    background-color: ${colors.inputGrey};
  }

  &:active {
    background-color: ${colors.disabledGrey};
  }
`;

const TypeBox = styled.div(
  ({ color }) => css`
    background-color: ${color};
    border-radius: 4px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const NodeWrapper = styled.div<{ isConv: boolean }>(({ isConv }) => {
  return css`
    width: 160px;
    height: 45px;
    border-radius: 4px;
    background-color: #f2f2f2;
    border: ${isConv ? '2px dotted rgba(71, 20, 125, 0.1)' : '1px solid rgba(71, 20, 125, 0.1)'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    position: relative;
  `;
});

const LabelWrapper = styled.div`
  display: flex;
  margin-left: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0;
  color: #000;
`;
const Subtitle = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: 200;
  color: ${colors.greyLight};
  line-height: 10px;
  padding: 0;
  font-size: 8px;
  margin: 0;
`;

const BoolLabel = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: 200;
  color: ${colors.darkPurple};
  line-height: 10px;
  padding: 0;
  font-size: 8px;
  margin: 0;
`;

export const CustomNode = ({ data, id, onContextMenu, isLeafNode, deleteNode }) => {
  const { title, subtitle, type, entity_type, conversation_entity, bool } = data;
  let color = nodesDesign[type].color;
  let IconComponent = nodesDesign[type].icon;

  if (type === 'entity' && !!entity_type) {
    const { value: entityValue } = entity_type;
    color = entityDesign[entityValue].color;
    IconComponent = entityDesign[entityValue].icon;
  } else if (type === 'entity' && !entity_type) {
    color = colors.greyDark;
    IconComponent = 'question';
  }

  return (
    <>
      <NodeWrapper isConv={conversation_entity}>
        {type !== NODE_TYPES.CONFIRMATION && type !== NODE_TYPES.INITIAL && (
          <RemoveButton
            style={{ position: 'fixed', right: -2, top: -3 }}
            onClick={e => {
              e.stopPropagation();
              deleteNode(id);
            }}>
            <IconClose />
          </RemoveButton>
        )}
        {type !== NODE_TYPES.INITIAL && (
          <Handle type='target' position={Position.Top} style={{ borderRadius: 50, height: 2, width: 2 }} />
        )}

        <TypeBox color={color}>
          <IconComponent />
        </TypeBox>
        <LabelWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {bool !== undefined && (
            <BoolLabel>{`${`${bool}`.charAt(0).toUpperCase() + `${bool}`.slice(1)} path`}</BoolLabel>
          )}
        </LabelWrapper>
        {type !== NODE_TYPES.CONFIRMATION && (
          <Handle type='source' position={Position.Bottom} style={{ borderRadius: 50, height: 2, width: 2 }} />
        )}
      </NodeWrapper>
      {isLeafNode && type !== NODE_TYPES.CONFIRMATION && (
        <AddButton
          style={{ position: 'relative', right: '-46.2%', top: 10 }}
          onClick={e => {
            e.stopPropagation();
            onContextMenu(e, { nodeId: id });
          }}>
          <IconAdd />
        </AddButton>
      )}
    </>
  );
};

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  handleContextMenu,
  menuItems,
  source,
  target
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <>
      <path id={id} style={style} className='react-flow__edge-path' d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={20}
        height={20}
        x={labelX - 12 / 2}
        y={labelY - 12 / 2}
        className='edgebutton-foreignobject'
        requiredExtensions='http://www.w3.org/1999/xhtml'>
        <div>
          <AddButton
            onClick={e => {
              e.stopPropagation();
              handleContextMenu(e, { source, target }, menuItems);
            }}>
            <IconAdd />
          </AddButton>
        </div>
      </foreignObject>
    </>
  );
};
