import { createContext, useState } from 'react';
import { getLayoutElements } from '../Logic';
import { useNodeAndEdgeManagement } from '../Hooks';

export const ConversationEditorContext = createContext(null);

export const useConversationContext = ({ initialNodes }) => {
  const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(initialNodes, []);
  const [selectedElement, setSelectedElement] = useState(null);
  const { nodes, edges, addNodeBetween, addChild, deleteNode, endConversationFlow, updateNode, reset } =
    useNodeAndEdgeManagement(layoutNodes, layoutEdges);
  const { crmObjects } = {
    crmObjects: {
      Account: {
        type: 'lookup'
      }
    }
  };

  return {
    nodes,
    edges,
    reset,
    addNodeBetween,
    selectedElement,
    setSelectedElement,
    addChild,
    deleteNode,
    updateNode,
    endConversationFlow
  };
};
