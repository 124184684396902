import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useAddNewDatasource } from '../../hooks/api/Datasources/hooks';
import { instance } from '@rollioforce/rollio-schemas';
import { Modal } from '../../components/Modals/Modal';
import { usePermission } from '../../hooks';
import { useSelector } from 'react-redux';
import { getActiveOrganization, getOrganizations } from '../../store/reducers/globalFilter';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import Spinner from '../../components/Spinner';

interface NewDatasourceModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const NewDatasourceModal: React.FC<NewDatasourceModal> = ({ visible, setVisible }) => {
  const { mutate: addRecord, isLoading } = useAddNewDatasource(() => setVisible(false));
  const { isInternal } = usePermission();
  const activeOrg = useSelector(getActiveOrganization);
  const organizations = useSelector(getOrganizations);

  const ORGANIZATIONS_SELECT = useMemo(
    () =>
      !isInternal
        ? [activeOrg]
        : organizations.map(i => ({
          label: isInternal ? `${i.name} [${i.id}]` : i.name,
          value: i.id
        })),
    [organizations, activeOrg, isInternal]
  );

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        placeholder: 'Enter the Datasource Name',
        type: 'text'
      },
      {
        key: 'organization_id',
        label: 'Organization',
        type: 'select',
        options: ORGANIZATIONS_SELECT
      },
      {
        key: 'login_url',
        label: 'Login Url',
        info: 'This is the Salesforce Login URL.',
        placeholder: 'Enter the Salesforce Login URL',
        type: 'text'
      },
      {
        key: 'is_active',
        label: 'Active',
        type: 'checkbox',
        isVisible: isInternal
      }
    ],
    [ORGANIZATIONS_SELECT]
  );

  const onFormSubmit = useCallback(
    (data: OrganizationInstanceType) => {
      try {
        addRecord(data);
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  return (
    <Modal isOpen={visible} title='Create New Datasource' onCloseClick={() => setVisible(false)}>
      {isLoading ? <Spinner fullscreen /> : null}
      <GenericForm
        name='new_datasource'
        columns={1}
        initialValues={{
          name: '',
          login_url: '',
          organization_id: activeOrg.value !== 'all' ? Number(activeOrg.value) : Number(ORGANIZATIONS_SELECT[0].value),
          is_active: true
        }}
        fields={FORM_FIELDS}
        schema={instance}
        onSubmit={onFormSubmit}
        modal
      />
    </Modal>
  );
};
