import { colors } from '../../../../../theme';
import styled from 'styled-components';

export const TagContainer = styled.div`
  display: block;
  flex: 1;
  overflow-x: hidden;
  background-color: ${colors.inputGrey};
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.disabledGrey};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: transparent;
  }
`;

export const SingleLineWrapper = styled.div`
  display: flex;
  margin: 15px 0 5px 0;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  font-family: 'Open Sans', serif;
  display: inline-block;
  font-size: 18px;
  color: black;
  margin: 0 5px 5px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const GroupFieldWrapper = styled.div`
  margin: 5px 0 5px 0;
  padding: 15px;
  width: 100%;
  border: 1px solid rgba(71, 20, 125, 0.1);
  background-color: #f2f2f2;
  border-radius: 5px;
`;

export const ActivitySubtitle = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: 400;
  color: ${colors.selectBlue};
  font-size: 0.8rem;
  line-height: 1;
  margin: 0;
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
