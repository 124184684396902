import { useState } from 'react';

export const useRowSelectorHook = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isSelected = (id: string) => selectedIds.includes(id);

  const deselectAll = () => setSelectedIds([]);

  const selectAll = rows => setSelectedIds(rows.map(row => row.id));

  const toggleSelected = (id: string) => {
    setSelectedIds(prevIds => {
      if (isSelected(id)) {
        return prevIds.filter(prevId => prevId !== id);
      }
      return [...new Set([...prevIds, id])];
    });
  };

  return { selectedIds, isSelected, toggleSelected, deselectAll, selectAll };
};
