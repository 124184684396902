import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IconChevronLeft } from '../../Icon/IconChevronLeft';
import { IconChevronRight } from '../../Icon/IconChevronRight';
import { BaseIconProps } from '../../Icon/BaseIcon';
import { TableSortingType } from '../hooks/usePaginationHook';
import { Body } from '../../../theme/typography/Text';

export interface PageNavigationProps {
  icon: React.FC<BaseIconProps>;
  onClick: () => void;
  disabled: boolean;
}

interface LimitRowsSelectProps {
  limitOptions: number[];
  setShowRows: (limit: number) => void;
}

interface TableFooterProps {
  total: number;
  pagination: TableSortingType;
}

interface PageNavigatorProps {
  totalOfPages: number;
  currentPage: number;
  limit: number;
  setPage: (offset: number) => void;
}

interface ResultNavigatorProps {
  limit: number;
  offset: number;
  currentPage: number;
  total?: number;
  setPage: (offset: number) => void;
}

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;
  align-items: center;
  justify-content: space-around;
`;

const SelectWrapper = styled.div`
  position: relative;
  margin: 0 10px 0 10px;
  min-width: 40px;
  justify-content: center;

  & > option {
    font-size: 12px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
  }
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryVariant};
  border-radius: 4px;
  border: none;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 25px;
  width: 25px;

  &:active {
    background-color: ${({ theme }) => theme.buttons.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.buttons.disabled};
  }
`;

const Option = styled.option`
  color: ${({ theme }) => theme.texts.secondary};
  font-size: 12px;
`;

const Select = styled.select`
  appearance: none;
  background-color: ${({ theme }) => theme.borders.first};
  box-shadow: 0 4px 4px #0000000a;
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.texts.secondary};
  padding: 10px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #333;
  }
`;

const LimitRowsSelect: React.FC<LimitRowsSelectProps> = ({ limitOptions, setShowRows }) => {
  const onChangeRowLimit: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setShowRows(Number(event.target.value));
    },
    [setShowRows]
  );
  return (
    <FooterWrapper>
      <Body>Show</Body>
      <SelectWrapper>
        <Select onChange={onChangeRowLimit}>
          {limitOptions.map((option: number) => (
            <Option key={option.toString()} value={option.toString()}>
              {option.toString()}
            </Option>
          ))}
        </Select>
      </SelectWrapper>
      <Body>rows</Body>
    </FooterWrapper>
  );
};

const PageNavigatorButton: React.FC<PageNavigationProps> = ({ icon: ChevronIcon, onClick, disabled }) => {
  const theme = useTheme();

  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <ChevronIcon color={disabled ? theme.icons.disabled : theme.icons.onColor} />
    </StyledButton>
  );
};

const ResultNavigator: React.FC<ResultNavigatorProps> = ({ limit, offset, currentPage, total, setPage }) => {
  const [cachedTotal, setCachedTotal] = useState(total);
  const fromResult = currentPage !== 1 ? limit * (currentPage - 1) + 1 : total > 1 ? '1' : '0';
  const hasMoreResults = cachedTotal > limit * currentPage;
  const toResult = cachedTotal ? (!hasMoreResults ? cachedTotal : limit * currentPage) : 0;

  useEffect(() => {
    if (total) {
      setCachedTotal(total);
    }
  }, [total]);

  const toggleNextPage = useCallback(() => {
    setPage(toResult + 1);
  }, [setPage]);

  const togglePrevPage = useCallback(() => {
    setPage(currentPage === 2 ? 0 : offset - limit);
  }, [setPage]);

  return (
    <FooterWrapper>
      <Body>{`Results ${fromResult} to ${toResult}${cachedTotal > limit ? ` from ${cachedTotal}` : ''}`}</Body>
      <FooterWrapper>
        <PageNavigatorButton icon={IconChevronLeft} onClick={togglePrevPage} disabled={currentPage === 1} />
        <PageNavigatorButton icon={IconChevronRight} onClick={toggleNextPage} disabled={!hasMoreResults} />
      </FooterWrapper>
    </FooterWrapper>
  );
};

const PageNavigator: React.FC<PageNavigatorProps> = ({ totalOfPages, currentPage, limit, setPage }) => {
  const pages = Array.from({ length: totalOfPages }, (_, i) => i + 1);

  return (
    <FooterWrapper>
      <Body>Page:</Body>
      <SelectWrapper>
        <Select
          onChange={e => {
            setPage(limit * (Number(e.target.value) - 1));
          }}
          value={currentPage}>
          {pages.map(page => {
            return (
              <Option key={`go_to_page_option_${page}`} value={page}>
                {page}
              </Option>
            );
          })}
        </Select>
      </SelectWrapper>
    </FooterWrapper>
  );
};

export const TableFooter: React.FC<TableFooterProps> = ({ total, pagination }) => {
  const {
    pagination: { limit, offset },
    setLimit,
    setOffset,
    limits,
    currentPage
  } = pagination;
  const totalOfPages = useMemo(() => {
    return Math.floor(total / limit) + 1;
  }, [limit, total]);

  return (
    <FooterWrapper>
      <LimitRowsSelect limitOptions={limits} setShowRows={setLimit} />
      <PageNavigator currentPage={currentPage} totalOfPages={totalOfPages} setPage={setOffset} limit={limit} />
      <ResultNavigator limit={limit} offset={offset} total={total} currentPage={currentPage} setPage={setOffset} />
    </FooterWrapper>
  );
};
