import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageWrap } from 'src/components/PageWrap';
import { formatDeployment } from '../../parser';
import { usePermission } from '../../hooks';
import UserManagement from '../../layouts/Deployment/DeploymentDetail/UserComponent';
import { ManageSfUsersModal } from '../../components/Modals/Custom/ManageSfUsersModal';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { Title3 } from '../../theme/typography/Text';
import { deployment } from '@rollioforce/rollio-schemas';
import { useDeploymentDetail, useDeploymentEdit } from '../../hooks/api/Deployments/hooks';
import Spinner from '../../components/Spinner';

export interface DeploymentFormProps {
  deploymentId?: string;
}

const Container = styled.div`
  padding: 20px;
`;

export const Deployment: React.FC<DeploymentFormProps> = () => {
  const { deploymentId } = useParams();
  const { isInternal } = usePermission();
  const { mutate: editRecord } = useDeploymentEdit();
  const [modalVisible, setModalVisible] = useState(false);
  const { data, isLoading } = useDeploymentDetail(deploymentId);

  const onFormSubmit = useCallback(
    (data: DeploymentType) => {
      try {
        editRecord({ id: data.id, body: formatDeployment(data) });
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [editRecord, deploymentId]
  );

  const DEPLOYMENT_INSTANCE_SELECT = useMemo(
    () =>
      data?.instance
        ? [
          {
            label: data.instance.name,
            value: data.instance.id
          }
        ]
        : [],
    [data?.instance]
  );

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'id',
        label: 'Id',
        type: 'text',
        onlyDisplay: true
      },
      {
        key: 'name',
        label: 'Name',
        type: 'text',
        validate: 'onChange'
      },
      {
        key: 'instance_id',
        label: 'Datasource',
        type: 'select',
        validate: 'onChange',
        options: DEPLOYMENT_INSTANCE_SELECT
      },
      {
        key: 'timeformat',
        label: 'Time Format',
        type: 'select',
        validate: 'onChange',
        options: [
          {
            label: '12 Hour',
            value: '12-hour'
          },
          {
            label: '24 Hour',
            value: '24-hour'
          }
        ]
      },
      {
        key: 'provider',
        label: 'STT Provider',
        type: 'select',
        options: [
          {
            label: 'Google',
            value: 'google'
          },
          {
            label: 'Watson',
            value: 'watson'
          }
        ]
      },
      {
        key: 'new_platform',
        label: 'Use New Platform',
        type: 'checkbox',
        isVisible: isInternal
      },
      {
        key: 'content_generator',
        label: 'Content Generation',
        info: 'Allows the AI summary generation for users.',
        type: 'checkbox'
      },
      {
        key: 'aws_kms',
        label: 'AWS Encryption Key',
        info: 'AWS Key used for encryption/decryption of the deployment data.',
        placeholder: 'Enter the AWS Encryption Key',
        inputType: 'password',
        type: 'text'
      },
      {
        key: 'sso_configured',
        label: 'SSO Enabled',
        type: 'checkbox',
        isVisible: isInternal
      },
      {
        key: 'updated_at',
        label: 'Last Update Date',
        info: 'This field is automatically updated when the record is modified.',
        type: 'date',
        placeholder: 'No updates yet',
        onlyDisplay: true
      }
    ],
    [isInternal, DEPLOYMENT_INSTANCE_SELECT]
  );

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  return (
    <PageWrap>
      <ManageSfUsersModal
        title={`Add users to ${data.name}`}
        deploymentId={deploymentId}
        visible={modalVisible}
        setVisible={() => setModalVisible(!modalVisible)}
      />
      <Container>
        <GenericForm
          title={`${data.name} Deployment`}
          name='new_deployment'
          initialValues={data}
          fields={FORM_FIELDS}
          schema={deployment}
          onSubmit={onFormSubmit}
        />
        <Title3>Users</Title3>
        <UserManagement
          deploymentName={data.name}
          deploymentId={deploymentId}
          instanceId={data.instance_id}
          setModalVisible={setModalVisible}
        />
      </Container>
    </PageWrap>
  );
};
