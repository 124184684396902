import { useCallback } from 'react';
import { Edge, Node, useEdgesState, useNodesState } from 'reactflow';
import { nanoid } from 'nanoid';
import { deleteNodeFromDagreGraph, getLayoutElements, resetDagreGraph } from '../Logic';

type NodeType = string;

interface NodeData {
  title: string;
  subtitle?: string;
  slot: string;
  type: NodeType;
  bool?: string;
}

const deleteNodeAndChildren = (nodeId: string, nodesArray: Node[], edgesArray: Edge[]) => {
  const childrenEdges = edgesArray.filter((edge: Edge) => edge.source === nodeId);
  const childNodeIds = childrenEdges.map((edge: Edge) => edge.target);
  deleteNodeFromDagreGraph(nodeId);
  // Remove the node and related edges
  let updatedNodes = nodesArray.filter(node => node.id !== nodeId);
  let updatedEdges = edgesArray.filter((edge: Edge) => edge.source !== nodeId && edge.target !== nodeId);

  // Recursively delete children nodes
  childNodeIds.forEach((childNodeId: string) => {
    const result = deleteNodeAndChildren(childNodeId, updatedNodes, updatedEdges);
    updatedNodes = result.updatedNodes;
    updatedEdges = result.updatedEdges;
  });

  return { updatedNodes, updatedEdges };
};

const findEdgesToRemove = (sourceId: string, targetId: string, edges: Edge[]) => {
  return edges.filter(edge => edge.source === sourceId && edge.target === targetId);
};

export const useNodeAndEdgeManagement = (initialNodes: Node[], initialEdges: Edge[]) => {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const addChildNode = (parentId: string, type: NodeType) => {
    const createNode = (type: NodeType, offsetX: number, bool?: string) => {
      const id = nanoid(5);
      return {
        id,
        data: { title: `Untitled ${type} ${bool || ''} slot`, type, bool },
        position: { x: offsetX, y: 0 },
        type: 'special'
      };
    };

    let newNodes = [];
    let newEdges = [];

    if (type === 'decision') {
      const decisionNode = createNode('decision', 0);
      const decisionChildNode1 = createNode('entity', 0, 'true');
      const decisionChildNode2 = createNode('entity', 150, 'false');

      newNodes = [decisionNode, decisionChildNode1, decisionChildNode2];
      newEdges = [
        { id: `${decisionNode.id}-${parentId}-edge`, source: parentId, target: decisionNode.id, type: 'custom' },
        {
          id: `${decisionChildNode1.id}-${decisionNode.id}-edge`,
          source: decisionNode.id,
          target: decisionChildNode1.id,
          type: 'custom'
        },
        {
          id: `${decisionChildNode2.id}-${decisionNode.id}-edge`,
          source: decisionNode.id,
          target: decisionChildNode2.id,
          type: 'custom'
        }
      ];
    } else {
      const newNode = createNode(type, 0);

      newNodes = [newNode];
      newEdges = [{ id: `${newNode.id}-${parentId}-edge`, source: parentId, target: newNode.id, type: 'custom' }];
    }

    const updatedNodes = [...nodes, ...newNodes];
    const updatedEdges = [...edges, ...newEdges];
    const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(updatedNodes, updatedEdges);

    setNodes(layoutNodes);
    setEdges(layoutEdges);
  };

  const addNodeBetween = (sourceId: string, targetId: string, type: NodeType) => {
    const createNode = (nodeType = 'entity', offsetX: number) => {
      const id = nanoid(5);
      return {
        id,
        data: { title: `Untitled ${nodeType} slot`, type: nodeType },
        type: 'special',
        position: { x: offsetX, y: 0 }
      };
    };

    let newNodes = [];
    let newEdges = [];

    if (type === 'decision') {
      const decisionNode1 = createNode('decision', 0);
      const decisionNode2 = createNode('entity', 150);

      newNodes = [decisionNode1, decisionNode2];
      newEdges = [
        { id: `${sourceId}-${decisionNode1.id}-edge`, source: sourceId, target: decisionNode1.id, type: 'custom' },
        { id: `${decisionNode1.id}-${targetId}-edge`, source: decisionNode1.id, target: targetId, type: 'custom' },
        {
          id: `${decisionNode1.id}-${decisionNode2.id}-edge`,
          source: decisionNode1.id,
          target: decisionNode2.id,
          type: 'custom'
        }
      ];
    } else {
      const newNode = createNode(type, 0);

      newNodes = [newNode];
      newEdges = [
        { id: `${sourceId}-${newNode.id}-edge`, source: sourceId, target: newNode.id, type: 'custom' },
        { id: `${newNode.id}-${targetId}-edge`, source: newNode.id, target: targetId, type: 'custom' }
      ];
    }

    const edgesToRemove = findEdgesToRemove(sourceId, targetId, edges);
    const updatedNodes = [...nodes, ...newNodes];
    const updatedEdges = [...edges.filter(edge => !edgesToRemove.includes(edge)), ...newEdges];

    const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(updatedNodes, updatedEdges, edgesToRemove);

    setNodes(layoutNodes);
    setEdges(layoutEdges);
  };

  const addChildNodeMemo = useCallback(addChildNode, [edges, nodes, setNodes, setEdges]);

  const addNodeBetweenMemo = useCallback(addNodeBetween, [setNodes, setEdges, nodes, edges]);

  const connectToTerminal = (sourceNodeId: string) => {
    let terminalNode = nodes.find(node => node.id === 'terminal');
    let updatedNodes = nodes;
    let updatedEdges = edges;

    if (!terminalNode) {
      terminalNode = {
        id: 'terminal',
        data: { title: 'Confirmation', subtitle: 'End of Conversation', type: 'end' },
        type: 'special',
        position: { x: 0, y: 0 }
      };

      updatedNodes = [...nodes, terminalNode];
    }

    const edgeExists = edges.some(edge => edge.source === sourceNodeId && edge.target === terminalNode.id);

    if (!edgeExists) {
      const newEdge = {
        id: `${sourceNodeId}-terminal-edge`,
        type: 'custom',
        source: sourceNodeId,
        target: terminalNode.id
      };
      updatedEdges = [...edges, newEdge];
    }

    const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(updatedNodes, updatedEdges);

    setNodes(layoutNodes);
    setEdges(layoutEdges);
  };

  const connectToTerminalMemo = useCallback(connectToTerminal, [setNodes, setEdges, nodes, edges]);

  const reset = () => resetDagreGraph();

  const deleteNode = useCallback(
    (nodeId: string) => {
      const { updatedNodes, updatedEdges } = deleteNodeAndChildren(nodeId, nodes, edges);

      const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(updatedNodes, updatedEdges);

      setNodes(layoutNodes);
      setEdges(layoutEdges);
    },
    [setNodes, setEdges, edges, nodes]
  );

  const updateNode = (id: string, newNodeData: NodeData) => {
    setNodes(nds =>
      nds.map(node => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            ...newNodeData
          };
        }

        return node;
      })
    );
  };

  return {
    nodes,
    reset,
    edges,
    updateNode,
    addChild: addChildNodeMemo,
    addNodeBetween: addNodeBetweenMemo,
    endConversationFlow: connectToTerminalMemo,
    deleteNode
  };
};
