import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { useSfAuth } from '../../../../../hooks';
import { List } from '../../../../List';
import { CommonInput } from '../../../../Input/CommonInput';
import { CommonButton } from '../../../../Buttons/CommonButton';
import Spinner from '../../../../Spinner';
import { getUserName, isEmail, uniqueToast } from '../../../../../utils';
import SalesforceLogo from '../../../../../assets/salesforce-logo.png';
import { Body, Title3 } from '../../../../../theme/typography/Text';
import { useLinkedSfUsers, useLinkSfUser, useUnlinkSfUsers } from '../../../../../hooks/api/Users/hooks';

const ButtonTitle = styled(Title3)`
  margin: 0 0 0 0;
`;

const ButtonSubtitle = styled(Body)`
  margin: 0 0 0 0;
  color: ${({ theme }) => theme.texts.secondary};
`;

const StyledAvatar = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

const StyledAvatarImg = styled.img`
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
`;

const ButtonWrapper = styled.form`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ContainerStyles = css`
  min-height: 90px;
  cursor: pointer;
  width: 400px;
  border-radius: 4px;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  border: none;
  flex-direction: row;
  padding: 15px;
  background-color: ${({ theme }) => theme.layers.first};
`;

const FormContainer = styled.div`
  ${ContainerStyles};
  display: flex;
`;

const CommonLoginButtonStyles = css`
  ${ContainerStyles};

  &:active {
    background-color: ${({ theme }) => theme.layers.second};
  }

  &:hover {
    background-color: ${({ theme }) => theme.layers.second};
  }
`;

const LoginWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginButton = styled.button`
  ${CommonLoginButtonStyles}
`;

const LoginListButton = styled.li`
  ${CommonLoginButtonStyles};
  text-align: center;
`;

const LinkAccountButton = () => {
  return (
    <ButtonWrapper>
      <StyledAvatar>
        <StyledAvatarImg src={SalesforceLogo} />
      </StyledAvatar>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
        <ButtonTitle>Link an Account</ButtonTitle>
        <ButtonSubtitle>Link a Salesforce account to this user</ButtonSubtitle>
      </div>
    </ButtonWrapper>
  );
};

const LoadingSpinner = () => (
  <ButtonWrapper style={{ justifyContent: 'center' }}>
    <Spinner />
  </ButtonWrapper>
);

const LinkAccountForm = ({
  email,
  setInputEmail,
  setInputError,
  setFormActive,
  inputError,
  onFormSubmit,
  setLoading
}) => {
  const onSubmitForm = useCallback(
    e => {
      e.preventDefault();
      if (isEmail(email)) {
        setInputError(false);
        setLoading(true);
        onFormSubmit({ email });
        setFormActive(false);
      }
    },
    [isEmail, email, setInputError, setLoading, onFormSubmit, setFormActive]
  );

  return (
    <ButtonWrapper style={{ justifyContent: 'space-between' }} onSubmit={onSubmitForm}>
      <CommonInput value={email} onChange={setInputEmail} placeholder='Enter your Salesforce email' />
      <CommonButton label='Link' type='submit' disabled={inputError} />
    </ButtonWrapper>
  );
};

const LoginForm = ({ popUpRef, isLoading, setLoading, onSubmit }) => {
  const [inputError, setInputError] = useState(true);
  const [formActive, setFormActive] = useState(false);
  const [email, setEmail] = useState('');

  const onLoadingClick = useCallback(() => {
    if (isLoading && popUpRef.current) {
      popUpRef.current.close();
      popUpRef.current = null;
    }
    setLoading(false);
  }, [isLoading, popUpRef]);

  const setInputEmail = value => {
    if (isEmail(value)) {
      setInputError(false);
    }

    setEmail(value);
  };

  useEffect(() => {
    if (isEmail(email)) {
      setInputError(false);
    } else if (!inputError) {
      setInputError(true);
    }
  }, [email]);

  return isLoading ? (
    <LoginButton onClick={onLoadingClick}>
      <LoadingSpinner />
    </LoginButton>
  ) : formActive ? (
    <FormContainer>
      <LinkAccountForm
        email={email}
        inputError={inputError}
        setFormActive={setFormActive}
        onFormSubmit={onSubmit}
        setLoading={setLoading}
        setInputEmail={setInputEmail}
        setInputError={setInputError}
      />
    </FormContainer>
  ) : (
    <LoginButton onClick={() => setFormActive(true)}>
      <LinkAccountButton />
    </LoginButton>
  );
};

export const CRMLogin = ({ deploymentId }) => {
  const loginPopup = useRef<Window | void>();
  const { data: linkedUsers, refetch: refreshLinkedUsers } = useLinkedSfUsers(deploymentId);
  const { mutate: linkUser } = useLinkSfUser(deploymentId);
  const { mutate: unlinkUser } = useUnlinkSfUsers(deploymentId);
  const { login } = useSfAuth();
  const [isLoginIn, setLoginIn] = useState(false);

  const loginCallback = useCallback(
    async (username: string, isNew: boolean, success: boolean, data: string) => {
      setLoginIn(false);
      if (success) {
        if (isNew) {
          linkUser(username);
        }
      } else {
        toast(data);
        if (isNew) {
          unlinkUser([username]);
        }
      }
    },
    [refreshLinkedUsers, deploymentId]
  );

  const onSelectAccount = useCallback(
    async (username: string, isNew: boolean) => {
      try {
        if (isNew) {
          linkUser(username);
        }

        loginPopup.current = await login(username, null, (success, data) =>
          loginCallback(username, isNew, success, data)
        );
      } catch (e) {
        uniqueToast('There was an error');
      }
    },
    [linkUser, login, loginCallback]
  );

  const onUnlinkAccount = useCallback(async (id: string) => unlinkUser([id]), [unlinkUser]);

  const onRowClick = useCallback(
    (id: string) => onSelectAccount(linkedUsers.data.find(linkedUser => linkedUser.id === id)?.email, false),
    [linkedUsers, onSelectAccount]
  );

  const onFormSubmit = useCallback(({ email }) => onSelectAccount(email, true), [onSelectAccount]);

  const onUnlinkButtonClick = useCallback(() => {
    try {
      unlinkUser(linkedUsers[0].id);
    } catch (e) {
      toast('There was an error');
    }
  }, [linkedUsers, onUnlinkAccount]);

  return (
    <LoginWrapper>
      {linkedUsers?.data.length > 0 ? (
        <List
          items={[
            ...linkedUsers.data.map(({ id, first_name, last_name, email, profile_image_url }) => ({
              id,
              title: getUserName(first_name, last_name),
              description: email,
              avatar: profile_image_url
            }))
          ]}
          onRowClick={onRowClick}>
          <LoginListButton onClick={onUnlinkButtonClick}>
            <ButtonTitle>Use another account</ButtonTitle>
            <ButtonSubtitle>Select another account to link to this instance</ButtonSubtitle>
          </LoginListButton>
        </List>
      ) : (
        <LoginForm popUpRef={loginPopup} setLoading={setLoginIn} onSubmit={onFormSubmit} isLoading={isLoginIn} />
      )}
    </LoginWrapper>
  );
};
