import React, { useEffect, useState } from 'react';
import { CommonInput } from '../../Input/CommonInput';
import { ToggleSwitch } from '../../ToggleSwitch';
import { CommonSelect, SelectValue } from '../../Select';
import { CommonButton } from '../../Buttons/CommonButton';
import { DefaultValueInput } from './Parts/DefaultValueInput';
import { FieldLabel, FieldWrapper, SingleLineWrapper } from '../Common';
import { NoSelection } from './Parts/NoSelection';
import { PicklistValueSettings } from './Parts/PicklistPanel';
import { DatePanel } from './Parts/DatePanel';
import { TextPanel } from './Parts/TextPanel';
import { LookupPanel } from './Parts/LookupPanel';

const componentMap = {
  picklist: PicklistValueSettings,
  date: DatePanel,
  text: TextPanel,
  lookup: LookupPanel,
  initial: null
};

interface NodeObject {
  data: any;
  id?: number;
}

interface EntityPanelProps {
  node: NodeObject;
  saveNode: (id, data) => void;
}

const SlotTypeMap = {
  picklist: { label: 'Picklist', value: 'picklist' },
  date: { label: 'Date', value: 'date' },
  lookup: { label: 'Lookup', value: 'lookup' },
  text: { label: 'Text', value: 'text' }
};

const BasicDataInputs = ({ data, setInputData }) => {
  const [slotType, setSlotType] = useState<SelectValue>(null);

  useEffect(() => {
    if (data?.object_name && data?.object_name?.type) {
      const slot = data.object_name.type;
      setSlotType(SlotTypeMap[slot]);
      setInputData('entity_type', SlotTypeMap[slot]);
    }
  }, [data?.object_name]);

  return (
    <>
      <FieldWrapper style={{ marginTop: 0 }}>
        <FieldLabel label='Name' info='Select the object name' />
        <CommonInput
          name='title'
          style={{ width: 400 }}
          value={data.title}
          onChange={value => setInputData('title', value)}
          placeholder='Enter the slot name.'
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel label={'Salesforce Field'} info='Select the object name' />
        <CommonSelect
          placeholder='Select a field'
          onChange={value => setInputData('object_name', value)}
          value={data.object_name}
          options={[
            { label: 'Related To', value: 'whatid', type: 'lookup' },
            { label: 'Due Date', value: 'closedate', type: 'date' },
            { label: 'Status', value: 'stage', type: 'picklist' },
            { label: 'Subject', value: 'subject', type: 'text' },
            { label: 'Name', value: 'Name', type: 'text' },
            { label: 'Comments', value: 'text', type: 'text' },
            { label: 'Type', value: 'type_c', type: 'picklist' }
          ]}
          type='common'
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel label='Type' info='Select the object name' />
        <CommonSelect
          placeholder='Select a slot type'
          value={slotType}
          disabled={true}
          options={[
            { label: 'Picklist', value: 'picklist' },
            { label: 'Date', value: 'date' },
            { label: 'Lookup', value: 'lookup' },
            { label: 'Text', value: 'text' }
          ]}
          type='common'
        />
      </FieldWrapper>
      <SingleLineWrapper>
        <FieldLabel label='Conversation Entity' info='Select the object name' />
        <ToggleSwitch
          on={data.conversation_entity}
          handleToggle={value => setInputData('conversation_entity', value.target.checked)}
          name={'conv_entity'}
          isDisabled={false}
        />
      </SingleLineWrapper>
    </>
  );
};

const createPanel = (entityType, data, setInputData) => {
  const Component = componentMap[entityType.value];

  if (!Component) {
    console.warn(`No component found for entity type: ${entityType.value}`);
    return null;
  }

  return <Component setData={setInputData} value={data} />;
};

const SpecificInputs = ({ data, setInputData }) => {
  const { entity_type, default_value } = data;
  console.log('-> entity_type', entity_type);

  return (
    <>
      <BasicDataInputs data={data} setInputData={setInputData} />
      <SingleLineWrapper>
        <FieldLabel label='Skippable' info='Select the object name' />
        <ToggleSwitch
          on={data.skip}
          handleToggle={value => setInputData('skip', value.target.checked)}
          name={'skippable'}
          isDisabled={false}
        />
      </SingleLineWrapper>
      <SingleLineWrapper style={{ marginTop: 0 }}>
        <DefaultValueInput defaultValue={default_value} setData={setInputData} />
      </SingleLineWrapper>
      {!!entity_type && createPanel(entity_type, data, setInputData)}
    </>
  );
};

export const EntityPanel: React.FC<EntityPanelProps> = ({ node, saveNode }) => {
  if (!node?.data) {
    return <NoSelection />;
  }

  const [nodeData, setNodeData] = useState(node.data);

  useEffect(() => {
    setNodeData(node.data);
  }, [node]);

  const save = () => {
    saveNode(node.id, nodeData);
  };

  const handleInputChange = (id, value) => {
    setNodeData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  return (
    <ul>
      <li key='test1'>
        <SpecificInputs data={nodeData} setInputData={handleInputChange} />
        <div style={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CommonButton style={{ height: 50, width: 150 }} onClick={save} label='Save' />
        </div>
      </li>
    </ul>
  );
};
