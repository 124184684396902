import React, { createContext } from 'react';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';

import { FIREBASE_CONFIG } from './constants';

interface Firebase {
  auth: app.auth.Auth;
}

export const FirebaseContext = createContext<Firebase>(null);

const FirebaseProvider = ({ children }) => {
  app.initializeApp(FIREBASE_CONFIG);

  const firebase = {
    auth: app.auth()
  };

  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
