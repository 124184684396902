import React from 'react';
import { useField } from 'formik';
import { ToggleSwitch } from '../../ToggleSwitch';

interface CustomToggleFieldProps {
  name: string;
  disabled?: boolean;
}

const FormToggle: React.FC<CustomToggleFieldProps> = ({ name, disabled }) => {
  const [field] = useField({ name, type: 'checkbox' });

  return <ToggleSwitch isDisabled={disabled} on={field.value} handleToggle={field.onChange} name={name} />;
};

export default FormToggle;
