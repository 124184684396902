import { USERS_URL } from 'src/constants';

import axiosInstance from '../axiosInstances';
import { PaginationParams, parsePaginatedUrl } from '../utils';

export interface RawUserData {
  id: number;
  first_name: string;
  last_name: string;
  updated_at: string;
  email: string;
  salesforce_id?: string;
  instance: {
    name: string;
    id: number;
  };
  deployments: {
    name: string;
    id: number;
  }[];
}

export interface RawSyncedUserData {
  usersSynced: string[];
}

const fetchPaginatedUserListData = async (params: PaginationParams) =>
  (
    await axiosInstance.get<{
      data: RawUserData[];
      total: number;
    }>(parsePaginatedUrl(`${USERS_URL}/search`, params))
  ).data;

const fetchLinkedAdminSfUsers = async (deploymentId: string) =>
  (
    await axiosInstance.get<{
      data: UserType[];
    }>(`${USERS_URL}/linked-sf-users?deployment_id=${deploymentId}`)
  ).data;

const linkAdminSfUser = async (deploymentId: string, username: string) =>
  axiosInstance.post(`${USERS_URL}/linked-sf-users`, {
    deployment_id: deploymentId,
    username
  });

const addUsers = async (deploymentId: string, emails: string[]) =>
  axiosInstance.post(`${USERS_URL}`, {
    deployment_id: deploymentId,
    emails
  });

const unlinkAdminSfUsers = async (deploymentId: string, sfUserIds: string[]) =>
  axiosInstance.delete(`${USERS_URL}/linked-sf-users`, {
    data: {
      deployment_id: deploymentId,
      sf_user_ids: sfUserIds
    }
  });

const syncUsers = async (deploymentId: string, sfToken: string) =>
  axiosInstance.post<{
    data: {
      usersSynced: RawUserData[];
    };
  }>(
    `${USERS_URL}/sync`,
    {
      deployment_id: deploymentId
    },
    {
      headers: {
        'sf-authorization': sfToken
      }
    }
  );

const addSyncedUsersToDeployment = async (
  deploymentId: string,
  sfToken: string,
  users: {
    usersToAdd?: RawUserData[];
    usersToRemove?: RawUserData[];
  }
) =>
  axiosInstance.post(
    `${USERS_URL}/add-synced-sf-users`,
    {
      sfUsersToAdd: users.usersToAdd,
      sfUsersToRemove: users.usersToRemove,
      deployment_id: deploymentId
    },
    {
      headers: {
        'sf-authorization': sfToken
      }
    }
  );

export {
  addUsers,
  fetchPaginatedUserListData,
  fetchLinkedAdminSfUsers,
  linkAdminSfUser,
  unlinkAdminSfUsers,
  syncUsers,
  addSyncedUsersToDeployment
};
