import { Store } from '@reduxjs/toolkit';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { resetUser } from '../../store/actions';

const axiosInstance = axios.create();
export const addHeadersInterceptor = (store: Store) => {
  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
    const state = store.getState();
    const token = state.user.authToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

export const addUnauthorizedAccessInterceptor = (store: Store) => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const status = error?.response?.status;
      if (status === 401) {
        store.dispatch(resetUser());
      }
      throw error;
    }
  );
};

export default axiosInstance;
