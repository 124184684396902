import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { formatOrganization } from '../../parser';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { organization } from '@rollioforce/rollio-schemas';
import { Modal } from '../../components/Modals/Modal';
import { useAddOrganization } from '../../hooks/api/Organizations/hooks';
import Spinner from '../../components/Spinner';

interface NewOrganizationProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const NewOrganizationModal: React.FC<NewOrganizationProps> = ({ visible, setVisible }) => {
  const { mutate: addRecord, isLoading } = useAddOrganization(() => setVisible(false));

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter the organization name'
      },
      {
        key: 'licenses',
        label: 'Licenses',
        type: 'text',
        inputType: 'number',
        placeholder: 'Enter the number of licenses'
      },
      {
        key: 'is_active',
        label: 'Active',
        type: 'checkbox'
      }
    ],
    []
  );

  const onFormSubmit = useCallback(
    (data: OrganizationType) => {
      try {
        addRecord(formatOrganization(data));
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  return (
    <Modal isOpen={visible} title='Create New Organization' onCloseClick={() => setVisible(false)}>
      {isLoading ? <Spinner fullscreen /> : null}
      <GenericForm
        name='new_organization'
        columns={1}
        initialValues={{ name: '', is_active: true, licenses: 10 }}
        fields={FORM_FIELDS}
        schema={organization}
        onSubmit={onFormSubmit}
        modal
      />
    </Modal>
  );
};
