import React, { useEffect, useRef, useState } from 'react';
import { SimpleButton } from '../../Dashboard/SimpleButton';
import { IconMore } from '../../Icon/IconMore';
import { ActionType, Row } from '../index';
import styled from 'styled-components';

interface ActionsProps {
  row: Row;
  actions: ActionType[];
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  padding: 8px 0;
  opacity: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  transform: translateY(-10px);
  animation: fadeInDown 0.3s ease forwards;
  animation-delay: ${(props: { delay: number }) => props.delay}s;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const DropdownContent = styled.div`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.layers.second};
  position: absolute;
  right: 0;
  min-width: 100px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px 8px;
  z-index: 1;
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  transform: ${(props: { isOpen: boolean }) => (props.isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  transition: opacity 0.3s ease, transform 0.3s ease;
`;

export const ActionButton: React.FC<ActionsProps> = ({ actions, row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <SimpleButton icon={IconMore} onClick={toggleDropdown} />
      <DropdownContent isOpen={isOpen}>
        {actions.map((action, index) => (
          <ButtonWrapper key={`action_${action.name}_${index}`} delay={index * 0.1}>
            <SimpleButton
              icon={action?.icon}
              onClick={() => {
                action.onClick(row);
                setIsOpen(false);
              }}
              key={index}
              label={action.name}
              style={{ textAlign: 'left' }}
            />
          </ButtonWrapper>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};
