export const cmsApiUrl = `${process.env.REACT_APP_CMS_API_URL}/api`;
const loginApiUrl = process.env.REACT_APP_AUTH_API_URL;
const sttApiUrl = process.env.REACT_APP_STT_API_URL;

export const ORGANIZATIONS = 'organizations';
export const INSTANCES = 'instances';
export const DEPLOYMENTS = 'deployments';
export const LOGIN_URL = `${cmsApiUrl}/authentication/firebase-token`;
export const AUTHENTICATION_URL = `${cmsApiUrl}/authentication`;
export const SALESFORCE_LOGIN_URL = `${loginApiUrl}/redirect-url`;
export const SALESFORCE_LOGOUT_URL = `${loginApiUrl}/logout`;
export const DEPLOYMENTS_URL = `${cmsApiUrl}/${DEPLOYMENTS}`;
export const ORGANIZATIONS_URL = `${cmsApiUrl}/${ORGANIZATIONS}`;
export const ADMINISTRATORS_URL = `${cmsApiUrl}/users`;
export const USERS_URL = `${cmsApiUrl}/sf-users`;
export const CONFIGURATIONS_URL = `${cmsApiUrl}/configurations`;
export const FLOWS_URL = `${cmsApiUrl}/conversations`;
export const INSTANCES_URL = `${cmsApiUrl}/${INSTANCES}`;
export const INTENTS_URL = `${cmsApiUrl}/intents`;
export const FIELDS_URL = `${cmsApiUrl}/fields`;
export const STT_REPORTS_URL = `${cmsApiUrl}/stt-reports`;
export const STT_AUDIO_URL = `${sttApiUrl}/audio`;

export const SELECT_VALUES_A_B = ['A', 'B'].map(el => ({
  label: el,
  value: el
}));

export const DATE_FORMATS = {
  DEFAULT: 'MM/dd/yyyy HH:mm',
  FORM: 'yyyy-MM-dd'
};

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_API_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_API_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export enum ACTION {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  ACTIVATE = 'activate'
}

export enum RESOURCE {
  ORGANIZATION = 'Organization',
  DEPLOYMENT = 'Deployment',
  ADMINISTRATORS_URL = 'Administrator',
  SF_USER = 'SfUser',
  ROLE = 'Role'
}

export enum ROLE {
  INTERNAL = 'Internal',
  ORGANIZATION = 'Organization',
  DEPLOYMENT = 'Deployment'
}

export enum CONFIGURATION_TYPE {
  CORE = 'core',
  NLP = 'nlp',
  STT = 'stt',
  CHROME = 'chrome'
}

export enum FLOW_STEP {
  INITIAL_STEP = 0,
  INTENT = 1,
  CONVERSATION_TEMPLATE = 2,
  CONFIRMATION = 3
}

export enum SLOT_OPTION {
  PROMPT = 'Prompt',
  DECISION_POINT = 'Decision Point'
}

export const DEFAULT_DEPLOYMENT_FORM: DeploymentType = {
  name: '',
  is_active: true,
  language_customization_id_a: '',
  language_customization_id_b: '',
  record_sync_stt: false,
  active_language_customization: 'A',
  timeformat: '12-hour',
  new_platform: false,
  instance_id: '',
  aws_kms: '',
  available_licenses: 0,
  used_licenses: '',
  sso_configured: false
};
