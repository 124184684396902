import { DEPLOYMENTS_URL, INSTANCES_URL } from 'src/constants';
import axiosInstance from '../axiosInstances';
import { PaginationParams, parsePaginatedUrl } from '../utils';
import { toast } from 'react-toastify';

export interface RawDeploymentData {
  id: number;
  name: string;
  updated_at: string;
  users_count: string;
  is_active: boolean;
}

const fetchPaginatedDeploymentsData = async (params: PaginationParams) =>
  (
    await axiosInstance.get<{
      data: RawDeploymentData[];
      total: number;
    }>(parsePaginatedUrl(`${DEPLOYMENTS_URL}/search`, params))
  ).data;

const fetchDeploymentsByInstanceId = async (id: string) =>
  (
    await axiosInstance.get<{
      data: RawDeploymentData[];
      total: number;
    }>(`${INSTANCES_URL}/${id}/deployments`)
  ).data.data;

const fetchDeploymentDetail = async (id: string) =>
  (
    await axiosInstance.get<{
      data: DeploymentType;
    }>(`${DEPLOYMENTS_URL}/${id}?extraData=true`)
  ).data.data;

const addNewDeployment = async (deployment: DeploymentType) =>
  await axiosInstance.post<{ data: DeploymentType }>(`${DEPLOYMENTS_URL}`, deployment);

const editDeployment = async (id: string, deployment: DeploymentType) =>
  await axiosInstance.put<{ data: DeploymentType }>(`${DEPLOYMENTS_URL}/${id}`, deployment);

const deleteDeployments = async (ids: string[]) =>
  await axiosInstance.delete<void>(`${DEPLOYMENTS_URL}`, {
    data: ids
  });
const deleteDeploymentUsers = async (deploymentId: string, sf_user_ids: string[]) =>
  await axiosInstance.delete<void>(`${DEPLOYMENTS_URL}/${deploymentId}/sf-selected-users`, { data: { sf_user_ids } });

const generateReport = async (
  deployment_ids: string[],
  date_from: string,
  date_to: string,
  include_successful_failed: boolean
) => {
  try {
    const response = await axiosInstance.post(`${DEPLOYMENTS_URL}/report`, {
      deployment_ids,
      date_from,
      date_to,
      include_successful_failed
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('Download', `${date_from}_${date_to}-report.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    toast(error.message, { type: 'error' });
  }
};

export {
  fetchDeploymentDetail,
  fetchDeploymentsByInstanceId,
  editDeployment,
  addNewDeployment,
  deleteDeployments,
  deleteDeploymentUsers,
  fetchPaginatedDeploymentsData,
  generateReport
};
