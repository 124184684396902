import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getRouteUrlById, ROUTES } from 'src/routes';
import { CommonButton } from './Buttons/CommonButton';

interface Props {
  title: string;
  children: React.ReactElement[] | React.ReactElement | string | string[] | number | number[] | null | undefined;
}

const ErrorContainer = styled.div`
  padding: 20px;
  align-items: center;
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.texts.secondary};
  text-align: center;
`;
const ErrorTitle = styled.h1`
  font-family: 'Open Sans', serif;
  font-size: 30px;
  color: ${({ theme }) => theme.texts.primary};
  font-weight: 600;
  margin: 0 0 12px;
`;
const ErrorDescription = styled.p`
  font-size: 20px;
  font-family: 'Open Sans', serif;
  font-weight: 600;
  margin: 0 0 30px;
`;

export const Error: React.FC<Props> = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorDescription>{children}</ErrorDescription>
      <CommonButton label='Back to Dashboard' onClick={() => navigate(getRouteUrlById(ROUTES.home))} />
    </ErrorContainer>
  );
};
