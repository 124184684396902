import React from 'react';
import styled from 'styled-components';
import { IconMoon } from './Icon/IconMoon';
import { IconSun } from './Icon/IconSun';
import { RollioTheme } from '../theme/RollioTheme';
import { useDispatch, useSelector } from 'react-redux';
import { getIsDarkModeEnabled, setDarkModeIsEnabled } from '../store/reducers/ui';

const CheckboxContainer = styled.div`
  position: relative;
`;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const CheckboxLabel = styled.label<{ checked: boolean; theme: RollioTheme }>`
  background-color: ${props => (props.checked ? props.theme.colors.secondary : props.theme.colors.primaryVariant)};
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ball {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
    transform: ${props => (props.checked ? 'translateX(24px)' : 'none')};
  }
`;

const MoonIcon = styled(IconMoon)`
  color: white;
`;

const SunIcon = styled(IconSun)`
  color: #f39c12;
`;

const ThemeToggle: React.FC = () => {
  const isChecked = useSelector(getIsDarkModeEnabled);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setDarkModeIsEnabled(!isChecked));
  };

  return (
    <CheckboxContainer>
      <CheckboxInput
        type='checkbox'
        id='theme-toggle'
        className='checkbox'
        checked={isChecked}
        onChange={handleToggle}
      />
      <CheckboxLabel htmlFor='theme-toggle' checked={isChecked}>
        <MoonIcon color='white' />
        <SunIcon color='#f39c12' />
        <div className='ball' />
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default ThemeToggle;
