import React from 'react';
import { PlacesType } from 'react-tooltip';

export interface ToolTipProps {
  children: React.ReactNode;
  position?: PlacesType;
  title?: string;
  message: string;
}

export const ToolTip: React.FC<ToolTipProps> = ({ children, position = 'top', message }) => (
  <div data-tooltip-id='rollio-tooltip' data-tooltip-content={message} data-tooltip-place={position}>
    {children}
  </div>
);
