import React from 'react';
import { colors } from '../../theme';
import styled, { css, keyframes } from 'styled-components';
import { Caption } from '../../theme/typography/Text';
import { IconClose } from '../Icon/IconClose';

const explode = keyframes`
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-2deg);
    }
    20% {
        transform: rotateZ(2deg);
    }
    25% {
        transform: rotateZ(-4deg);
    }
    30% {
        transform: rotateZ(1deg);
    }
    35% {
        transform: rotateZ(-1deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
`;

const Label = styled(Caption)`
  margin: 0;
`;

const CommonChipStyle = css`
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  display: inline-flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Open Sans', serif;
`;

const HoverText = styled.span`
  display: none;
`;

const CommonDisplayChip = styled.div(({ color }) => {
  return css`
    ${CommonChipStyle};
    background-color: ${color};
  `;
});

const StyledChipButton = styled.button`
  ${CommonChipStyle};
  background-color: ${colors.purple};
  animation: ${explode} 1s ease-in forwards;

  &:hover {
    background-color: ${colors.red};

    span {
      display: flex;
      position: absolute;
      top: 6px;
    }

    ${Label} {
      color: transparent;
    }
  }
`;
const StyledTagButton = styled.div`
  background-color: transparent;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  font: inherit;
  font-weight: bold;
  padding: 2px 0 0 0;
  display: inline-block;
  margin-left: 5px;
  text-align: center;
`;

export const EmailChip = ({ item, handleDelete, icon = true }) => {
  const id = item?.id || item;
  const name = item?.id ? item.label : item;
  return (
    <StyledChipButton
      key={id}
      onClick={() => {
        handleDelete(id);
      }}>
      <Label>{name}</Label>
      <HoverText>Remove</HoverText>
      {icon && (
        <StyledTagButton>
          <IconClose />
        </StyledTagButton>
      )}
    </StyledChipButton>
  );
};

export const DisplayChip = ({ item, color }) => {
  return (
    <CommonDisplayChip color={color}>
      <Label>{item}</Label>
    </CommonDisplayChip>
  );
};
