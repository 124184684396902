import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TableHeaderWrapper, Title, Wrapper } from '../../layouts/Common';
import { ROUTES } from '../../routes';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable, Row } from '../../components/Table';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { getFormattedDate, getUserName } from '../../utils';
import { setAlert } from '../../store/reducers/ui';
import { useQueryFilter } from '../../hooks/api/common/common';
import { IconTrash } from '../../components/Icon/IconTrash';
import { linkCellFormatter } from '../../components/Table/utils';
import { useUsersListData } from '../../hooks/api/Users/hooks';
import { RawUserData } from '../../hooks/api/Users/queries';
import { useDeleteDeploymentUsers } from '../../hooks/api/Deployments/hooks';
import { SimpleButton } from '../../components/Dashboard/SimpleButton';
import { IconReassign } from '../../components/Icon/IconReassign';

const COLUMNS = [
  { key: 'name', label: 'Name', sortable: true },
  { key: 'email', label: 'Email' },
  { key: 'datasource', label: 'Datasource', formatter: linkCellFormatter },
  { key: 'deployment', label: 'Deployment', formatter: linkCellFormatter },
  { key: 'updated_at', label: 'Last Update Date', sortable: true }
];

interface UserRowData extends Row {
  updated_at: string;
  datasource: { onClick: () => void; name: string } | string;
  email: string;
  deployment: { onClick: () => void; name: string; id: string } | string;
}

export const Users: React.FC = () => {
  const navigate = useNavigate();
  const paginationAndSorting = usePaginationAndSorting();
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const { mutate: deleteUsersFromDeployment } = useDeleteDeploymentUsers('1');
  const dispatch = useDispatch();
  const queryFilter = useQueryFilter();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [tableRows, setTableRows] = useState([]);
  const userPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );
  const { isLoading, data: response } = useUsersListData(userPagination, searchParams, {
    organization: queryFilter?.organization,
    instance: queryFilter?.instance,
    deployment: queryFilter?.deployment
  });

  useEffect(() => {
    paginationAndSorting.reset();
  }, [queryFilter]);

  const parseUsersToTableData = useCallback(
    (data: RawUserData[]) =>
      data?.length
        ? data.map(({ id, first_name, last_name, updated_at, email, instance, deployments }) => ({
          id: id.toString(),
          name: getUserName(first_name, last_name),
          email,
          updated_at: getFormattedDate(updated_at),
          datasource: instance
              ? {
                name: instance.name,
                onClick: () => {
                  navigate(`/${ROUTES.datasources}/${instance.id}`);
                }
              }
              : 'Unassigned',
          deployment: deployments.length
              ? {
                name: deployments[0].name,
                id: deployments[0].id,
                onClick: () => {
                  navigate(`/${ROUTES.deployments}/${deployments[0].id}`);
                }
              }
              : 'Unassigned'
        }))
        : ([] as UserRowData[]),
    [navigate]
  );

  useEffect(() => {
    if (response?.data?.length) {
      const rows = parseUsersToTableData(response?.data);
      setTableRows(rows);
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const unlinkRecords = useCallback(
    ({ deploymentId, ids }) => {
      try {
        deleteUsersFromDeployment(ids);
      } catch (e) {
        toast('There was an error', { type: 'error' });
      }
    },
    [deleteUsersFromDeployment]
  );

  //TODO RESOLVER ESTO
  const actions = useMemo(
    () => [
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: UserRowData) => {
          dispatch(
            setAlert({
              title: `Delete ${row.name}`,
              message: `Are you sure you want to remove ${row.name}?`,
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => unlinkRecords({ deploymentId: '1', ids: [row.id] })
              }
            })
          );
        }
      }
    ],
    [ROUTES.users, navigate]
  );

  const Header = useCallback(() => {
    return (
      <>
        {selectedIds.length ? (
          <>
            <SimpleButton
              outline
              disabled={!selectedIds?.length}
              info='Reassign selected users to another deployment.'
              icon={IconReassign}
              label='Reassign'
              onClick={() => alert('Not Yet implemented')}
            />
            <SimpleButton
              outline
              label='Delete Selected'
              icon={IconTrash}
              onClick={() =>
                dispatch(
                  setAlert({
                    title: 'Delete users',
                    message: 'Are you sure you want to delete the selected users?',
                    isOpen: true,
                    positiveButton: {
                      title: 'Confirm and Delete',
                      onClick: () => alert('Not Yet implemented')
                    }
                  })
                )
              }
            />
          </>
        ) : null}
      </>
    );
  }, [selectedIds]);

  return (
    <Wrapper>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <TableHeaderWrapper>
          <Title>Users</Title>
        </TableHeaderWrapper>
        <RecordTable
          setSearch={setSearchParams}
          header={Header}
          data={tableRows}
          pagination={paginationAndSorting}
          total={response?.total}
          isLoading={isLoading}
          actions={actions}
          columns={COLUMNS}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
