import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from 'src/store/actions';
import { auth } from 'src/services';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { FirebaseContext } from 'src/FirebaseProvider';

import { useSfAuth } from './useSfAuth';
import { getAuthToken } from '../store/reducers/user';

export const useAuth = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(getAuthToken);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState('');
  const { auth: firebaseAuth } = useContext(FirebaseContext);
  const { resetSfAuth } = useSfAuth();

  const isLoggedIn = useMemo(() => !!authToken, [authToken]);
  const navigate = useNavigate();

  const updateToken = useCallback(
    (newAuthToken: string) => {
      dispatch(setAuthToken(newAuthToken));
    },
    [dispatch]
  );

  const logout = useCallback(async () => {
    try {
      await firebaseAuth.signOut();
      updateToken(null);
      resetSfAuth();
      navigate(getRouteUrlById(ROUTES.login));
    } catch (e) {
      toast(e.message, { type: 'error' });
    }
  }, [updateToken, resetSfAuth, firebaseAuth, navigate]);

  const login = useCallback(
    async (username, password) => {
      setLoginError('');

      if (!username?.trim()) {
        setLoginError('Invalid username');
        return;
      }

      setIsLoggingIn(true);
      try {
        const { user } = await firebaseAuth.signInWithEmailAndPassword(username, password);
        const token = await user.getIdToken(true);
        const authResponse = await auth.login(token, user.refreshToken);
        if (authResponse.success) {
          updateToken(authResponse.token);
        }
        setLoginError(authResponse.message);
      } catch (e) {
        setLoginError(e.message);
      } finally {
        setIsLoggingIn(false);
      }
    },
    [firebaseAuth, updateToken]
  );

  return {
    isLoggedIn,
    isLoggingIn,
    login,
    logout,
    updateToken,
    authToken,
    loginError
  };
};
