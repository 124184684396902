import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageWrap } from 'src/components/PageWrap';
import AdminManagement from '../../layouts/Organizations/Parts/AdminManagement';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { organization } from '@rollioforce/rollio-schemas';
import { useOrganizationDetail, useOrganizationEdit } from '../../hooks/api/Organizations/hooks';
import { formatOrganization } from '../../parser';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';

const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

export const Organization: React.FC = () => {
  const { organizationId } = useParams();
  const { data: initialData, isLoading: isLoadingDetail } = useOrganizationDetail(organizationId);
  const { mutate: editRecord } = useOrganizationEdit();

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'id',
        label: 'Id',
        type: 'text',
        onlyDisplay: true
      },
      {
        key: 'name',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter the organization name'
      },
      {
        key: 'licenses',
        label: 'Licenses',
        type: 'text',
        inputType: 'number',
        placeholder: 'Enter the number of licenses'
      },
      {
        key: 'is_active',
        label: 'Active',
        type: 'checkbox'
      },
      {
        key: 'updated_at',
        label: 'Last Update Date',
        info: 'This field is automatically updated when the record is modified.',
        type: 'date',
        placeholder: 'No updates yet',
        onlyDisplay: true
      }
    ],
    []
  );

  const onFormSubmit = useCallback(
    (data: OrganizationType) => {
      try {
        editRecord({ id: organizationId, body: formatOrganization(data) });
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [editRecord, organizationId]
  );

  return (
    <PageWrap>
      {!isLoadingDetail ? (
        <>
          <Wrapper>
            <GenericForm
              title={initialData?.name}
              isLoading={isLoadingDetail}
              fields={FORM_FIELDS}
              initialValues={initialData}
              name='organization_edit'
              onSubmit={onFormSubmit}
              schema={organization}
            />
          </Wrapper>
          <Wrapper>
            <AdminManagement organizationId={organizationId} />
          </Wrapper>
        </>
      ) : (
        <Spinner fullscreen />
      )}
    </PageWrap>
  );
};
