import React, { useState } from 'react';
import { debounce } from 'lodash';
import {
  Condition,
  ConditionInput,
  ConditionSelect,
  ConditionsWrapper,
  DeleteRuleButton,
  handleInputChange
} from './Common';
import { IconClose } from '../../../Icon/IconClose';

export const ConditionComponent = ({ condition, updateCondition, deleteCondition, level, possibleTargets }) => {
  const [localValues, setLocalValues] = useState(condition);
  const [currentTarget, setCurrentTarget] = useState(possibleTargets[0]);

  const debouncedUpdateCondition = debounce(updateCondition, 300);
  return (
    <div
      style={{
        marginLeft: level > 0 ? 20 * level : 0
      }}>
      <Condition>
        <ConditionsWrapper>
          <ConditionSelect
            value={condition.type}
            onChange={e =>
              debouncedUpdateCondition(condition.id, {
                ...condition,
                type: e.target.value
              })
            }>
            <option value=''>Select condition</option>
            <option value='AND'>AND</option>
            <option value='OR'>OR</option>
          </ConditionSelect>

          <DeleteRuleButton onClick={() => deleteCondition(condition.id)}>
            <IconClose />
          </DeleteRuleButton>
        </ConditionsWrapper>
        <ConditionsWrapper>
          <ConditionSelect
            value={condition.field}
            onChange={e =>
              debouncedUpdateCondition(condition.id, {
                ...condition,
                field: e.target.value
              })
            }>
            <option value=''>Select field</option>
            {possibleTargets}
          </ConditionSelect>
          <ConditionSelect
            value={condition.operator}
            onChange={e =>
              debouncedUpdateCondition(condition.id, {
                ...condition,
                operator: e.target.value
              })
            }>
            <option value=''>Select operator</option>
            <option value='equals'>Equals</option>
            <option value='not_equals'>Not Equals</option>
          </ConditionSelect>
          <ConditionInput
            placeholder='Enter Value'
            type='text'
            value={localValues[condition.id] || ''}
            onChange={e => {
              handleInputChange(condition.id, localValues, setLocalValues, debouncedUpdateCondition, e.target.value);
            }}
          />
        </ConditionsWrapper>
      </Condition>
      {/*      {condition.children.length > 0 && renderConditions(condition.children, level + 1)}*/}
      {/*<AddNestedRuleButton onClick={() =>
      addNestedCondition(condition.id)}>Add Nested Condition</AddNestedRuleButton>*/}
    </div>
  );
};
