import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePermission } from '../../hooks';
import { getActiveOrganization, getOrganizations } from '../../store/reducers/globalFilter';
import { useAddAdministrator } from '../../hooks/api/Administrators/hooks';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { Modal } from '../../components/Modals/Modal';
import { user } from '@rollioforce/rollio-schemas';

interface NewAdministratorModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  defaultOrg?: string;
}

export const AddAdministratorModal: React.FC<NewAdministratorModal> = ({ visible, setVisible, defaultOrg }) => {
  const { isInternal } = usePermission();
  const { mutate: addRecord, isLoading } = useAddAdministrator(() => setVisible(false));
  const organizations = useSelector(getOrganizations);
  const organization = useSelector(getActiveOrganization);

  const onFormSubmit = useCallback(
    (data: BasicUserType) => {
      try {
        addRecord(data);
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  const ORGANIZATION_SELECT_VALUES = useMemo(
    () =>
      !isInternal
        ? [organization]
        : organizations.map(i => ({
          label: isInternal ? `${i.name} [${i.id}]` : i.name,
          value: i.id.toString()
        })),
    [organizations, organization, isInternal]
  );

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'first_name',
        label: 'First Name',
        type: 'text',
        validate: 'onChange'
      },
      {
        key: 'last_name',
        label: 'Last Name',
        type: 'text',
        validate: 'onChange'
      },
      {
        key: 'profile_image_url',
        label: 'Profile Picture',
        type: 'text',
        validate: 'onChange'
      },
      {
        key: 'email',
        label: 'Email',
        type: 'text',
        validate: 'onChange'
      },
      {
        key: 'organization_id',
        label: 'Organization',
        type: 'select',
        validate: 'onChange',
        options: ORGANIZATION_SELECT_VALUES
      },
      {
        key: 'role_name',
        label: 'Role',
        type: 'select',
        validate: 'onChange',
        options: [
          {
            label: 'Organization Admin',
            value: 'Organization'
          }
        ]
      }
    ],
    [isInternal, ORGANIZATION_SELECT_VALUES]
  );

  const initialData: BasicUserType = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      email: '',
      profile_image_url: '',
      organization_id: defaultOrg ?? ORGANIZATION_SELECT_VALUES[0].value,
      role_name: 'Organization'
    }),
    [defaultOrg, ORGANIZATION_SELECT_VALUES]
  );

  return (
    <Modal isOpen={visible} title='Add New Administrator' onCloseClick={() => setVisible(false)}>
      <GenericForm
        name='Add Administrator'
        fields={FORM_FIELDS}
        columns={2}
        isLoading={isLoading}
        schema={user}
        initialValues={initialData}
        onSubmit={onFormSubmit}
        modal
      />
    </Modal>
  );
};
