import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Login as LoginLayout } from 'src/layouts/Auth/Login';
import { useAuth } from 'src/hooks';
import { getRouteUrlById, ROUTES } from 'src/routes';

export const Login: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(getRouteUrlById(ROUTES.home), { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return <LoginLayout />;
};
