import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type AuthToken = string;

export type SFTokenType = {
  [key: string]: AuthToken;
};

export type InstanceType = {
  label: string;
  value: string;
};

interface UserState {
  authToken: AuthToken;
  sfTokens: SFTokenType;
}

const initialState: UserState = {
  sfTokens: null,
  authToken: null
};

// Slice
export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken: (state: UserState, action) => {
      state.authToken = action.payload;
    },
    setSfToken: (state: UserState, action) => {
      const { instanceId, token } = action.payload;
      state.sfTokens = { ...state.sfTokens, [instanceId]: token };
    },
    resetSfTokens: (state: UserState) => {
      state.sfTokens = {};
    },
    resetUser: () => initialState
  }
});

// Actions
export const { setAuthToken, setSfToken, resetUser, resetSfTokens } = user.actions;

export const getAuthToken = (state: RootState) => state.user.authToken;

export const getSfTokens = (state: RootState) => state.user.sfTokens;

export default user.reducer;
