import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../store/reducers/ui';

import { FilterType, PaginationParams } from '../utils';
import {
  addNewDatasource,
  deleteDatasources,
  editDatasource,
  fetchDatasourceData,
  fetchPaginatedDatasourcesData
} from './queries';

const useAddNewDatasource = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: OrganizationInstanceType) => addNewDatasource(body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: (response: { data: { data: OrganizationInstanceType } }) => {
      queryClient.invalidateQueries(['datasources', 'list']);
      queryClient.invalidateQueries([
        'organizations',
        'datasources',
        'list',
        response.data.data.organization_id.toString()
      ]);
      onSuccess();
      toast(`${response.data.data.name} Datasource Created`, { type: 'success' });
    }
  });
};

const useDatasourceDetail = (id: string) =>
  useQuery({
    queryKey: ['datasources', id],
    queryFn: () => fetchDatasourceData(id),
    enabled: !!id
  });

const useDatasourceEdit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: { id: string; body: OrganizationInstanceType }) =>
      editDatasource(variables.id, variables.body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: (response: { data: { data: OrganizationInstanceType } }) => {
      toast(`${response.data.data.name} changes saved.`, { type: 'success' });
      queryClient.invalidateQueries(['organizations', 'datasources', 'list', response.data.data.organization_id]);
      queryClient.invalidateQueries(['datasources', 'list']);
      queryClient.invalidateQueries(['datasources', response.data.data.id.toString()]);
    }
  });
};

const useDatasourcesDelete = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (ids: string[]) => deleteDatasources(ids),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Datasource(s) deleted', { type: 'success' });
      queryClient.invalidateQueries(['datasources', 'list']);
      queryClient.invalidateQueries(['organizations', 'datasources', 'list']);
      dispatch(clearAlert());
    }
  });
};

const useDatasourcesListData = (pagination: PaginationParams, search?: SearchParams, filter?: FilterType) => {
  const { sort, offset, limit } = pagination;
  return useQuery({
    queryKey: ['datasources', 'list', filter, pagination, { search: search?.text }],
    queryFn: () => fetchPaginatedDatasourcesData({ sort, offset, limit, search, filter }),
    refetchOnWindowFocus: false
  });
};

export { useAddNewDatasource, useDatasourceDetail, useDatasourceEdit, useDatasourcesDelete, useDatasourcesListData };
