import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash.debounce';
import { IconSearch } from '../Icon/IconSearch';
import { RollioTheme } from '../../theme/RollioTheme';
import { BaseIconProps } from '../Icon/BaseIcon';

interface SearchInputProps {
  placeholder?: string;
  onChange: Dispatch<SetStateAction<SearchParams>>;
}

const StyledWrapped = styled.div(
  ({ theme }) => css`
    border-radius: 4px;
    height: 40px;
    gap: 4px;
    display: flex;
    align-items: center;
    border: 2px solid ${theme.borders.first};
    padding: 5px 10px;
  `
);

const IconWrapper = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  align-items: center;
  display: flex;
`;

const StyledInput = styled.input`
  font-family: Lato, serif;
  font-weight: 500;
  font-size: 1rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.texts.primary};
`;

const StyledSearchIcon = styled(IconSearch).attrs<BaseIconProps & { theme: RollioTheme }>(({ theme }) => ({
  color: theme.icons.secondary,
  size: 20
}))``;

export const SearchInput: React.FC<SearchInputProps> = ({ onChange, placeholder, ...props }) => {
  const inputRef = useRef(null);
  const onSearchChange = (event: any) => {
    onChange({ text: event.target.value });
  };

  const debouncedResults = useMemo(() => {
    return debounce(onSearchChange, 300);
  }, [onSearchChange]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <StyledWrapped {...props}>
      <IconWrapper onClick={() => inputRef.current && inputRef.current.focus()}>
        <StyledSearchIcon />
      </IconWrapper>
      <StyledInput ref={inputRef} placeholder={placeholder} onChange={debouncedResults} />
    </StyledWrapped>
  );
};
