import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import { IconChevronRight } from '../Icon/IconChevronRight';
import { IconChevronLeft } from '../Icon/IconChevronLeft';

const DRAWER_WIDTH = 500;

const DrawerWrapper = styled.div<{ drawerPosition: number }>`
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 0 50px 0 50px;
  right: ${({ drawerPosition }) => drawerPosition}px;
  background-color: #f8f9fa;
  box-shadow: -10px 0 10px 1px rgba(0, 0, 0, 0.1);
  width: ${DRAWER_WIDTH}px;
  z-index: 1000;
  border-left: 1px solid #dee2e6;
  overflow-y: auto;
  transition: right 0.3s ease-in-out;
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 20px;
  left: 10px;
  background-color: ${colors.darkPurple};
  color: #ffffff;
  height: 25px;
  width: 25px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1001;
`;

const DraggableDrawer = ({ children, setIsOpen, isOpen }) => {
  const [drawerPosition, setDrawerPosition] = useState(-DRAWER_WIDTH + 50);
  const drawerRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setDrawerPosition(isOpen ? -DRAWER_WIDTH + 50 : 0);
  };

  return (
    <DrawerWrapper drawerPosition={isOpen ? 0 : drawerPosition} ref={drawerRef}>
      <ToggleButton onClick={handleToggle}>{isOpen ? <IconChevronRight /> : <IconChevronLeft />}</ToggleButton>
      {children}
    </DrawerWrapper>
  );
};

export default DraggableDrawer;
