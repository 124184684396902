import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, Wrapper } from '../../layouts/Common';
import { ROUTES } from '../../routes';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable } from '../../components/Table';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { getFormattedDate } from '../../utils';
import { useQueryFilter } from '../../hooks/api/common/common';
import { IconEye } from '../../components/Icon/IconEye';
import { IconTrash } from '../../components/Icon/IconTrash';
import { booleanCellFormatter } from '../../components/Table/utils';

const COLUMNS = [
  { key: 'name', label: 'Name', sortable: true },
  { key: 'published', label: 'Published', formatter: booleanCellFormatter },
  { key: 'updated_at', label: 'Last Update Date', sortable: true }
];

export const Conversations: React.FC = () => {
  const navigate = useNavigate();
  const paginationAndSorting = usePaginationAndSorting();
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [tableRows, setTableRows] = useState([]);
  const queryFilter = useQueryFilter();
  const userPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );

  const { isLoading, total, conversations } = {
    isLoading: false,
    total: 1,
    conversations: [{ name: 'Demo', published: true, updated_at: '' }]
  };

  useEffect(() => {
    paginationAndSorting.reset();
  }, [queryFilter]);

  const parseConversationsToTable = useCallback(
    data =>
      data?.length
        ? data.map(({ id, published, name, updated_at }) => ({
          id,
          name,
          published,
          updated_at: getFormattedDate(updated_at)
        }))
        : [],
    []
  );

  useEffect(() => {
    const rows = parseConversationsToTable(conversations);
    setTableRows(rows);
  }, [conversations]);

  const actions = useMemo(
    () => [
      {
        name: 'Detail',
        icon: IconEye,
        onClick: row => {
          navigate(`/${ROUTES.conversations}/${row.id}`);
        }
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: null
      }
    ],
    [ROUTES.conversations, navigate]
  );

  return (
    <Wrapper>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <Title>Conversations</Title>
        <RecordTable
          data={tableRows}
          setSearch={setSearchParams}
          pagination={paginationAndSorting}
          total={total}
          isLoading={isLoading}
          actions={actions}
          columns={COLUMNS}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
