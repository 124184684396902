import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ToolTip } from '../../ToolTip';
import { CommonButton } from '../../Buttons/CommonButton';
import SalesforceLogo from '../../../assets/salesforce-logo.png';
import { SimpleButton } from '../../Dashboard/SimpleButton';
import IconRollio from '../../Icon/IconRollio';
import { IconChevronLeft } from '../../Icon/IconChevronLeft';
import { IconChevronRight } from '../../Icon/IconChevronRight';
import { RawUserData } from '../../../hooks/api/Users/queries';
import { IconRestart } from '../../Icon/IconRestart';
import { TransferTable } from './parts/BasicTable';

const UsersTableContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 500px;
`;

const StyledLogo = styled.img`
  width: 30px;
  height: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  margin-top: 15px;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const TransfersButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button(({ disabled }) => {
  return css`
    background-color: ${({ theme }) => (disabled ? theme.buttons.disabled : theme.buttons.primary)};
    border: none;
    height: 40px;
    color: ${({ theme }) => (disabled ? theme.icons.disabled : theme.icons.onColor)};
    justify-content: center;
    border-radius: 4px;
    vertical-align: middle;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  `;
});

const ChevronLeft = styled(IconChevronLeft).attrs(({ theme, disabled }) => ({
  size: 'small',
  color: disabled ? theme.icons.disabled : theme.icons.primary
}))``;

const ChevronRight = styled(IconChevronRight).attrs(({ theme, disabled }) => ({
  size: 'small',
  color: disabled ? theme.icons.disabled : theme.icons.primary
}))``;

const ResetSyncIcon = styled(IconRestart).attrs(({ theme, disabled }) => ({
  size: 'small',
  color: disabled ? theme.icons.disabled : theme.icons.primary
}))``;

const ChevronButton = ({ icon: ArrowIcon, disabled, ...props }) => {
  return (
    <StyledButton disabled={disabled} {...props}>
      <ArrowIcon disabled={disabled} />
    </StyledButton>
  );
};

interface SyncUsersTableProps {
  users: RawUserData[];
  deploymentId: string;
  deploymentName: string;
  closeModal: () => void;
  onConfirm: ({ usersToAdd, usersToRemove }: { usersToAdd: RawUserData[]; usersToRemove: RawUserData[] }) => void;
  logout: () => void;
  userEmail: string;
}

const getNewUsers = (currentUsers: RawUserData[], initialUsers: RawUserData[]) => {
  return currentUsers.filter(user => !initialUsers.some(initialUser => initialUser.id === user.id));
};

const filterUsers = (users: RawUserData[], searchQuery: string) =>
  users.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase()));

const InteractiveTable: React.FC<SyncUsersTableProps> = ({
  users,
  deploymentId,
  deploymentName,
  onConfirm,
  closeModal,
  logout,
  userEmail
}) => {
  const [sourceUsers, setSourceUsers] = useState<RawUserData[]>([]);
  const [destinationUsers, setDestinationUsers] = useState<RawUserData[]>([]);

  const [selectedSourceUserIds, setSelectedSourceUserIds] = useState<number[]>([]);
  const [selectedDestinationUserIds, setSelectedDestinationUserIds] = useState<number[]>([]);

  const [sourceSearchQuery, setSourceSearchQuery] = useState('');
  const [destinationSearchQuery, setDestinationSearchQuery] = useState('');

  const usersToRemove = useMemo(
    () =>
      getNewUsers(
        sourceUsers,
        users.filter(
          (user: RawUserData) => !(user.deployments?.length && user.deployments[0]?.id.toString() === deploymentId)
        )
      ),
    [users, sourceUsers, deploymentId]
  );

  const usersToAdd = useMemo(
    () =>
      getNewUsers(
        destinationUsers,
        users.filter(
          (user: RawUserData) => user.deployments?.length && user?.deployments[0]?.id.toString() === deploymentId
        )
      ),
    [users, destinationUsers, deploymentId]
  );

  const filteredSourceUsers = useMemo(
    () => filterUsers(sourceUsers, sourceSearchQuery),
    [sourceUsers, sourceSearchQuery]
  );
  const filteredDestinationUsers = useMemo(
    () => filterUsers(destinationUsers, destinationSearchQuery),
    [destinationUsers, destinationSearchQuery]
  );

  const initializeUsers = useCallback(() => {
    const initialSelectedUsers = users.filter(
      (user: RawUserData) => user.deployments?.length && user?.deployments[0]?.id.toString() === deploymentId
    );

    const initialAllUsers = users.filter(
      (user: RawUserData) => !(user.deployments?.length && user.deployments[0]?.id.toString() === deploymentId)
    );

    setSourceUsers(initialAllUsers);
    setDestinationUsers(initialSelectedUsers);
    setSelectedSourceUserIds([]);
    setSelectedDestinationUserIds([]);
  }, [users]);

  useEffect(() => {
    initializeUsers();
  }, [users]);

  const handleSourceUserSelection = useCallback((userId: number) => {
    setSelectedSourceUserIds(prevSelectedUserIds => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter(id => id !== userId);
      }
      return [...prevSelectedUserIds, userId];
    });
  }, []);

  const handleDestinationUserSelection = useCallback((userId: number) => {
    setSelectedDestinationUserIds(prevSelectedUserIds => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter(id => id !== userId);
      }
      return [...prevSelectedUserIds, userId];
    });
  }, []);

  const handleSourceSelectAll = (isChecked: boolean) => {
    setSelectedSourceUserIds(isChecked ? sourceUsers.map(user => user.id) : []);
  };

  const handleDestinationSelectAll = (isChecked: boolean) => {
    setSelectedDestinationUserIds(isChecked ? destinationUsers.map(user => user.id) : []);
  };

  const handleUserTransfer = useCallback(
    (isLeftToRight: boolean) => {
      if (isLeftToRight) {
        const usersToTransfer = sourceUsers.filter(user => selectedSourceUserIds.includes(user.id));
        setSourceUsers(prevUsers => prevUsers.filter(user => !selectedSourceUserIds.includes(user.id)));
        setDestinationUsers(prevUsers => [...prevUsers, ...usersToTransfer]);
        setSelectedSourceUserIds([]);
      } else {
        const usersToTransfer = destinationUsers.filter(user => selectedDestinationUserIds.includes(user.id));
        setDestinationUsers(prevUsers => prevUsers.filter(user => !selectedDestinationUserIds.includes(user.id)));
        setSourceUsers(prevUsers => [...prevUsers, ...usersToTransfer]);
        setSelectedDestinationUserIds([]);
      }
    },
    [sourceUsers, destinationUsers, selectedSourceUserIds, selectedDestinationUserIds]
  );

  const onConfirmation = useCallback(
    () =>
      onConfirm({
        usersToAdd,
        usersToRemove
      }),
    [usersToAdd, usersToRemove]
  );

  return (
    <MainWrapper>
      <UsersTableContainer>
        <TransferTable
          title='Salesforce Users'
          deploymentId={deploymentId}
          setSearchResults={setSourceSearchQuery}
          users={filteredSourceUsers}
          logo={<StyledLogo src={SalesforceLogo} />}
          selectedUserIds={selectedSourceUserIds}
          onSelectAll={handleSourceSelectAll}
          onSelection={handleSourceUserSelection}
        />
        <TransfersButtonContainer>
          <ChevronButton
            icon={ChevronRight}
            disabled={!selectedSourceUserIds.length}
            onClick={() => handleUserTransfer(true)}
          />
          <ChevronButton
            icon={ChevronLeft}
            disabled={!selectedDestinationUserIds.length}
            onClick={() => handleUserTransfer(false)}
          />
          <ToolTip message='Reset'>
            <ChevronButton
              icon={ResetSyncIcon}
              disabled={!(usersToRemove.length || usersToAdd.length)}
              onClick={() => initializeUsers()}
            />
          </ToolTip>
        </TransfersButtonContainer>
        <TransferTable
          deploymentId={deploymentId}
          setSearchResults={setDestinationSearchQuery}
          title={`${deploymentName} Users`}
          logo={<IconRollio size={20} />}
          selectedUserIds={selectedDestinationUserIds}
          users={filteredDestinationUsers}
          onSelectAll={handleDestinationSelectAll}
          onSelection={handleDestinationUserSelection}
        />
      </UsersTableContainer>
      <ButtonWrapper>
        <CommonButton
          disabled={!(usersToRemove.length || usersToAdd.length)}
          label='Confirm User Sync'
          onClick={() => onConfirmation()}
        />
        <SimpleButton outline style={{ marginLeft: 10 }} label='Cancel' onClick={closeModal} />
        <ToolTip position='top' message={`Logout with ${userEmail}`}>
          <CommonButton style={{ marginLeft: 10 }} label='Logout' color='red' onClick={() => logout()} />
        </ToolTip>
      </ButtonWrapper>
      ;
    </MainWrapper>
  );
};

export default InteractiveTable;
