import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldLabel } from '../Conversations/Common';
import { colors } from '../../theme';
import { IconChevronUp } from '../Icon/IconChevronUp';
import { IconChevronDown } from '../Icon/IconChevronDown';

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 0;
  color: white;
  cursor: pointer;
`;

const TabContent = styled.div<{ isOpen: boolean }>`
  background-color: #f1f1f1;
  padding: 10px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const ArrowWrapper = styled.div`
  width: 45px;
  background-color: ${colors.greyDark};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CollapsibleTab = ({ title, info, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TabWrapper>
      <TabHeader onClick={toggleTab}>
        <FieldLabel label={title} info={info} />
        <ArrowWrapper>{isOpen ? <IconChevronUp /> : <IconChevronDown />}</ArrowWrapper>
      </TabHeader>
      <TabContent isOpen={isOpen}>{children}</TabContent>
    </TabWrapper>
  );
};

export default CollapsibleTab;
