import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { useAuth } from '../../hooks';
import { RollioTheme } from '../../theme/RollioTheme';
import { BaseIconProps } from '../Icon/BaseIcon';
import { IconLogout } from '../Icon/IconLogout';
import IconRollio from '../Icon/IconRollio';
import { Body } from '../../theme/typography/Text';
import ThemeToggle from '../ThemeToggle';

export interface SidebarProps {
  isVisible?: boolean;
  links: SidebarLink[];
  logoutLabel?: string;
  onLogoutClick?: () => void;
  titleLabel?: string;
}

export interface SidebarLink {
  id: string;
  label: string;
  active: boolean;
  icon: React.FC<BaseIconProps>;
  path: string;
}

const StyledButton = styled.button<{ isSelected?: boolean; theme: RollioTheme }>(({ isSelected, theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    cursor: pointer;
    height: 65px;
    min-width: 100%;
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.background};

    ${isSelected &&
    css`
      background-color: ${theme.colors.primaryVariant};

      :hover {
        p {
          color: ${theme.colors.primaryVariant};
        }
      }
    `}
    :hover {
      background-color: ${theme.layers.first};
    }
  `;
});

const Wrapper = styled.div`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

const IconWrapper = styled.div`
  margin-top: 10px;
`;

const StyledLabel = styled.p<{ isSelected?: boolean; theme: RollioTheme }>(
  ({ isSelected, theme }) => css`
    font-family: 'Open Sans', serif;
    font-weight: ${isSelected ? 400 : 200};
    margin-top: 5px;
    font-size: 0.7rem;
    color: ${isSelected ? theme.texts.onColor : theme.texts.secondary};
  `
);

const StyledRollioLabel = styled(Body)`
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;

const Drawer = styled.div`
  width: 150px;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  transition: left 0.3s ease;
`;

const LogoWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
`;

const FooterContainer = styled(Container)`
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const Button: React.FC<SidebarLink> = ({ id, label, active, icon: SidebarIcon, path }) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const theme = useTheme();
  const iconColor = active ? (buttonHovered ? theme.colors.primary : theme.icons.onColor) : theme.colors.primary;

  return (
    <Link id={id} to={path} style={{ textDecoration: 'none' }}>
      <StyledButton
        isSelected={active}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}>
        <IconWrapper>
          <SidebarIcon color={iconColor} />
        </IconWrapper>
        <Wrapper>
          <StyledLabel isSelected={active}>{label}</StyledLabel>
        </Wrapper>
      </StyledButton>
    </Link>
  );
};

const LogoutButton: React.FC = () => {
  const { logout } = useAuth();
  const theme = useTheme();

  return (
    <StyledButton onClick={logout}>
      <IconWrapper>
        <IconLogout color={theme.colors.primary} />
      </IconWrapper>
      <Wrapper>
        <StyledLabel>Logout</StyledLabel>
      </Wrapper>
    </StyledButton>
  );
};

export const AppSidebar: React.FC<SidebarProps> = ({ links }) => {
  return (
    <Drawer>
      <Container>
        <LogoWrapper>
          <IconRollio size={45} />
          <StyledRollioLabel>STUDIO</StyledRollioLabel>
        </LogoWrapper>
        {links.map(link => (
          <Button key={link.id} {...link} />
        ))}
      </Container>
      <FooterContainer>
        <ThemeToggle />
        <LogoutButton />
      </FooterContainer>
    </Drawer>
  );
};
