import React from 'react';
import { ToastContainer as ReactToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';
import { colors } from '../theme';

const StyledToastContainer = styled(ReactToastContainer)`
  .Toastify__toast {
    background-color: ${colors.white};
    color: ${colors.secondaryBlue};
    font-family: 'Open Sans', serif;
    padding-left: 17px;

    &:before {
      content: '';
      width: 7px;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
    }

    .Toastify__close-button {
      color: ${colors.greyLight};
    }

    &--success:before {
      background: ${colors.primary};
    }

    &--error:before {
      background: ${colors.red};
    }
  }
`;

export const ToastContainer: React.FC<Omit<ToastContainerProps, 'theme'>> = props => (
  <StyledToastContainer autoClose={2000} hideProgressBar {...props} />
);
