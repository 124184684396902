import React from 'react';

import { Dashboard as DashboardLayout } from 'src/layouts/Dashboard';
import { PageWrap } from 'src/components/PageWrap';

export const Dashboard: React.FC = () => (
  <PageWrap>
    <DashboardLayout />
  </PageWrap>
);
