import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../../../theme';
import { SearchInput } from '../../../../Input/SearchInput';

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const TableContainer = styled.table`
  border: none;
  width: 100%;
  max-height: 200px;
  border-collapse: collapse;
  padding-right: 0;
  padding-bottom: 20px;
`;

const TableRow = styled.tr<{ disabled: boolean }>(({ disabled }) => {
  return css`
    max-height: 25px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    border: none;
    vertical-align: middle;
    background-color: white;

    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  `;
});

const TableData = styled.td<{ disabled: boolean }>(
  ({ disabled }) => css`
    padding: 8px;
    text-align: left;
    font-size: 14px;
    font-family: 'Open Sans', serif;
    color: ${disabled ? colors.disabledGrey : colors.greyDark};
    font-style: ${disabled ? 'italic' : 'normal'};
  `
);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  overflow-y: auto;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.disabledGrey};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }
`;

const TableHead = styled.div`
  max-height: 50px;
  width: 100%;
  display: flex;
  flex: 1;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.greyDark};
  border-radius: 5px 5px 0 0;
  color: white;
  font-weight: bold;
  font-family: 'Open Sans', serif;
`;

const SearchWrapper = styled.div`
  max-height: 50px;
  width: 100%;
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0;
  background-color: #ddd;
  font-family: 'Open Sans', serif;
`;

const WrapperTable = styled.div`
  display: flex;
  flex: 1;
  border: 5px solid ${colors.inputGrey};
  border-radius: 10px;
  padding-bottom: 3px;
  flex-direction: column;
  max-width: 400px;
  max-height: 300px;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const EntityTableRow = ({ field, onRowSelect, disabled }) => (
  <TableRow disabled={disabled} onClick={disabled ? null : () => onRowSelect(field)}>
    <TableData disabled={disabled}>{field.label}</TableData>
    <TableData disabled={disabled}>{field.id}</TableData>
  </TableRow>
);

const RowTitle = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: bold;
  margin: 0;
  color: ${colors.primary};
  font-size: 1rem;
  line-height: 1rem;
`;

const LabelHead = styled.th`
  display: table-cell;
  vertical-align: middle;
  position: sticky;
  text-align: start;
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 10px 0 10px;
  text-align: start;
  flex: 1;
  width: 100%;
  align-items: center;
  flex-direction: row;
`;

const MiniTableHead = styled.thead`
  height: 35px;
  border-bottom: ${colors.auxiliaryGrey} solid 2px;
`;

const EntityTable = ({ fields, onRowSelect, maxItems, columns, checkedEntities }) => (
  <React.Fragment>
    <MiniTableHead>
      {columns.map(column => (
        <LabelHead key={column.key}>
          <HeadContainer>
            <RowTitle>{column.label}</RowTitle>
          </HeadContainer>
        </LabelHead>
      ))}
    </MiniTableHead>
    <tbody>
      <tr>
        <td colSpan={2}>
          <TableContainer>
            <tbody>
              {fields.map(field => {
                const isDisabled =
                  checkedEntities.length >= maxItems || checkedEntities.find(obj => obj.id === field.id) !== undefined;
                return <EntityTableRow key={field.id} onRowSelect={onRowSelect} disabled={isDisabled} field={field} />;
              })}
            </tbody>
          </TableContainer>
        </td>
      </tr>
    </tbody>
  </React.Fragment>
);

const searchArray = (array, searchTerm) => {
  const searchRegex = new RegExp(searchTerm, 'i');
  return array.filter(item => searchRegex.test(item.email));
};

const DroppableTable = ({
  title,
  columns,
  fields,
  maxItems,
  handleClick,
  selected,
  searchResults,
  setSearchResults
}) => {
  const search = useCallback(
    (searchTerm: SearchParams) => {
      if (searchTerm && searchTerm.text) {
        const searchResults = searchArray(fields, searchTerm.text);
        setSearchResults(searchResults);
      } else {
        setSearchResults([]);
      }
    },
    [setSearchResults, fields]
  );

  return (
    <WrapperTable>
      <TableHead>
        <TitleWrapper>{title}</TitleWrapper>
      </TableHead>
      <SearchWrapper>
        <SearchInput onChange={search} />
      </SearchWrapper>
      <Wrapper>
        <TableContainer>
          <EntityTable
            columns={columns}
            maxItems={maxItems}
            fields={searchResults.length ? searchResults : fields}
            onRowSelect={handleClick}
            checkedEntities={selected}
          />
        </TableContainer>
      </Wrapper>
    </WrapperTable>
  );
};

const MiniTable = ({ objectName, selectedFields, setSelectedFields, columns, lookupFields, maxItems }) => {
  const [fields, setAllFields] = useState(lookupFields);
  useEffect(() => {
    setAllFields(lookupFields);
  }, [lookupFields]);
  const [searchResults, setSearchResults] = useState([]);

  return (
    <MainWrapper>
      <DroppableTable
        title={`${objectName} fields`}
        columns={columns}
        selected={selectedFields}
        maxItems={maxItems}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        fields={fields}
        handleClick={value => {
          setSelectedFields([...selectedFields, value]);
        }}
      />
    </MainWrapper>
  );
};

export default MiniTable;
