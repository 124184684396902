import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { instance } from '@rollioforce/rollio-schemas';
import { useSelector } from 'react-redux';
import { getActiveOrganization, getOrganizations } from '../../store/reducers/globalFilter';
import { usePermission } from '../../hooks';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { useDatasourceDetail, useDatasourceEdit } from '../../hooks/api/Datasources/hooks';
import { toast } from 'react-toastify';
import { Wrapper } from '../../layouts/Common';
import Spinner from '../../components/Spinner';

export interface DatasourceFormProps {
  title: string;
  onSubmit: (data: OrganizationInstanceType) => Promise<void>;
  datasourceId?: string;
  datasource?: OrganizationInstanceType;
}

export const Datasource: React.FC<DatasourceFormProps> = () => {
  const { isInternal } = usePermission();
  const { datasourceId } = useParams();
  const { data: initialData, isLoading } = useDatasourceDetail(datasourceId);
  const { mutate: editRecord } = useDatasourceEdit();
  const activeOrg = useSelector(getActiveOrganization);
  const organizations = useSelector(getOrganizations);

  const formData = useMemo(() => {
    if (initialData) {
      return {
        ...initialData,
        organization_id: initialData.organization.id.toString()
      };
    }
    return initialData;
  }, [initialData]);

  const ORGANIZATIONS_SELECT = useMemo(
    () =>
      initialData
        ? [{ label: initialData.organization.name, value: initialData.organization.id.toString() }]
        : !isInternal
        ? [activeOrg]
        : organizations.map(i => ({
          label: isInternal ? `${i.name} [${i.id}]` : i.name,
          value: String(i.id)
        })),
    [organizations, activeOrg, initialData, isInternal]
  );

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        type: 'text'
      },
      {
        key: 'login_url',
        label: 'Salesforce Login Url',
        info: 'This is the Salesforce Instance Login url. Example: https://login.salesforce.com',
        type: 'text'
      },
      {
        key: 'organization_id',
        label: 'Organization',
        type: 'select',
        options: ORGANIZATIONS_SELECT
      },
      {
        key: 'is_active',
        label: 'Active',
        type: 'checkbox',
        isVisible: isInternal
      },
      {
        key: 'updated_at',
        label: 'Last Update Date',
        info: 'This field is automatically updated when the record is modified.',
        type: 'date',
        placeholder: 'No updates yet',
        onlyDisplay: true
      }
    ],
    [ORGANIZATIONS_SELECT]
  );

  const onFormSubmit = useCallback(
    (data: OrganizationInstanceType) => {
      try {
        editRecord({ id: datasourceId, body: data });
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [editRecord, datasourceId]
  );

  return (
    <Wrapper>
      {isLoading ? (
        <Spinner fullscreen />
      ) : (
        <GenericForm
          title={initialData?.name}
          name='edit_datasource'
          fields={FORM_FIELDS}
          schema={instance}
          initialValues={formData}
          onSubmit={onFormSubmit}
        />
      )}
    </Wrapper>
  );
};
