import React from 'react';

import { useAuth, useNavGuard, usePermission } from 'src/hooks';

import { Error } from './Error';
import Spinner from './Spinner';

interface Props {
  children: any;
}

export const PageWrap: React.FC<Props> = ({ children }) => {
  useNavGuard();
  const { isLoggedIn } = useAuth();
  const { hasRouteAccess } = usePermission();

  if (!isLoggedIn) {
    return <Spinner fullscreen />;
  }

  if (!hasRouteAccess) {
    return <Error title='403 Forbidden'>You don't have permission to access this page</Error>;
  }

  return <>{children}</>;
};
