import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TableHeaderWrapper, Title, Wrapper } from '../../layouts/Common';
import { ROUTES } from '../../routes';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable } from '../../components/Table';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { usePermission, useUserProfile } from '../../hooks';
import { getUserName } from '../../utils';
import { CommonButton } from '../../components/Buttons/CommonButton';
import { setAlert } from '../../store/reducers/ui';
import { useQueryFilter } from '../../hooks/api/common/common';
import { IconEye } from '../../components/Icon/IconEye';
import { IconTrash } from '../../components/Icon/IconTrash';
import { IconReset } from '../../components/Icon/IconReset';
import { booleanCellFormatter, linkCellFormatter } from '../../components/Table/utils';
import {
  useAdministratorsUsersData,
  useDeleteAdministrators,
  useResetPassword
} from '../../hooks/api/Administrators/hooks';
import { RawAdministratorData } from '../../hooks/api/Administrators/queries';
import { AdministratorRow } from '../../layouts/Organizations/Parts/AdminManagement';
import { AddAdministratorModal } from '../../layouts/Administrators/AddAdministratorModal';

const COLUMNS = [
  { key: 'name', label: 'Name', sortable: true },
  { key: 'email', label: 'Email' },
  { key: 'organization', label: 'Organization', formatter: linkCellFormatter },
  { key: 'is_active', label: 'Active', formatter: booleanCellFormatter }
];

export const Administrators: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInternal } = usePermission();
  const { id } = useUserProfile();
  const paginationAndSorting = usePaginationAndSorting();
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const [isNewAdministratorModalOpen, setNewAdministratorModalOpen] = useState(false);
  const { mutate: deleteRecord } = useDeleteAdministrators();
  const { mutate: resetPassword } = useResetPassword();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [tableRows, setTableRows] = useState([]);
  const queryFilter = useQueryFilter();

  const userPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );

  const { isLoading, data: response } = useAdministratorsUsersData(userPagination, searchParams, {
    organization_id: queryFilter?.organization ?? null
  });

  useEffect(() => {
    paginationAndSorting.reset();
  }, [queryFilter]);

  const handleReset = useCallback((row: AdministratorRow) => resetPassword(row.email), [resetPassword]);

  const parseUsersToTableData = useCallback(
    (data: RawAdministratorData[]) =>
      data?.length
        ? data.map(({ id, first_name, last_name, is_active, email, organization_user }) => ({
          id,
          name: getUserName(first_name, last_name),
          email,
          organization: isInternal
              ? {
                name: organization_user.organization.name,
                onClick: () => {
                  navigate(`/${ROUTES.organizations}/${organization_user.organization.id}`);
                }
              }
              : organization_user.organization.name,
          is_active
        }))
        : [],
    [navigate, isInternal]
  );

  useEffect(() => {
    if (response?.data) {
      setTableRows(parseUsersToTableData(response?.data));
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const actions = useMemo(
    () => [
      {
        name: 'Detail',
        icon: IconEye,
        onClick: (row: AdministratorRow) => {
          navigate(`/${ROUTES.administrators}/${row.id}`);
        }
      },
      {
        name: 'Reset Password',
        icon: IconReset,
        onClick: handleReset
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: AdministratorRow) => {
          dispatch(
            setAlert({
              title: `Delete ${row.name}`,
              message: `Are you sure you want to delete ${row.name}?`,
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => deleteRecord([row.id])
              }
            })
          );
        },
        isDisabled: (row: AdministratorRow) => id === row.id
      }
    ],
    [navigate, handleReset, deleteRecord, id, dispatch]
  );

  return (
    <Wrapper>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <AddAdministratorModal visible={isNewAdministratorModalOpen} setVisible={setNewAdministratorModalOpen} />
        <TableHeaderWrapper>
          <Title>Administrators</Title>
          <CommonButton label='+ Add Administrator' onClick={() => setNewAdministratorModalOpen(true)} />
        </TableHeaderWrapper>
        <RecordTable
          data={tableRows}
          total={response?.total}
          pagination={paginationAndSorting}
          isLoading={isLoading}
          setSearch={setSearchParams}
          selectable={false}
          actions={actions}
          columns={COLUMNS}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
