import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactFlow, { Background, Controls, MiniMap } from 'reactflow';
import styled from 'styled-components';
import { CustomEdge, CustomNode, NODE_TYPES } from './Elements';
import { findPathEdges, isLeafNode } from './Logic';
import ContextMenu from './Menu';
import { colors } from '../../theme';
import { ConversationEditorContext } from './Context';
import { IconAdd } from '../Icon/IconAdd';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlowChart = ({ onClickNode }) => {
  const {
    nodes,
    edges,
    deleteNode,
    addChild,
    selectedElement,
    setSelectedElement,
    addNodeBetween,
    endConversationFlow,
    reset
  } = useContext(ConversationEditorContext);

  useEffect(() => {
    return reset();
  }, []);

  const [menuItems, setMenuItems] = useState([]);
  const [edgeIdsWithColor, setEdgesToColor] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);

  const contextMenuItems = useMemo(
    () => [
      {
        icon: IconAdd,
        label: 'Add Node',
        submenu: [
          { label: 'Slot', action: ({ nodeId }) => addChild(nodeId, NODE_TYPES.ENTITY) },
          { label: 'Decision Point', action: ({ nodeId }) => addChild(nodeId, NODE_TYPES.DECISION_POINT) }
        ]
      },
      { icon: 'trash', label: 'Delete Node', action: ({ nodeId }) => deleteNode(nodeId) },
      { icon: 'end', label: 'Finish Conversation', action: ({ nodeId }) => endConversationFlow(nodeId) }
    ],
    [addChild, deleteNode]
  );

  const interMenuItems = useMemo(
    () => [
      {
        icon: IconAdd,
        label: 'Add Node',
        submenu: [
          { label: 'Slot', action: ({ source, target }) => addNodeBetween(source, target, NODE_TYPES.ENTITY) },
          {
            label: 'Decision Point',
            action: ({ source, target }) => addNodeBetween(source, target, NODE_TYPES.DECISION_POINT)
          }
        ]
      }
    ],
    [addNodeBetween, deleteNode]
  );

  const handleContextMenu = (event, selectedNode, tempMenuItems = contextMenuItems) => {
    event.preventDefault();
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setMenuItems(tempMenuItems);
    setSelectedElement(selectedNode);
  };
  const handleContextMenuClose = () => {
    setContextMenuPosition(null);
  };
  const onLoad = reactFlowInstance => {
    reactFlowInstance.fitView();
  };

  const onElementClick = useCallback(
    (ev, element) => {
      const pathEdges = findPathEdges(edges, '1', element.id);
      onClickNode(element);
      setEdgesToColor(pathEdges);
    },
    [edges]
  );

  const nodeTypes = useMemo(
    () => ({
      special: props => (
        <CustomNode
          {...props}
          deleteNode={deleteNode}
          onContextMenu={handleContextMenu}
          isLeafNode={isLeafNode(props.id, edges)}
        />
      )
    }),
    [addChild, deleteNode, handleContextMenu, edges]
  );

  const edgeTypes = useMemo(
    () => ({
      custom: props => (
        <CustomEdge
          {...props}
          menuItems={interMenuItems}
          setSelectedEdge={setSelectedElement}
          style={{
            stroke: edgeIdsWithColor.includes(props.id) ? colors.red : 'black'
          }}
          handleContextMenu={handleContextMenu}
        />
      )
    }),
    [addNodeBetween, edgeIdsWithColor, interMenuItems, handleContextMenu]
  );

  useEffect(() => {
    const handleClick = event => {
      if (contextMenuPosition && !event.target.closest('.context-menu')) {
        handleContextMenuClose();
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [contextMenuPosition]);

  return (
    <Container>
      <ReactFlow
        onContextMenu={event => {
          event.preventDefault();
        }}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={false}
        onNodeClick={onElementClick}
        nodes={nodes}
        edges={edges}
        onInit={onLoad}>
        {contextMenuPosition && (
          <ContextMenu
            id={selectedElement}
            position={contextMenuPosition}
            onClose={handleContextMenuClose}
            items={menuItems}
          />
        )}
        <Background color='#aaa' gap={16} />
        <MiniMap />
        <Controls />
      </ReactFlow>
    </Container>
  );
};

export default FlowChart;
