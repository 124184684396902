import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';
import { CommonButton } from '../../../components/Buttons/CommonButton';
import { isEmail } from '../../../utils';
import { DisplayChip, EmailChip } from '../../../components/Chip';

const StyledErrorMessage = styled.p`
  font-family: 'Open Sans', serif;
  font-size: 0.9rem;
  height: 0.9rem;
  text-align: center;
  color: ${colors.red};
  margin: 0;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 45px;
  font-family: 'Open Sans', serif;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  padding: 20px 20px 10px 20px;
  border-radius: 20px;
  flex-direction: column;
`;

const TagContainer = styled.div`
  display: block;
  flex: 1;
  overflow-x: hidden;
  background-color: ${colors.inputGrey};
  width: 100%;
  min-height: 350px;
  max-height: 350px;
  padding: 15px;
  border-radius: 20px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.disabledGrey};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: transparent;
  }
`;

const EmailArea = ({ items, setItems, handleSubmit, resultEmails, value, setValue, error, setError }) => {
  const hasRepeatedMails = resultEmails && resultEmails.repeated && resultEmails.repeated.length > 0;
  const hasAddedMails = resultEmails && resultEmails.added && resultEmails.added.length > 0;
  const isInList = email => {
    return items.includes(email);
  };

  const isValid = email => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const newValue = value.trim();

      if (newValue && isValid(newValue)) {
        setItems([...items, newValue]);
        setValue('');
      }
    }
  };

  const handleChange = evt => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = item => {
    setItems(items.filter(i => i !== item));
  };

  const handlePaste = evt => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w.-]+@[\w.-]+\.[\w.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email));

      setItems([...items, ...toBeAdded]);
    }
  };

  const reset = () => {
    setItems([]);
    setValue('');
    setError(null);
  };

  return (
    <Wrapper>
      <TagContainer>
        {items.map(item => (
          <EmailChip item={item} key={item} handleDelete={handleDelete} />
        ))}
        {hasAddedMails && resultEmails.added.map(item => <DisplayChip key={item} item={item} color='green' />)}
        {hasRepeatedMails && resultEmails.repeated.map(item => <DisplayChip key={item} item={item} color='red' />)}
      </TagContainer>
      {!hasRepeatedMails && (
        <div style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
          <StyledInput
            value={value}
            placeholder='Type or paste email addresses and press `Enter`...'
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          <CommonButton
            style={{ width: 100, marginLeft: 10, height: 45, marginTop: 16 }}
            disabled={!items.length}
            color='primary'
            label='Add All'
            onClick={handleSubmit}
          />
          <CommonButton
            style={{ width: 100, marginLeft: 10, height: 45, marginTop: 16 }}
            disabled={!items.length}
            color='red'
            label='Clear All'
            onClick={reset}
          />
        </div>
      )}
      <StyledErrorMessage>{hasRepeatedMails ? 'Emails in red are already added' : error}</StyledErrorMessage>
    </Wrapper>
  );
};

export default EmailArea;
