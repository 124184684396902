import React from 'react';
import styled from 'styled-components';
import InteractiveTable from '../../../../Table/Custom/InteractiveTable';
import { useAddSyncedSfUsers, useSyncSfUsers } from '../../../../../hooks/api/Users/hooks';
import Spinner from '../../../../Spinner';

const WrapperTable = styled.div`
  flex: 1;
  width: 1000px;
  padding: 10px;
`;

interface SyncUsersProps {
  sfToken: string;
  userEmail: string;
  deploymentName: string;
  deploymentId: string;
  setModalVisible: (value: boolean) => void;
  logout: () => void;
}

const SyncUserManagement: React.FC<SyncUsersProps> = ({
  sfToken,
  userEmail,
  deploymentId,
  deploymentName,
  setModalVisible,
  logout
}) => {
  const { isLoading, data: response } = useSyncSfUsers(sfToken, deploymentId);
  const { mutate: addSyncedUsersToDeployment } = useAddSyncedSfUsers(sfToken, deploymentId, setModalVisible);

  return (
    <WrapperTable>
      {isLoading && <Spinner fullscreen />}
      <InteractiveTable
        deploymentId={deploymentId}
        deploymentName={deploymentName}
        users={response?.data?.data?.usersSynced ?? []}
        onConfirm={addSyncedUsersToDeployment}
        closeModal={() => setModalVisible(false)}
        logout={logout}
        userEmail={userEmail}
      />
    </WrapperTable>
  );
};

export default SyncUserManagement;
