import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../routes';
import { setActiveDatasource, setActiveOrganization } from '../../store/reducers/globalFilter';
import { usePermission } from '../../hooks';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { TableHeaderWrapper, Title, Wrapper } from '../../layouts/Common';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable, Row } from '../../components/Table';
import { CommonButton } from '../../components/Buttons/CommonButton';
import { getFormattedDate, uniqueToast } from '../../utils';
import { useQueryFilter } from '../../hooks/api/common/common';
import { setAlert } from '../../store/reducers/ui';
import { IconEye } from '../../components/Icon/IconEye';
import { IconTrash } from '../../components/Icon/IconTrash';
import { SimpleButton } from '../../components/Dashboard/SimpleButton';
import { useDatasourcesDelete, useDatasourcesListData } from '../../hooks/api/Datasources/hooks';
import { RawDatasourceData } from '../../hooks/api/Datasources/types';
import { NewDatasourceModal } from '../../layouts/Datasources/NewDatasource';
import { booleanCellFormatter, linkCellFormatter } from '../../components/Table/utils';

const COLUMNS = [
  { key: 'name', label: 'Datasource', sortable: true },
  { key: 'organization', label: 'Organization', formatter: linkCellFormatter },
  { key: 'users_count', label: 'Users', formatter: linkCellFormatter },
  { key: 'is_active', label: 'Active', formatter: booleanCellFormatter },
  { key: 'updated_at', label: 'Last Update Date', sortable: true }
];

export const Datasources: React.FC = () => {
  const navigate = useNavigate();
  const { isInternal } = usePermission();
  const { mutate: deleteBulkRecords } = useDatasourcesDelete();
  const dispatch = useDispatch();
  const paginationAndSorting = usePaginationAndSorting();
  const [isNewDatasourceModalOpen, setNewDatasourceModalOpen] = useState(false);
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [tableRows, setTableRows] = useState([]);
  const queryFilter = useQueryFilter();
  const datasourcePagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );
  const {
    isLoading,
    data: response,
    error
  } = useDatasourcesListData(datasourcePagination, searchParams, {
    organization_id: queryFilter?.organization
  });

  useEffect(() => {
    if (error) {
      uniqueToast('There was a problem fetching the datasources');
    }
  }, [error]);

  const parseDatasourceToTableData = useCallback(
    (data: RawDatasourceData[]) =>
      data?.length
        ? data.map(({ id, name, updated_at, users_count, is_active, organization }) => ({
          id,
          name,
          organization: isInternal
              ? {
                name: organization.name,
                onClick: () => {
                  navigate(`/${ROUTES.organizations}/${organization.id}`);
                }
              }
              : organization.name,
          updated_at: getFormattedDate(updated_at),
          is_active,
          users_count: {
            name: users_count,
            onClick: () => {
              dispatch(setActiveOrganization({ label: organization.name, value: organization.id }));
              dispatch(setActiveDatasource({ label: name, value: id }));
              navigate(`/${ROUTES.users}`);
            }
          }
        }))
        : [],
    [isInternal]
  );

  useEffect(() => {
    if (response?.data.length) {
      setTableRows(parseDatasourceToTableData(response.data));
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const deleteDatasource = async (ids: string[]) => deleteBulkRecords(ids);

  const actions = useMemo(
    () => [
      {
        name: 'View',
        icon: IconEye,
        onClick: (row: Row) => {
          navigate(`/${ROUTES.datasources}/${row.id}`);
        }
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: Row) =>
          dispatch(
            setAlert({
              title: `Delete ${row.name}`,
              message: `Are you sure you want to delete ${row.name}?`,
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => deleteDatasource([row.id])
              }
            })
          )
      }
    ],
    [ROUTES.datasource, navigate]
  );

  const Header = useCallback(() => {
    return (
      <>
        {selectedIds.length ? (
          <SimpleButton
            outline
            label='Delete Selected'
            icon={IconTrash}
            onClick={() =>
              dispatch(
                setAlert({
                  title: 'Delete deployments',
                  message: 'Are you sure you want to delete the selected deployments?',
                  isOpen: true,
                  positiveButton: {
                    title: 'Confirm and Delete',
                    onClick: () => deleteDatasource(selectedIds)
                  }
                })
              )
            }
          />
        ) : null}
      </>
    );
  }, [selectedIds]);

  return (
    <Wrapper>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <NewDatasourceModal visible={isNewDatasourceModalOpen} setVisible={setNewDatasourceModalOpen} />
        <TableHeaderWrapper>
          <Title>Datasources</Title>
          <CommonButton disabled={false} label='+ Add Datasource' onClick={() => setNewDatasourceModalOpen(true)} />
        </TableHeaderWrapper>
        <RecordTable
          data={tableRows}
          total={response?.total ?? 0}
          header={Header}
          setSearch={setSearchParams}
          pagination={paginationAndSorting}
          isLoading={isLoading}
          actions={actions}
          columns={COLUMNS}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
