import React from 'react';
import styled, { css } from 'styled-components';

interface CheckboxProps {
  checked: boolean;
  onChange?: () => void;
  color?: string;
  disabled?: boolean;
}

const Icon = styled.svg(
  ({ theme }) => css`
    fill: none;
    stroke: ${theme.buttons.primary};
    stroke-width: 4px;
  `
);

const StyledCheckbox = styled.div<{ checked: boolean; disabled: boolean }>(
  ({ theme, checked, disabled }) => css`
    display: flex;
    width: 20px;
    z-index: 9999;
    height: 20px;
    border: 2px solid ${disabled ? theme.buttons.disabled : theme.buttons.secondary};
    background: transparent;
    border-radius: 3px;
    transition: all 150ms;

    ${Icon} {
      visibility: ${checked ? 'visible' : 'hidden'};
    }
  `
);
export const CheckBox: React.FC<CheckboxProps> = ({ checked, onChange, disabled = false }) => (
  <StyledCheckbox checked={checked} onClick={!disabled ? onChange : null} disabled={disabled}>
    <Icon viewBox='0 0 24 24'>
      <polyline points='20 6 9 17 4 12' />
    </Icon>
  </StyledCheckbox>
);
