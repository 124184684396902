import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { CommonButton } from '../../Buttons/CommonButton';
import { ToggleSwitch } from '../../ToggleSwitch';
import { BasicDatePicker } from '../../Dashboard/DatePicker';
import { Body } from '../../../theme/typography/Text';
import { differenceInDays, format, subDays } from 'date-fns';

export interface ReportParams {
  dateFrom: string;
  dateTo: string;
  includeSuccessfulFailed: boolean;
}

interface ReportProps {
  title: string;
  visible: boolean;
  isDisabled?: boolean;
  setVisible: (visible: boolean) => void;
  onClick: (params: ReportParams) => void;
}

const StyledDiv = styled.div`
  flex: 1;
  border-radius: 4px;
  width: 500px;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-direction: row;
`;

const FormContainer = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.second};
`;

export const GenerateReportModal: React.FC<ReportProps> = ({ title, isDisabled, visible, setVisible, onClick }) => {
  const [includeColumns, setIncludeColumns] = useState(false);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const daysDifference = differenceInDays(toDate, fromDate);
  const isDateRangeValid = daysDifference >= 0;

  const handleToggleResults = () => {
    setIncludeColumns(!includeColumns);
  };

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const generateReport = () => {
    const values: ReportParams = {
      dateFrom: format(fromDate, 'yyyy-MM-dd'),
      dateTo: format(toDate, 'yyyy-MM-dd'),
      includeSuccessfulFailed: includeColumns
    };

    return onClick(values);
  };

  return (
    <Modal isOpen={visible} onCloseClick={toggleVisible} title={title}>
      <StyledDiv>
        <FormContainer>
          <FieldWrapper>
            <Body>From Date</Body>
            <BasicDatePicker selected={fromDate} onChange={setFromDate} />
          </FieldWrapper>
          <FieldWrapper>
            <Body>To Date</Body>
            <BasicDatePicker selected={toDate} onChange={setToDate} />
          </FieldWrapper>
          <FieldWrapper>
            <Body>Show successful and failed columns</Body>
            <ToggleSwitch
              on={includeColumns}
              handleToggle={handleToggleResults}
              name='checkbox_report_columns'
              isDisabled={false}
            />
          </FieldWrapper>
        </FormContainer>
        <ButtonContainer>
          <CommonButton label='Generate Report' disabled={!isDateRangeValid || isDisabled} onClick={generateReport} />
        </ButtonContainer>
      </StyledDiv>
    </Modal>
  );
};
