import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Route, Routes, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
// Store
import store from './store';
// Hooks
import { useAuth } from 'src/hooks';
import { routes } from 'src/routes';
// Components
import { AppSidebar } from './components/AppSidebar';
import { ToastContainer } from 'src/components/ToastContainer';
// Firebase provider
import FirebaseProvider from './FirebaseProvider';
import { TopBar } from './components/TopBar/TopBar';
import { NotFound } from './pages/NotFound';
import { useSelectedTheme } from './theme/hooks';
import { ConnectedRollioAlert } from './components/Modals/Custom/RollioAlert';
import { addHeadersInterceptor, addUnauthorizedAccessInterceptor } from './hooks/api/axiosInstances';
import { RollioTheme } from './theme/RollioTheme';
import { Tooltip } from 'react-tooltip';

const MainContainer = styled.div`
  display: block;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Content = styled.div<{ isLoggedIn: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding-left: ${({ isLoggedIn }) => (isLoggedIn ? 150 : 0)}px;
`;

const RollioTooltip = styled(Tooltip)`
  z-index: 100000;
  font-family: 'Open Sans', serif;
`;

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${({ theme }: { theme: RollioTheme }) => theme.colors.background};
        margin: 0;
        padding: 0;
    }
`;

export const queryClient = new QueryClient();

addHeadersInterceptor(store);
addUnauthorizedAccessInterceptor(store);

const hasTopBar = (location: string) => {
  const url = location.split('/');

  return !(url.length > 2 && url[1] === 'conversations');
};

const ConnectedThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useSelectedTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const AppInner: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <MainContainer>
      {isLoggedIn && <AppSidebar />}
      <Content isLoggedIn={isLoggedIn}>
        {isLoggedIn && hasTopBar(currentPath) && <TopBar />}
        <ConnectedRollioAlert />
        <RollioTooltip id='rollio-tooltip' />
        <Routes>
          {routes.map(({ id, path, Component }) => (
            <Route key={id} path={path} element={<Component />} />
          ))}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Content>
    </MainContainer>
  );
};

export const App: React.FC = () => {
  const persistedStore = persistStore(store);

  return (
    <QueryClientProvider client={queryClient}>
      <PersistGate persistor={persistedStore}>
        <FirebaseProvider>
          <ConnectedThemeProvider>
            <GlobalStyle />
            <AppInner />
          </ConnectedThemeProvider>
        </FirebaseProvider>
        <ToastContainer />
      </PersistGate>
    </QueryClientProvider>
  );
};
