import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

export type SortDirectionType = 'ASC' | 'DESC' | 'OFF';

interface SortButtonProps {
  column: string;
  updateSorting: (column: string, direction: string) => void;
  direction: SortDirectionType;
}

const UpperTriangle = styled.div<{ status: SortDirectionType }>(({ status, theme }) => {
  return css`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5.5px 8px 5.5px;
    border-color: transparent transparent ${status !== 'DESC' ? theme.buttons.primary : theme.buttons.disabled}
      transparent;
  `;
});

const ButtonWrapper = styled.button`
  border: none;
  background-color: transparent;
`;

const BottomTriangle = styled.div<{ status: SortDirectionType }>(({ status, theme }) => {
  return css`
    width: 0;
    margin-top: 1px;
    height: 0;
    display: inline-block;
    border-style: solid;
    border-width: 8px 5.5px 0 5.5px;
    border-color: ${status !== 'ASC' ? theme.buttons.primary : theme.buttons.disabled} transparent transparent
      transparent;
  `;
});

export const SortButton: React.FC<SortButtonProps> = ({ column, updateSorting, direction }) => {
  const changeSortStatus = (sortStatus: SortDirectionType) => {
    if (sortStatus === 'OFF') {
      return 'ASC';
    }

    if (sortStatus === 'ASC') {
      return 'DESC';
    }

    if (sortStatus === 'DESC') {
      return 'OFF';
    }
  };

  const toggleSorting = useCallback(() => updateSorting(column, changeSortStatus(direction)), [column, direction]);

  return (
    <ButtonWrapper onClick={toggleSorting}>
      <UpperTriangle status={direction} />
      <BottomTriangle status={direction} />
    </ButtonWrapper>
  );
};
