import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

import { deployment } from '@rollioforce/rollio-schemas';
import { Modal } from '../../components/Modals/Modal';
import { usePermission } from '../../hooks';
import { useSelector } from 'react-redux';
import { getActiveOrganization, getOrganizations } from '../../store/reducers/globalFilter';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { useAddNewDeployment } from '../../hooks/api/Deployments/hooks';
import { DEFAULT_DEPLOYMENT_FORM } from '../../constants';
import { formatDeployment } from '../../parser';
import { useOrganizationDatasources } from '../../hooks/api/Organizations/hooks';
import Spinner from '../../components/Spinner';

interface NewDeploymentModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const NewDeploymentModal: React.FC<NewDeploymentModal> = ({ visible, setVisible }) => {
  const { mutate: addRecord, isLoading } = useAddNewDeployment(() => setVisible(false));
  const { isInternal } = usePermission();
  const organizations = useSelector(getOrganizations);
  const organization = useSelector(getActiveOrganization);
  const [formSelectedOrg, setFormSelectedOrg] = React.useState(organization);
  const { data: availableInstances, isLoading: isLoadingInstances } = useOrganizationDatasources(formSelectedOrg.value);

  const onFormSubmit = useCallback(
    async (data: DeploymentType) => {
      try {
        addRecord(formatDeployment(data));
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  const ORGANIZATION_SELECT_VALUES = useMemo(
    () =>
      !isInternal
        ? [organization]
        : organizations.map(i => ({
          label: isInternal ? `${i.name} [${i.id}]` : i.name,
          value: i.id.toString()
        })),
    [organizations, organization, isInternal]
  );

  const INSTANCES_SELECT_VALUES = useMemo(
    () =>
      availableInstances
        ? availableInstances?.map(i => ({
          label: isInternal ? `${i.name} [${i.id}]` : i.name,
          value: i.id.toString()
        }))
        : [],
    [availableInstances, isInternal]
  );
  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter the deployment name'
      },
      {
        key: 'organization_id',
        label: 'Organization',
        type: 'select',
        placeholder: 'No Organizations available',
        options: ORGANIZATION_SELECT_VALUES,
        onChange: setFormSelectedOrg
      },
      {
        key: 'instance_id',
        label: 'Datasource',
        info: !INSTANCES_SELECT_VALUES.length ? 'Select an organization with datasources' : null,
        type: 'select',
        placeholder: 'No datasources available',
        isLoading: isLoadingInstances,
        options: INSTANCES_SELECT_VALUES
      },
      {
        key: 'timeformat',
        label: 'Time Format',
        type: 'select',
        isVisible: isInternal,
        options: [
          {
            label: '12 Hour',
            value: '12-hour'
          },
          {
            label: '24 Hour',
            value: '24-hour'
          }
        ]
      },
      {
        key: 'is_active',
        label: 'Active',
        type: 'checkbox'
      }
    ],
    [isInternal, ORGANIZATION_SELECT_VALUES, INSTANCES_SELECT_VALUES]
  );

  const initialData = useMemo(
    () => ({
      ...DEFAULT_DEPLOYMENT_FORM,
      organization_id:
        formSelectedOrg.value !== 'all'
          ? formSelectedOrg.value
          : ORGANIZATION_SELECT_VALUES.length
          ? ORGANIZATION_SELECT_VALUES[0]?.value
          : null,
      instance_id: INSTANCES_SELECT_VALUES.length ? INSTANCES_SELECT_VALUES[0]?.value : null
    }),
    [INSTANCES_SELECT_VALUES, ORGANIZATION_SELECT_VALUES, formSelectedOrg]
  );

  return (
    <Modal isOpen={visible} title='Create New Deployment' onCloseClick={() => setVisible(false)}>
      {isLoading ? <Spinner fullscreen /> : null}
      <GenericForm
        name='new_deployment'
        initialValues={initialData}
        columns={1}
        fields={FORM_FIELDS}
        schema={deployment}
        onSubmit={onFormSubmit}
        modal
      />
    </Modal>
  );
};
