import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ACTION, RESOURCE, ROLE } from 'src/constants';
import { getRouteByUrl, Route } from 'src/routes';
import { useUserProfile } from './useUserProfile';

export const usePermission = () => {
  const location = useLocation();
  const userProfile = useUserProfile();

  const checkPermission = useCallback(
    (resource: RESOURCE, action: ACTION) =>
      (userProfile?.roles ?? []).reduce(
        (acc, role) =>
          acc ||
          role.permissions.reduce(
            (acc2, permission) => acc2 || (permission.resource_name === resource && permission.action === action),
            false
          ),
        false
      ),
    [userProfile]
  );

  const checkRole = useCallback(
    (role: ROLE) => (userProfile?.roles ?? []).reduce((acc: boolean, current) => acc || current.name === role, false),
    [userProfile]
  );

  const route = useMemo(() => {
    return getRouteByUrl(location.pathname);
  }, [location.pathname]);

  const checkRoutePermission = useCallback(
    (r: Route) => !r.permission || checkPermission(r.permission.resource, r.permission.action),
    [checkPermission]
  );

  const checkRouteRole = useCallback((r: Route) => !r.roles || r.roles.some(role => checkRole(role)), [checkRole]);

  const checkRouteAccess = useCallback(
    (r: Route) => checkRouteRole(r) && checkRoutePermission(r),
    [checkRouteRole, checkRoutePermission]
  );

  const hasRouteAccess = useMemo(() => {
    return route && checkRouteAccess(route);
  }, [route, checkRouteAccess]);

  const isInternal = useMemo(() => checkRole(ROLE.INTERNAL), [checkRole]);

  return {
    checkPermission,
    checkRole,
    checkRouteAccess,
    hasRouteAccess,
    isInternal,
    route
  };
};
