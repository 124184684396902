import axiosInstance from '../axiosInstances';
import { INSTANCES_URL } from 'src/constants';
import { PaginationParams, parsePaginatedUrl } from '../utils';
import { RawDatasourceData } from './types';

const fetchDatasourceData = async (datasourceId: string) =>
  (
    await axiosInstance.get<{
      data: OrganizationInstanceType;
    }>(`${INSTANCES_URL}/${datasourceId}`)
  ).data.data;

const fetchPaginatedDatasourcesData = async (params: PaginationParams) =>
  (
    await axiosInstance.get<{
      data: RawDatasourceData[];
      total: number;
    }>(parsePaginatedUrl(`${INSTANCES_URL}/search`, params))
  ).data;

const addNewDatasource = async (datasource: OrganizationInstanceType) =>
  await axiosInstance.post<{ data: OrganizationInstanceType }>(`${INSTANCES_URL}`, datasource);

const editDatasource = async (id: string, datasource: OrganizationInstanceType) =>
  await axiosInstance.put<{ data: OrganizationInstanceType }>(`${INSTANCES_URL}/${id}`, datasource);

const deleteDatasources = async (ids: string[]) =>
  await axiosInstance.delete<void>(`${INSTANCES_URL}`, {
    data: ids
  });

const fetchInstanceObjects = async (instanceId: string, sfToken: string) =>
  axiosInstance.get(`${INSTANCES_URL}/${instanceId}/object-list`, {
    headers: {
      sfToken
    }
  });

const syncInstanceMetadata = async (objectNames: string[], instanceId: string, sfToken: string) =>
  axiosInstance.post(
    `${INSTANCES_URL}/${instanceId}/object-list`,
    { objectNames },
    {
      headers: {
        sfToken
      }
    }
  );

export {
  fetchPaginatedDatasourcesData,
  fetchDatasourceData,
  addNewDatasource,
  editDatasource,
  deleteDatasources,
  fetchInstanceObjects,
  syncInstanceMetadata
};
