import React, { useEffect, useState } from 'react';
import { CommonSelect } from '../../Select';
import { CommonButton } from '../../Buttons/CommonButton';
import { FieldLabel, FieldWrapper } from '../Common';
import { NoSelection } from '../EntityPanel/Parts/NoSelection';

interface NodeObject {
  data: any;
  id?: number;
}

interface EntityPanelProps {
  node: NodeObject;
  saveNode: (id, data) => void;
}

export const ObjectPanel: React.FC<EntityPanelProps> = ({ node, saveNode }) => {
  if (!node?.data) {
    return <NoSelection />;
  }

  const [nodeData, setNodeData] = useState(node.data);

  useEffect(() => {
    setNodeData(node.data);
  }, [node]);

  const save = () => {
    const newNode = nodeData;
    newNode.title = `${nodeData.intent.label} ${nodeData.object_id.label}`;
    newNode.subtitle = 'Start of the Conversation';
    saveNode(node.id, newNode);
  };

  const handleInputChange = (id, value) => {
    setNodeData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  return (
    <ul>
      <li key='test1'>
        <>
          <FieldWrapper>
            <FieldLabel label='Object' info='Select the object' />
            <CommonSelect
              placeholder='Select a slot type'
              value={node.data.object_id}
              onChange={value => handleInputChange('object_id', value)}
              options={[
                { label: 'Account', value: 'Account' },
                { label: 'Contact', value: 'Contact' },
                { label: 'Lead', value: 'Lead' },
                { label: 'Opportunity', value: 'Opportunity' },
                { label: 'Case', value: 'Case' },
                { label: 'Task', value: 'Task' },
                { label: 'Event', value: 'Event' },
                { label: 'Call', value: 'Call' },
                { label: 'Contract', value: 'Contract' },
                { label: 'Quote', value: 'Quote' }
              ]}
              type='common'
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel label='Intent' info='Select the conversation intent' />
            <CommonSelect
              placeholder='Select an intent'
              onChange={value => handleInputChange('intent', value)}
              value={node.data.intent}
              options={[
                { label: 'Create', value: 'whatid' },
                { label: 'Update', value: 'closedate' },
                { label: 'Delete', value: 'stage' },
                { label: 'View', value: 'text' }
              ]}
              type='common'
            />
          </FieldWrapper>
        </>
        <div style={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CommonButton style={{ height: 50, width: 150 }} onClick={save} label='Save' />
        </div>
      </li>
    </ul>
  );
};
