import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import { Title2 } from '../../theme/typography/Text';
import { IconClose } from '../Icon/IconClose';

interface StyledModalProps extends ReactModal.Props {
  className?: string;
  customClassName?: string;
  isOpen?: boolean;
  isSmall?: boolean;
  onRequestClose?: () => void;
  children?: React.ReactNode;
}

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onCloseClick: () => void;
  small?: boolean;
  title?: string;
  style?: ReactModal.Styles;
}

//TO DO: Check this
const StyledModalWrapper: React.FC<StyledModalProps> = ({ className, customClassName, ...props }) => {
  const classNameBase = customClassName && className ? className.replace(customClassName, '').trim() : className;

  const classNames = [classNameBase, ...(customClassName ? [customClassName] : [])];

  const contentClassNames = classNames.map(cn => `${cn}__content`).join(' ');
  const overlayClassNames = classNames.map(cn => `${cn}__overlay`).join(' ');

  return (
    <ReactModal
      {...props}
      portalClassName={className}
      className={contentClassNames}
      overlayClassName={overlayClassNames}
    />
  );
};

const StyledModal = styled(StyledModalWrapper).attrs(({ className }) => ({
  customClassName: className
}))(() => {
  return css`
    &__content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow: auto;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.background};
      max-width: calc(100% - 28px);
      max-height: calc(100% - 28px);
      outline: none;
    }

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
  `;
});

const StyledHeader = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 20px 0 20px;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Title = styled(Title2)`
  margin: 0;
`;

const buttonResetStyles = css`
  padding: 0;
  border: 0;
  margin: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
`;

const CloseButton = styled.button`
  ${buttonResetStyles};
  background: transparent;
  position: absolute;
  right: 15px;
  top: 10px;
  line-height: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  will-change: opacity;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const StyledCloseIcon = styled(IconClose)`
  color: ${({ theme }) => theme.texts.primary};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px 20px 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const Modal: React.FC<ModalProps> = ({ children, isOpen, onCloseClick, small, title, ...props }) => (
  <Fragment>
    <StyledModal {...props} isOpen={isOpen} onRequestClose={onCloseClick} isSmall={small}>
      <StyledHeader>
        <Title>{title}</Title>
        <CloseButton onClick={onCloseClick} title='Close'>
          <StyledCloseIcon />
        </CloseButton>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledModal>
  </Fragment>
);

export const modalSetAppElement = ReactModal.setAppElement;
