import styled from 'styled-components';
import { useRef, useState } from 'react';
import { colors } from '../../theme';

const EditableContainer = styled.div`
  display: flex;
  margin: 10px 0 10px 0;
  flex: 1;
  position: relative;
  width: auto;
  min-height: 60px;
`;

const EditableButton = styled.button`
  background-color: ${colors.offWhite3};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: start;
  padding: 12px 0 8px 3px;
  width: auto;
  justify-content: flex-start;
  min-height: 50px;
  font-family: 'Open Sans', serif;
  font-size: 32px;
  min-width: 100px;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
  color: ${colors.primary};
`;

const EditableInput = styled.input`
  background-color: ${colors.offWhite3};
  border: none;
  border-radius: 4px;
  padding: 12px 0 8px 3px;
  border: 1px dotted ${colors.disabledGrey};
  outline: none;
  width: auto;
  min-width: 100px;
  min-height: 50px;
  line-height: 32px;
  font-family: 'Open Sans', serif;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: ${colors.disabledGrey};
`;

export const EditableButtonComponent = ({ buttonText, setButtonText }) => {
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);

  const handleButtonClick = () => {
    setEditMode(true);
  };

  const handleBlur = () => {
    setEditMode(false);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      setEditMode(false);
    }
  };

  const handleInputChange = e => {
    setButtonText(e.target.value);
  };

  return (
    <EditableContainer>
      {!editMode ? (
        <EditableButton onClick={handleButtonClick}>{buttonText}</EditableButton>
      ) : (
        <EditableInput
          ref={inputRef}
          value={buttonText}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          autoFocus
        />
      )}
    </EditableContainer>
  );
};
