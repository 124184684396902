import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface GlobalFilterState {
  organizations: OrganizationType[];
  datasources: OrganizationInstanceType[];
  deployments: DeploymentType[];
  activeOrganization: { label: string; value: string };
  activeDatasource: { label: string; value: string };
  activeDeployment: { label: string; value: string };
}

const initialState: GlobalFilterState = {
  organizations: [],
  datasources: [],
  deployments: [],
  activeOrganization: { label: 'All Organizations', value: 'all' },
  activeDatasource: null,
  activeDeployment: null
};

// Slice
export const globalFilter = createSlice({
  name: 'globalFilter',
  initialState,
  reducers: {
    setOrganizations: (state: GlobalFilterState, action) => {
      state.organizations = action.payload;
    },
    setDatasources: (state: GlobalFilterState, action) => {
      state.datasources = action.payload;
    },
    setDeployments: (state: GlobalFilterState, action) => {
      state.deployments = action.payload;
    },
    setActiveOrganization: (state: GlobalFilterState, action) => {
      state.activeOrganization = action.payload;
      state.activeDatasource = { label: 'All Datasources', value: 'none' };
    },
    setActiveDatasource: (state: GlobalFilterState, action) => {
      state.activeDatasource = action.payload;
    },
    setActiveDeployment: (state: GlobalFilterState, action) => {
      state.activeDeployment = action.payload;
    },
    resetFilter: () => initialState
  }
});

// Actions
export const {
  setOrganizations,
  setDeployments,
  setDatasources,
  setActiveOrganization,
  setActiveDatasource,
  setActiveDeployment
} = globalFilter.actions;

// Selectors
export const getOrganizations = (state: RootState) => state.globalFilter.organizations;

export const getDatasources = (state: RootState) => state.globalFilter.datasources;

export const getDeployments = (state: RootState) => state.globalFilter.deployments;

export const getActiveDatasource = (state: RootState) => state.globalFilter.activeDatasource;

export const getActiveOrganization = (state: RootState) => state.globalFilter.activeOrganization;

export const getActiveDeployment = (state: RootState) => state.globalFilter.activeDeployment;

export default globalFilter.reducer;
