import { FilterType, PaginationParams } from '../utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../store/reducers/ui';
import {
  addNewDeployment,
  deleteDeployments,
  deleteDeploymentUsers,
  editDeployment,
  fetchDeploymentDetail,
  fetchDeploymentsByInstanceId,
  fetchPaginatedDeploymentsData
} from './queries';

const useDeploymentsListData = (pagination: PaginationParams, search?: SearchParams, filter?: FilterType) => {
  const { sort, offset, limit } = pagination;
  return useQuery({
    queryKey: ['deployments', 'list', filter, pagination, { search: search?.text }],
    queryFn: () => fetchPaginatedDeploymentsData({ sort, offset, limit, search, filter }),
    refetchOnWindowFocus: false
  });
};

const useAddNewDeployment = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: DeploymentType) => addNewDeployment(body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: (response: { data: { data: DeploymentType } }) => {
      queryClient.invalidateQueries(['deployments', 'list']);
      onSuccess();
      toast(`${response.data.data.name} Deployment Created`, { type: 'success' });
    }
  });
};

const useDeploymentDetail = (id: string) =>
  useQuery({
    queryKey: ['deployments', id],
    queryFn: () => fetchDeploymentDetail(id),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

const useDeploymentsByDatasource = (id: string) =>
  useQuery({
    queryKey: ['deployments', 'datasource', id],
    queryFn: () => fetchDeploymentsByInstanceId(id),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

const useDeploymentEdit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: { id: string; body: DeploymentType }) => editDeployment(variables.id, variables.body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: ({ data }: { data: { data: DeploymentType } }) => {
      toast(`${data.data.name} updated`, { type: 'success' });
      queryClient.invalidateQueries(['deployments', 'list']);
      queryClient.invalidateQueries(['deployments', data.data.id.toString()]);
    }
  });
};

const useDeploymentsDelete = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => deleteDeployments(ids),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Deployment(s) deleted', { type: 'success' });
      queryClient.invalidateQueries(['deployments', 'list']);
      dispatch(clearAlert());
    }
  });
};

const useDeleteDeploymentUsers = (deploymentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string[]) => deleteDeploymentUsers(deploymentId, id),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Users removed from deployment', { type: 'success' });
      queryClient.invalidateQueries(['users', 'list', { deployment_id: deploymentId }]);
    }
  });
};

export {
  useAddNewDeployment,
  useDeploymentDetail,
  useDeploymentEdit,
  useDeploymentsDelete,
  useDeploymentsListData,
  useDeleteDeploymentUsers,
  useDeploymentsByDatasource
};
