import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePermission } from 'src/hooks';
import { getRouteById, getSidebarItems, ROUTES } from 'src/routes';
import { AppSidebar as Sidebar } from '../components/Sidebar';

const routes = [
  ROUTES.home,
  ROUTES.organizations,
  ROUTES.deployments,
  ROUTES.datasources,
  ROUTES.users,
  ROUTES.administrators
];

export const AppSidebar: React.FC = () => {
  const { checkRouteAccess } = usePermission();
  const location = useLocation();

  const linkItems = useMemo(
    () =>
      getSidebarItems(location.pathname, routes)
        .filter(checkRouteAccess)
        .map(({ id, label, match, icon }) => ({
          id,
          label,
          active: match,
          icon,
          path: getRouteById(id).path
        })),
    [location.pathname, checkRouteAccess]
  );

  return <Sidebar links={linkItems} />;
};
