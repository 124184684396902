import React from 'react';
import { useTheme } from 'styled-components';

const IconRollio = ({ size, color }: { size: number; color?: string }) => {
  const theme = useTheme();

  return (
    <svg width={`${size}px`} height={`${size}px`} fill={color ?? theme.colors.primary} viewBox='0 0 18 18'>
      <g id='surface3'>
        {/* eslint-disable-next-line max-len */}
        <path d='M 1.480469 0.171875 C 0.941406 0.414062 0.492188 0.847656 0.230469 1.371094 L 0 1.835938 L 0 16.164062 L 0.230469 16.628906 C 0.859375 17.890625 1.210938 17.996094 4.730469 17.996094 C 8.152344 18 7.828125 18.144531 7.828125 16.609375 L 7.828125 15.4375 L 10.398438 18 L 13.277344 17.996094 C 16.789062 17.996094 17.140625 17.886719 17.769531 16.628906 L 18 16.164062 L 18 10.261719 C 18 5.324219 17.976562 4.273438 17.867188 3.84375 C 17.417969 2.117188 15.882812 0.582031 14.15625 0.132812 C 13.289062 -0.09375 1.984375 -0.0585938 1.480469 0.171875 M 13.964844 1.257812 C 15.296875 1.667969 16.339844 2.714844 16.746094 4.050781 C 16.917969 4.609375 16.921875 4.871094 16.898438 10.320312 C 16.867188 17.59375 17.25 16.863281 13.476562 16.902344 L 10.945312 16.929688 L 9.261719 15.246094 C 6.902344 12.890625 6.75 12.898438 6.75 15.410156 L 6.75 16.929688 L 4.371094 16.902344 C 0.777344 16.863281 1.132812 17.585938 1.101562 10.25 L 1.074219 4.496094 L 6.277344 4.519531 L 11.476562 4.546875 L 11.898438 4.753906 C 14.082031 5.828125 14.023438 8.847656 11.792969 9.890625 L 11.296875 10.125 L 4.5 10.179688 L 4.5 7.828125 L 7.710938 7.828125 C 11.222656 7.828125 11.25 7.824219 11.25 7.304688 C 11.25 6.742188 11.320312 6.75 7.320312 6.75 C 3.808594 6.75 3.683594 6.757812 3.511719 6.929688 C 3.230469 7.210938 3.230469 14.210938 3.511719 14.488281 C 4.167969 15.148438 4.5 14.558594 4.5 12.722656 L 4.5 11.25 L 10.488281 11.25 L 12.195312 12.960938 C 13.988281 14.753906 14.128906 14.851562 14.488281 14.488281 C 14.839844 14.140625 14.742188 13.976562 13.273438 12.507812 L 11.878906 11.109375 L 12.433594 10.84375 C 15.324219 9.472656 15.359375 5.191406 12.488281 3.789062 C 11.59375 3.351562 11.316406 3.328125 6.019531 3.328125 L 1.078125 3.328125 L 1.078125 2.796875 C 1.078125 1.964844 1.347656 1.46875 1.933594 1.207031 C 2.433594 0.984375 13.226562 1.03125 13.964844 1.257812 ' />
      </g>
    </svg>
  );
};

export default IconRollio;
