import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContextMenu = styled.div`
  position: fixed;
  z-index: 9999;
  background-color: #ededed;
  font-family: 'Open Sans', serif;
  font-weight: 200;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 150px;

  & > div {
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;
    position: relative;
  }

  & > div:hover {
    background-color: #f5f5f5;
  }
`;

const Option = styled.div`
  height: 40px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const SubMenuIndicator = styled.span`
  position: absolute;
  right: 5px;
`;

const ContextMenu = ({ position, onClose, items, id = null }) => {
  const [subMenu, setSubMenu] = useState(null);
  const [subMenuPosition, setSubMenuPosition] = useState({ x: 0, y: 0 });

  const handleSubMenu = (event, item) => {
    event.stopPropagation();
    setSubMenuPosition({
      x: position.x + 3 + event.currentTarget.offsetWidth,
      y: position.y + event.currentTarget.offsetTop
    });
    setSubMenu(item.submenu);
  };

  return (
    <>
      <StyledContextMenu style={{ top: position.y, left: position.x }}>
        {items.map((item, index) => (
          <Option
            key={index}
            onClick={event => {
              if (item.submenu) {
                handleSubMenu(event, item);
              } else {
                item.action(id);
                onClose();
              }
            }}>
            {item.icon && <item.icon />}
            <LabelWrapper style={{ marginLeft: item.icon ? 10 : 0 }}>
              {item.label}
              {item.submenu && <SubMenuIndicator>&gt;</SubMenuIndicator>}
            </LabelWrapper>
          </Option>
        ))}
      </StyledContextMenu>
      {subMenu && <ContextMenu position={subMenuPosition} onClose={onClose} items={subMenu} id={id} />}
    </>
  );
};

export default ContextMenu;
