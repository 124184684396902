import React from 'react';
import styled from 'styled-components';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled(ReactDatePicker)`
  width: 300px;
  font-family: Lato, serif;
  font-weight: 500;
  font-size: 1rem;
  height: 45px;
  color: ${({ theme, disabled }) => (disabled ? theme.texts.disabled : theme.texts.secondary)};
  border: none;
  background-color: ${({ theme }) => theme.layers.first};
  border-radius: 4px;
  padding: 5px 15px 5px 15px;
`;

const DatepickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BasicDatePicker: React.FC<ReactDatePickerProps> = props => (
  <DatepickerWrapper>
    <StyledDatePicker {...props} />
  </DatepickerWrapper>
);
