import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { useMemo } from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const BarChart = () => {
  const theme = useTheme();

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: [24, 86, 13, 57, 91, 39, 76, 42, 5, 62, 80, 98],
          backgroundColor: theme.colors.primary
        }
      ]
    }),
    [theme]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
          labels: {
            font: {
              family: 'Open Sans',
              size: 14,
              color: theme.texts.primary
            }
          }
        },
        title: {
          display: false
        }
      },
      scales: {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false
          },
          ticks: {
            display: false
          },
          beginAtZero: false
        },
        y: {
          border: {
            display: false
          },
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false
          },
          beginAtZero: true
        }
      }
    }),
    [theme]
  );

  return <Bar options={options} data={data} />;
};
