import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import EmailArea from '../../../layouts/Users/Parts/EmailArea';
import { Modal } from '../Modal';
import { useAddUsers } from '../../../hooks/api/Users/hooks';

const StyledDiv = styled.div`
  flex: 1;
  border-radius: 20px;
  width: 900px;
  align-items: center;
  justify-content: center;
`;

export const ManageSfUsersModal = ({ title, visible, setVisible, deploymentId }) => {
  const clientQuery = useQueryClient();
  const [items, setItems] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [resultEmails, setResultEmails] = useState({ added: [], repeated: [] });

  const reset = () => {
    setItems([]);
    setValue('');
    setError(null);
  };

  const onSuccessAddUsers = useCallback(
    data => {
      if (!data.repeated.length) {
        setVisible(false);
        toast('Users successfully added to the deployment', { type: 'success' });
        reset();
        setResultEmails({ added: [], repeated: [] });
      } else {
        const { repeated } = data;
        setResultEmails({ repeated, added: items.filter(element => !repeated.includes(element)) });
        reset();
        toast('Some users were repeated', { type: 'warning' });
      }
      clientQuery.invalidateQueries(['sfUsers', 'list', deploymentId]);
    },
    [setVisible, items, reset]
  );

  const onClose = () => {
    setVisible(false);
    reset();
    setResultEmails({ added: [], repeated: [] });
  };

  const onError = useCallback(() => {
    onClose();
    toast('Error adding users to the deployment', { type: 'error' });
  }, [setVisible, reset]);

  const { mutate: addUsersToDeployment } = useAddUsers(onSuccessAddUsers, onError);

  return (
    <Modal isOpen={visible} onCloseClick={onClose} title={title}>
      <StyledDiv>
        <EmailArea
          items={items}
          value={value}
          error={error}
          setError={setError}
          setValue={setValue}
          setItems={setItems}
          handleSubmit={addUsersToDeployment}
          resultEmails={resultEmails}
        />
      </StyledDiv>
    </Modal>
  );
};
