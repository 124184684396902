import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colors } from '../../../../theme';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        // This more specific font property overrides the global property
        font: {
          family: 'Open Sans',
          size: 14
        }
      }
    },
    title: {
      display: false
    }
  },
  scales: {
    x: {
      border: {
        display: false
      },
      grid: {
        display: false
      },
      ticks: {
        display: false
      },
      beginAtZero: false
    },
    y: {
      border: {
        display: false
      },
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      },
      beginAtZero: true
    }
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 20, 15, 12, 15, 15],
      borderColor: colors.lightRed,
      backgroundColor: 'rgba(255, 99, 132, 0.5)'
    }
  ]
};

export const LineChart = () => {
  return <Line options={options} data={data} />;
};
