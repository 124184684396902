import { DEPLOYMENTS_URL } from '../../../constants';
import axiosInstance from '../axiosInstances';

interface PaginatedConversationParams {
  offset: number;
  limit: number;
  sfUserId: string;
  deploymentId: string;
}

export interface RawConversationData {
  created_at: string;
  deploymentId: number;
  id: number;
  intent: string;
  messagesCount: number;
  object: string;
  status: string;
  title: string;
  updated_at: string;
  user_id: number;
}

const fetchPaginatedConversationMessages = async ({
  offset,
  limit,
  deploymentId,
  sfUserId
}: PaginatedConversationParams) =>
  (
    await axiosInstance.get<{
      data: RawConversationData[];
      total: number;
    }>(`${DEPLOYMENTS_URL}/${deploymentId}/conversations/${sfUserId}?per_page=${limit}&offset=${offset}`)
  ).data;

// export const useConversationsData = (deploymentId: string, sfUserId: string, pagination: PaginationType) => {
//   const { limit, offset } = pagination;
//
//   const queryKey = useMemo(
//     () => queries.deployments.detail(deploymentId)._ctx.conversations(sfUserId, pagination),
//     [deploymentId, sfUserId, pagination]
//   );
//
//   const url = useMemo(
//     () => `${DEPLOYMENTS_URL}/${deploymentId}/conversations/${sfUserId}?per_page=${limit}&offset=${offset}`,
//     [deploymentId, sfUserId, limit, offset]
//   );
//
//   const { data: { data: conversations = [], total = 0 } = {}, ...data } = useData<
//     {
//       data: ConversationType[];
//       total: number;
//     },
//     ConversationType
//   >(queryKey, url);
//
//   return {
//     ...data,
//     totalItems: total,
//     conversations
//   };
// };

// export const useMessagesData = (deploymentId: string, conversationId: string, sfUserId: string) => {
//   const url = useMemo(
//     () => `${DEPLOYMENTS_URL}/${deploymentId}/messages/${conversationId}?user_id=${sfUserId}`,
//     [conversationId, deploymentId, sfUserId]
//   );
//
//   const { data: { data: messages = [] } = {}, ...data } = useData<
//     {
//       data: ConversationMessageType[];
//       total: number;
//     },
//     ConversationMessageType
//   >(queryKey, url);
//
//   return {
//     ...data,
//     messages
//   };
// };

export { fetchPaginatedConversationMessages };
