import { Modal } from 'src/components/Modals/Modal';
import { RecordTable, Row } from '../../../components/Table';
import { usePaginationAndSorting } from '../../../components/Table/hooks';
import { useNewConversationsData } from '../../../hooks/api/Conversations/hooks';
import { useEffect, useState } from 'react';
import { RawConversationData } from '../../../hooks/api/Conversations/queries';
import { getFormattedDate } from '../../../utils';
import { conversationStatusFormatter } from '../../../components/Table/utils';

interface ConversationRow extends Row {
  title: string;
  status: 'cancelled' | 'open' | 'successful' | 'error';
  messagesCount: number;
  created_at: string;
}

const COLUMNS = [
  { key: 'id', label: 'Id' },
  { key: 'title', label: 'Title' },
  { key: 'status', label: 'Status', formatter: conversationStatusFormatter },
  { key: 'messagesCount', label: 'Messages' },
  { key: 'created_at', label: 'Created At' }
];

const parseConversationsToTableData = (data: RawConversationData[]) =>
  data?.map(({ id, title, status, messagesCount, created_at }) => ({
    id: id.toString(),
    title,
    status,
    messagesCount,
    created_at: getFormattedDate(created_at)
  })) as ConversationRow[];

export const UserConversationsModal = ({ visible, setVisible, deploymentId, sfUser }) => {
  const paginationAndSorting = usePaginationAndSorting([5, 10, 20]);
  const { data: response, isLoading } = useNewConversationsData(
    deploymentId,
    sfUser?.id,
    paginationAndSorting.pagination
  );
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (response?.data?.length) {
      setTableRows(parseConversationsToTableData(response.data));
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  return (
    <Modal
      isOpen={visible}
      title={`Conversations for ${sfUser?.name}`}
      onCloseClick={() => {
        paginationAndSorting.reset();
        setVisible(false);
      }}>
      <div style={{ minWidth: 700, padding: 20 }}>
        <RecordTable
          data={tableRows}
          pagination={paginationAndSorting}
          columns={COLUMNS}
          selectable={false}
          total={response?.total}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};
