import React, { HTMLInputTypeAttribute } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormSelect, { Option } from './parts/FormSelect';
import { Body, Title1 } from '../../theme/typography/Text';
import FormInput from './parts/FormInput';
import FormToggle from './parts/FormToggle';
import { IconInfo } from '../Icon/IconInfo';
import { ToolTip } from '../ToolTip';
import FormDatePicker from './parts/FormDatePicker';
import { CommonButton } from '../Buttons/CommonButton';
import { SimpleButton } from '../Dashboard/SimpleButton';

type FieldType = 'text' | 'email' | 'password' | 'number' | 'checkbox' | 'select' | 'date';

export interface FieldStructure {
  key: string;
  label: string;
  type: FieldType;
  placeholder?: string;
  info?: string;
  format?: string;
  inputType?: HTMLInputTypeAttribute;
  isVisible?: boolean;
  isLoading?: boolean;
  onlyDisplay?: boolean;
  options?: Option[];
  onChange?: (option: Option) => void;
}

interface FormProps<T> {
  title?: string;
  columns?: number;
  name: string;
  isLoading?: boolean;
  initialValues: T;
  modal?: boolean;
  fields: FieldStructure[];
  schema: Yup.ObjectSchema<any>;
  onCancel?: () => void;
  onSubmit: (values: T) => void;
}

const Title = styled(Title1)`
  margin: 0;
`;

const StyledForm = styled(Form)<{ columns: number }>`
  display: grid;
  -ms-grid-column-align: center;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 20px;
  align-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 2fr);
    align-items: stretch;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 2fr);
    align-items: stretch;
  }
`;

const FormContainer = styled.div<{ columns: number }>`
  display: flex;
  justify-content: center;
  align-items: ${({ columns }) => (columns > 1 ? 'flex-start' : 'center')};
  gap: 24px;
  flex-direction: column;
`;

const Label = styled(Body)<{ htmlFor: string }>`
  display: block;
  margin: 0;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const LabelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const ToggleContainer = styled.div`
  display: flex;
  padding-top: 12px;
  align-items: center;
  height: 40px;
  justify-content: flex-start;
`;

const Error = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  max-width: 250px;
  margin-top: 10px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
`;

const SkeletonInput = styled.div`
  width: 250px;
  height: 40px;
  background-color: ${({ theme }) => theme.layers.first};
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      background-color: ${({ theme }) => theme.layers.first};
    }
    50% {
      background-color: ${({ theme }) => theme.layers.second};
    }
    100% {
      background-color: ${({ theme }) => theme.layers.first};
    }
  }
`;

const FormField = ({ field, isLoading }: { field: FieldStructure; isLoading?: boolean }) => (
  <FieldWrapper key={field.key}>
    <LabelHeader>
      <Label htmlFor={field.key}>{field.label}</Label>
      <InfoContainer>
        {field.info ? (
          <ToolTip message={field.info}>
            <IconInfo size='small' />
          </ToolTip>
        ) : null}
      </InfoContainer>
    </LabelHeader>
    {isLoading ? (
      <SkeletonInput />
    ) : field.type === 'checkbox' ? (
      <ToggleContainer>
        <FormToggle name={field.key} disabled={field.onlyDisplay} />
      </ToggleContainer>
    ) : field.type === 'select' ? (
      <FormSelect
        name={field.key}
        options={field.options ?? []}
        disabled={field.onlyDisplay}
        onChange={field.onChange}
        isLoading={field.isLoading}
        placeholder={field.placeholder}
      />
    ) : field.type === 'date' ? (
      <FormDatePicker
        name={field.key}
        disabled={field.onlyDisplay}
        placeholder={field.placeholder}
        dateFormat={field.format}
      />
    ) : (
      <FormInput
        name={field.key}
        disabled={field.onlyDisplay}
        placeholder={field.placeholder}
        type={field.inputType ?? 'text'}
      />
    )}
    <Error name={field.key} component='div' />
  </FieldWrapper>
);

const GenericForm = <T, >({
  title,
  name,
  initialValues,
  fields,
  schema,
  onSubmit,
  onCancel,
  isLoading = false,
  modal = false,
  columns = 4
}: FormProps<T>): JSX.Element => {
  const formName = `${name}-form`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}>
      {({ isSubmitting, dirty }) => (
        <FormContainer columns={columns}>
          <Header>{title ? <Title>{title}</Title> : null}</Header>
          <StyledForm id={formName} columns={columns}>
            {fields.map(field => (
              <FormField key={field.key} field={field} isLoading={isLoading} />
            ))}
          </StyledForm>
          <ButtonsContainer>
            {dirty || modal ? (
              <CommonButton label='Confirm' form={formName} type='submit' disabled={isSubmitting} />
            ) : null}
            {onCancel ? <SimpleButton outline label='Cancel' disabled={isSubmitting} onClick={onCancel} /> : null}
          </ButtonsContainer>
        </FormContainer>
      )}
    </Formik>
  );
};

export default GenericForm;
