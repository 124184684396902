import styled, { useTheme } from 'styled-components';
import { IconCheck } from '../Icon/IconCheck';
import { IconClose } from '../Icon/IconClose';
import { DisplayChip } from '../Chip';
import React from 'react';
import { Row } from './index';

const InvisibleButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.texts.link};
  font-family: Lato, serif;
  font-size: 16px;
  cursor: pointer;
`;

export const booleanCellFormatter = (value: boolean) => {
  const theme = useTheme();

  return value === true ? <IconCheck color={theme.support.success} /> : <IconClose color={theme.support.error} />;
};

export const conversationStatusFormatter = (status: string) => {
  const theme = useTheme();
  const label = status.charAt(0).toUpperCase() + status.slice(1);
  const color =
    status === 'successful'
      ? theme.support.success
      : status === 'error'
      ? theme.support.error
      : status === 'open'
      ? theme.support.info
      : theme.colors.secondary;

  return <DisplayChip item={label} color={color} />;
};

export const linkCellFormatter = (cell: { name: string; onClick: (row: Row) => void }, row?: Row) => (
  <InvisibleButton onClick={() => cell.onClick(row)}>{cell.name}</InvisibleButton>
);
