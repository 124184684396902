import React, { useContext, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { CommonButton } from '../../../Buttons/CommonButton';
import { ConditionComponent } from './Condition';
import {
  ActionsContainer,
  ConditionsContainer,
  DeleteRuleButton,
  RuleContainer,
  RuleContent,
  RuleHeader
} from './Common';
import { LogicAction } from './Action';
import { ConversationEditorContext } from '../../../FlowChart/Context';
import { IconClose } from '../../../Icon/IconClose';

const initialCondition = {
  id: nanoid(6),
  type: '',
  field: '',
  operator: '',
  value: '',
  children: []
};

const initialAction = {
  id: nanoid(6),
  type: '',
  target: '',
  value: ''
};

export const Rule = ({ rule, onUpdate, showActions, onDeleteRule }) => {
  const [conditions, setConditions] = useState(rule.conditions);
  const [actions, setActions] = useState(rule.actions);
  const { nodes } = useContext(ConversationEditorContext);
  const possibleTargets = useMemo(() => {
    const targets = nodes.filter(node => node.data.type === 'entity');
    console.log(targets);
    return targets.map(target => <option value={target.id}>{target.data.title}</option>);
  }, [nodes]);

  // Conditions Management
  const addNestedCondition = parentId => {
    const addNested = conditions => {
      return conditions.map(condition => {
        if (condition.id === parentId) {
          return {
            ...condition,
            children: [...condition.children, { ...initialCondition, id: nanoid(6) }]
          };
        } else if (condition.children.length > 0) {
          return { ...condition, children: addNested(condition.children) };
        }
        return condition;
      });
    };

    setConditions(addNested(conditions));
  };
  const addCondition = () => {
    setConditions([
      ...conditions,
      {
        ...initialCondition,
        id: Date.now()
      }
    ]);
  };
  const deleteCondition = id => {
    setConditions(conditions.filter(condition => condition.id !== id));
  };
  const updateCondition = (id, updatedCondition) => {
    const newConditions = conditions.map(condition => (condition.id === id ? updatedCondition : condition));
    setConditions(newConditions);
    onUpdate(rule.id, { ...rule, conditions: newConditions });
  };

  // Actions Management
  const addAction = () => {
    setActions([
      ...actions,
      {
        ...initialAction,
        id: Date.now()
      }
    ]);
  };
  const deleteAction = id => {
    setActions(actions.filter(action => action.id !== id));
  };
  const updateAction = (id, updatedAction) => {
    const newActions = actions.map(action => (action.id === id ? updatedAction : action));
    setActions(newActions);
    onUpdate(rule.id, { ...rule, actions: newActions });
  };

  // Renders
  const renderConditions = (conditions, level = 0) => {
    return conditions.map((condition, index) => {
      return (
        <ConditionComponent
          key={`logic_condition_${index}`}
          deleteCondition={deleteCondition}
          updateCondition={updateCondition}
          level={level}
          possibleTargets={possibleTargets}
          condition={condition}
        />
      );
    });
  };
  const renderActions = actions => {
    return actions.map((action, index) => (
      <LogicAction
        key={`logic_action_${index}`}
        action={action}
        updateAction={updateAction}
        deleteAction={deleteAction}
        possibleTargets={possibleTargets}
      />
    ));
  };

  return (
    <RuleContent>
      <RuleContainer>
        <RuleHeader>Rule</RuleHeader>
        <ConditionsContainer>
          {renderConditions(conditions)}
          <CommonButton onClick={addCondition} color='purple' label='Add Condition' />
        </ConditionsContainer>
        {showActions && (
          <ActionsContainer>
            {renderActions(actions)}
            <CommonButton onClick={addAction} color='purple' style={{ marginTop: 10 }} label='Add Action' />
          </ActionsContainer>
        )}
        <DeleteRuleButton onClick={() => onDeleteRule(rule.id)}>
          <IconClose />
        </DeleteRuleButton>
      </RuleContainer>
    </RuleContent>
  );
};
