export interface FilterType {
  [key: string]: any;
}

export type SortParams = {
  column?: string;
  direction?: string;
};

export interface PaginationParams {
  offset: number;
  limit: number;
  sort?: SortParams;
  search?: SearchParams;
  filter?: FilterType;
}

export const parseSearchParams = (url: string, search: SearchParams[] | SearchParams) => {
  let input = url;
  if (search) {
    const queryParams = Object.keys(search)
      .map(k => {
        if (Array.isArray(search[k])) {
          return search[k].map(value => `${k}=${value}`).join('&');
        }
        return `${k}=${search[k]}`;
      })
      .join('&');

    input = `${url}/search?` + queryParams;
  }
  return input;
};

export const parsePaginatedUrl = (url: string, params: PaginationParams) => {
  const { offset, limit, sort, search, filter } = params;
  const input = url;
  const searchParams = new URLSearchParams();

  if (offset) {
    searchParams.append('offset', offset.toString());
  }

  if (limit) {
    searchParams.append('limit', limit.toString());
  }

  if (sort && sort.direction !== 'OFF') {
    if (sort.column) {
      searchParams.append('orderBy', sort.column);
    }
    if (sort.direction) {
      searchParams.append('order', sort.direction);
    }
  }

  if (!search) {
    searchParams.append('text', '');
  } else {
    searchParams.append('text', search.text);
  }

  if (filter) {
    searchParams.append('filter', JSON.stringify(filter));
  }

  return `${input}?${searchParams.toString()}`;
};
