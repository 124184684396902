import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { useAuth } from './useAuth';

export const useNavGuard = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(getRouteUrlById(ROUTES.login), { replace: true });
    }
  }, [isLoggedIn, navigate]);
};
