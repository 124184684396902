import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Lato, serif;
  font-size: 32px;
  color: ${({ theme }) => theme.texts.primary};
  font-weight: lighter;
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;
