import styled from 'styled-components';
import { CommonSelect } from '../../Select';
import { BarChart } from './Parts/BarChart';
import { LineChart } from './Parts/LineChart';
import { Title2 } from '../../../theme/typography/Text';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const ChartsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  height: 250px;
`;

const BarChartWrapper = styled.div`
  max-width: 80%;
  flex: 1;
`;

const SmallChartWrapper = styled.div`
  display: flex;
  flex: 0.2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-height: 100%;
`;

const LineChartWrapper = styled.div`
  max-width: 200px;
`;

const TitleText = styled.h1`
  font-family: 'Open Sans', serif;
  font-weight: 700;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.texts.secondary};
  margin: 0;
  padding: 0;
`;

const SubtitleText = styled.h1`
  font-family: 'Open Sans', serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: ${({ theme }) => theme.texts.primary};
  margin: 0;
  padding: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const ConversationLogs = () => {
  return (
    <div>
      <div style={{ flexDirection: 'column' }}>
        <Wrapper>
          <Title2>Conversation logs Activity</Title2>
          <CommonSelect
            options={[{ label: 'Successful Conversations', value: '1' }]}
            value={{ label: 'Successful Conversations', value: '1' }}
            minimumOptions={1}
            type='common'
          />
        </Wrapper>
        <ChartsWrapper>
          <BarChartWrapper>
            <BarChart />
          </BarChartWrapper>
          <SmallChartWrapper>
            <LineChartWrapper>
              <InfoWrapper>
                <TitleText>Highest Month</TitleText>
                <SubtitleText>November 654 Logs</SubtitleText>
              </InfoWrapper>
              <LineChart />
            </LineChartWrapper>
            <LineChartWrapper>
              <InfoWrapper>
                <TitleText>Lowest Month</TitleText>
                <SubtitleText>March 345 Logs</SubtitleText>
              </InfoWrapper>
              <LineChart />
            </LineChartWrapper>
          </SmallChartWrapper>
        </ChartsWrapper>
      </div>
    </div>
  );
};
