import { alpha } from './utils';

// Ref.: https://carbondesignsystem.com/guidelines/a
interface RollioTheme {
  colors: Colors;
  layers: Layers;
  fields: Fields;
  icons: Icons;
  buttons: Buttons;
  texts: Texts;
  support: Support;
  borders: Borders;
  miscellaneous: Miscellaneous;
  isDark: boolean;
}

type Colors = {
  primary: string;
  primaryVariant: string;
  secondary: string;
  secondaryVariant: string;
  background: string;
  error: string;
  onPrimary: string;
  onSecondary: string;
  onBackground: string;
  onError: string;
};

type Layers = Record<Elevation, string>;

type Fields = Record<Elevation, string>;

type Elevation = 'first' | 'second' | 'third';

type Texts = {
  primary: string;
  secondary: string;
  placeholder: string;
  disabled: string;
  onColor: string;
  onColorDisabled: string;
  error: string;
  link: string;
};

type Icons = {
  primary: string;
  secondary: string;
  onColor: string;
  onColorDisabled: string;
  interactive: string;
  disabled: string;
};

type Buttons = {
  primary: string;
  secondary: string;
  tertiary: string;
  disabled: string;
};

type Support = {
  error: string;
  success: string;
  warning: string;
  info: string;
};

type Borders = Record<Elevation, string> & { shadow: string; onBackground: string; interactive: string };

type Miscellaneous = {
  interactive: string;
  highlight: string;
  toggleOff: string;
  overlay: string;
  skeletonElement: string;
  skeletonBackground: string;
};

const RawColors = {
  midnightBlue: '#1E147D',
  luckyPoint: '#1D125D',
  lightSteelBlue: '#97A7E1',
  lynch: '#E0E4F4',
  white: '#FFFFFF',
  alabaster: '#F4F4F4',
  wildSand: '#F5F5F5',
  gallery: '#F0F0F0',
  alto: '#E0E0E0',
  silver: '#C6C6C6',
  black: '#000000',
  night: '#161616',
  codGray: '#1E1E1E',
  mineShaft: '#262626',
  scorpion: '#575757',
  mineLoft: '#393939',
  emperor: '#525252',
  gray: '#8D8D8D',
  jet: '#313131',
  engineeringOrange: '#CC0000',
  mediumPurple: '#8875B7',
  lavenderPurple: '#9583BF',
  blueBell: '#A08FC5',
  alizarinCrimson: '#DA1E28',
  silverChalice: '#A8A8A8',
  doveGray: '#6F6F6F',
  geraldine: '#FF8389',
  malibu: '#78A9FF',
  blueRibbon: '#0F62FE',
  carnation: '#FA4D56',
  chateauGreen: '#42BE65',
  butterCup: '#F1C21B',
  dodgerBlue: '#4589FF',
  eucalyptus: '#24A148',
  butterMug: '#F1C21B',
  scienceBlue: '#0043CE',
  leadBlue: '#1B96FF',
  allBlue: '#107CAD',
  contactPurple: '#9602C7',
  accountPurple: '#5867E8',
  eventPurple: '#CB65FF',
  opportunityOrange: '#FF5D2D',
  taskGreen: '#3BA755',
  noteRed: '#B60554',
  mercury: '#E5E5E5',
  shafter: '#353535',
  pomegranate: '#F63228',
  redOrange: '#FF3B30'
};

// Light theme

const lightColors: Colors = {
  primary: RawColors.midnightBlue,
  primaryVariant: RawColors.luckyPoint,
  secondary: RawColors.lightSteelBlue,
  secondaryVariant: RawColors.lynch,
  background: RawColors.white,
  error: RawColors.engineeringOrange,
  onPrimary: RawColors.white,
  onSecondary: RawColors.jet,
  onBackground: RawColors.jet,
  onError: RawColors.white
};

const lightLayers: Layers = {
  first: RawColors.alabaster,
  second: RawColors.white,
  third: RawColors.alabaster
};

const lightTexts: Texts = {
  primary: RawColors.night,
  secondary: RawColors.emperor,
  placeholder: RawColors.silverChalice,
  onColor: RawColors.white,
  onColorDisabled: RawColors.gray,
  error: RawColors.alizarinCrimson,
  link: RawColors.allBlue,
  disabled: alpha(RawColors.night, 0.25)
};

const lightIcons: Icons = {
  primary: lightTexts.primary,
  secondary: lightTexts.secondary,
  onColor: lightTexts.onColor,
  onColorDisabled: lightTexts.onColorDisabled,
  interactive: lightColors.primary,
  disabled: lightTexts.disabled
};

const lightButtons: Buttons = {
  primary: lightColors.primary,
  secondary: lightColors.secondary,
  tertiary: lightColors.secondaryVariant,
  disabled: RawColors.silver
};

const lightSupport: Support = {
  error: RawColors.alizarinCrimson,
  success: RawColors.eucalyptus,
  warning: RawColors.butterMug,
  info: RawColors.scienceBlue
};

const lightFields: Fields = {
  first: lightLayers.first,
  second: lightLayers.second,
  third: lightLayers.third
};

const lightBorders: Borders = {
  interactive: lightColors.primary,
  shadow: alpha(RawColors.black, 0.1),
  onBackground: RawColors.alto,
  first: RawColors.silver,
  second: RawColors.alto,
  third: RawColors.silver
};

const lightMiscellaneous: Miscellaneous = {
  interactive: lightColors.primary,
  highlight: lightColors.secondary,
  toggleOff: RawColors.gray,
  overlay: alpha(RawColors.night, 0.7),
  skeletonElement: RawColors.silver,
  skeletonBackground: RawColors.mercury
};

const LightTheme: RollioTheme = {
  colors: lightColors,
  layers: lightLayers,
  texts: lightTexts,
  icons: lightIcons,
  buttons: lightButtons,
  support: lightSupport,
  fields: lightFields,
  borders: lightBorders,
  miscellaneous: lightMiscellaneous,
  isDark: false
};

// Dark theme

const darkColors: Colors = {
  primary: RawColors.lightSteelBlue,
  primaryVariant: RawColors.blueBell,
  secondary: RawColors.lavenderPurple,
  secondaryVariant: RawColors.gallery,
  error: RawColors.engineeringOrange,
  onError: RawColors.white,
  onPrimary: RawColors.black,
  onSecondary: RawColors.white,
  background: RawColors.night,
  onBackground: RawColors.white
};

const darkLayers: Layers = {
  first: RawColors.mineShaft,
  second: RawColors.mineLoft,
  third: RawColors.emperor
};

const darkTexts: Texts = {
  primary: RawColors.alabaster,
  secondary: RawColors.silver,
  placeholder: RawColors.doveGray,
  disabled: alpha(RawColors.alabaster, 0.25),
  onColor: RawColors.white,
  onColorDisabled: alpha(RawColors.alabaster, 0.25),
  link: RawColors.malibu,
  error: RawColors.geraldine
};

const darkIcons: Icons = {
  primary: darkTexts.primary,
  secondary: darkTexts.secondary,
  onColor: darkTexts.onColor,
  onColorDisabled: darkTexts.onColorDisabled,
  interactive: darkColors.primary,
  disabled: RawColors.mineLoft
};

const darkButtons: Buttons = {
  primary: darkColors.primary,
  secondary: darkColors.secondary,
  tertiary: darkColors.secondaryVariant,
  disabled: RawColors.emperor
};

const darkSupport: Support = {
  error: RawColors.carnation,
  success: RawColors.chateauGreen,
  warning: RawColors.butterCup,
  info: RawColors.dodgerBlue
};

const darkFields: Fields = {
  first: darkLayers.first,
  second: darkLayers.second,
  third: darkLayers.third
};

const darkBorders: Borders = {
  interactive: darkColors.primary,
  onBackground: RawColors.mineLoft,
  shadow: RawColors.black,
  first: RawColors.mineLoft,
  second: RawColors.emperor,
  third: RawColors.doveGray
};

const darkMiscellaneous: Miscellaneous = {
  interactive: darkColors.primary,
  highlight: darkColors.secondary,
  toggleOff: RawColors.doveGray,
  overlay: alpha(RawColors.night, 0.7),
  skeletonElement: RawColors.emperor,
  skeletonBackground: RawColors.shafter
};

const DarkTheme: RollioTheme = {
  colors: darkColors,
  layers: darkLayers,
  texts: darkTexts,
  icons: darkIcons,
  buttons: darkButtons,
  support: darkSupport,
  fields: darkFields,
  borders: darkBorders,
  miscellaneous: darkMiscellaneous,
  isDark: true
};

export { type RollioTheme, RawColors, LightTheme, DarkTheme };
