import { ConversationView } from '../../layouts/Conversations/ConversationView';
import { ConversationEditorContext, useConversationContext } from '../../components/FlowChart/Context';
import { NODE_TYPES } from '../../components/FlowChart/Elements';

const position = { x: 0, y: 0 };

export const initialNodes = [
  {
    id: '1',
    type: 'special',
    data: { title: 'No Intent/Object', subtitle: 'Set an Intent/Object to start', type: NODE_TYPES.INITIAL },
    position
  }
];

export const Conversation = () => {
  const conversationContext = useConversationContext({ initialNodes });
  return (
    <ConversationEditorContext.Provider value={conversationContext}>
      <ConversationView />
    </ConversationEditorContext.Provider>
  );
};
