import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setActiveOrganization } from '../../store/reducers/globalFilter';
import { ROUTES } from '../../routes';
import { TableHeaderWrapper, Title, Wrapper } from '../../layouts/Common';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable, Row } from '../../components/Table';
import { GenerateReportModal, ReportParams } from '../../components/Modals/Custom/GenerateReportModal';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { getFormattedDate } from '../../utils';
import { setAlert } from '../../store/reducers/ui';
import { IconTrash } from '../../components/Icon/IconTrash';
import { IconEye } from '../../components/Icon/IconEye';
import { CommonButton } from '../../components/Buttons/CommonButton';
import { SimpleButton } from '../../components/Dashboard/SimpleButton';
import { IconReport } from '../../components/Icon/IconReport';
import { NewOrganizationModal } from '../../layouts/Organizations/NewOrganizationModal';
import { generateReport, useOrganizationsDelete, useOrganizationsListData } from '../../hooks/api/Organizations/hooks';
import { toast } from 'react-toastify';
import { OrganizationPaginatedData } from '../../hooks/api/Organizations/types';
import { linkCellFormatter } from '../../components/Table/utils';

const COLUMNS = [
  { key: 'name', label: 'Organization', sortable: true },
  { key: 'deployments', label: 'Deployments', formatter: linkCellFormatter },
  { key: 'users_count', label: 'Users', formatter: linkCellFormatter },
  { key: 'created_at', label: 'Creation Date', sortable: true }
];

export const Organizations: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNewOrgModalOpen, setNewOrgModalOpen] = useState(false);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(false);
  const { mutate: deleteOrganizations } = useOrganizationsDelete();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const paginationAndSorting = usePaginationAndSorting();
  const [tableRows, setTableRows] = useState([]);
  const { data: response, isLoading } = useOrganizationsListData(
    {
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    },
    searchParams
  );

  const redirectTo = useCallback(
    (route: string) => (row: Row) => {
      dispatch(setActiveOrganization({ label: row.name, value: row.id }));
      navigate(`/${route}`);
    },
    [dispatch, navigate]
  );

  const parseOrganizationsToTableData = useCallback(
    (data: OrganizationPaginatedData[]) =>
      data?.length
        ? data.map(({ id, name, created_at, users_count, deployment_count }) => ({
          id: id.toString(),
          name,
          created_at: getFormattedDate(created_at),
          deployments: { name: deployment_count, onClick: redirectTo(ROUTES.deployments) },
          users_count: { name: users_count, onClick: redirectTo(ROUTES.users) }
        }))
        : [],
    [redirectTo]
  );

  useEffect(() => {
    if (response?.data.length) {
      setTableRows(parseOrganizationsToTableData(response?.data));
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const onGenerateReportFormSubmit = useCallback(
    async (values: ReportParams) => {
      const { dateTo, dateFrom, includeSuccessfulFailed } = values;
      try {
        await generateReport(selectedIds.map(Number), dateFrom, dateTo, includeSuccessfulFailed);
        setIsGenerateReportModalOpen(false);
      } catch (error) {
        toast('There was an error generating the report', { type: 'error' });
      }
    },
    [selectedIds]
  );

  const actions = useMemo(
    () => [
      {
        name: 'View',
        icon: IconEye,
        onClick: (row: Row) => navigate(`/${ROUTES.organizations}/${row.id}`)
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: Row) =>
          dispatch(
            setAlert({
              title: `Delete ${row.name}`,
              message: `Are you sure you want to delete ${row.name}?`,
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => deleteOrganizations([row.id])
              }
            })
          )
      }
    ],
    [navigate, dispatch]
  );

  const Header = useCallback(() => {
    return (
      <>
        {selectedIds.length ? (
          <SimpleButton
            outline
            label='Delete Selected'
            icon={IconTrash}
            onClick={() =>
              dispatch(
                setAlert({
                  title: 'Delete Organizations',
                  message: 'Are you sure you want to delete the selected organizations?',
                  isOpen: true,
                  positiveButton: {
                    title: 'Confirm and Delete',
                    onClick: () => deleteOrganizations(selectedIds)
                  }
                })
              )
            }
          />
        ) : null}
        <SimpleButton
          outline
          label='Generate Report'
          icon={IconReport}
          info={!selectedIds.length ? 'Select organizations to generate a report' : null}
          disabled={!selectedIds.length}
          onClick={() => setIsGenerateReportModalOpen(true)}
        />
      </>
    );
  }, [selectedIds]);

  return (
    <Wrapper>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <TableHeaderWrapper>
          <Title>Organizations</Title>
          <CommonButton label='+ Add Organization' onClick={() => setNewOrgModalOpen(true)} />
        </TableHeaderWrapper>
        <GenerateReportModal
          isDisabled={!selectedIds.length}
          title='Generate Organizations Report'
          visible={isGenerateReportModalOpen}
          setVisible={setIsGenerateReportModalOpen}
          onClick={onGenerateReportFormSubmit}
        />
        <NewOrganizationModal setVisible={setNewOrgModalOpen} visible={isNewOrgModalOpen} />
        <RecordTable
          data={tableRows}
          total={response?.total}
          header={Header}
          setSearch={setSearchParams}
          isLoading={isLoading}
          pagination={paginationAndSorting}
          actions={actions}
          columns={COLUMNS}
          selected={selectedIds.length}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
