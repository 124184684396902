import { FilterType, PaginationParams } from '../utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addNewAdministrator,
  deleteAdministrators,
  editAdministrator,
  fetchAdministratorDetail,
  fetchAdministratorsUserData,
  resetPassword
} from './queries';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../store/reducers/ui';

const useAdministratorsUsersData = (pagination: PaginationParams, search?: SearchParams, filter?: FilterType) => {
  const { sort, offset, limit } = pagination;
  return useQuery({
    queryKey: ['administrators', 'list', filter, pagination, { search: search?.text }],
    queryFn: () => fetchAdministratorsUserData({ sort, offset, limit, search, filter }),
    refetchOnWindowFocus: false
  });
};

const useAdministratorDetail = (id: string) =>
  useQuery({
    queryKey: ['administrators', id],
    queryFn: () => fetchAdministratorDetail(id),
    enabled: !!id
  });

const useAddAdministrator = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: AdministratorType) => addNewAdministrator(body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: ({ data }: { data: { data: AdministratorType } }) => {
      toast(`${data.data.first_name} ${data.data.last_name} updated`, { type: 'success' });
      queryClient.invalidateQueries(['administrators', 'list']);
      onSuccess();
    }
  });
};

const useEditAdministrator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: { id: string; body: AdministratorType }) =>
      editAdministrator(variables.id, variables.body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: ({ data }: { data: { data: AdministratorType } }) => {
      toast(`${data.data.first_name} ${data.data.last_name} updated`, { type: 'success' });
      queryClient.invalidateQueries(['administrators', 'list']);
      queryClient.invalidateQueries(['administrators', data.data.id.toString()]);
    }
  });
};

const useDeleteAdministrators = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => deleteAdministrators(ids),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Administrator(s) deleted', { type: 'success' });
      queryClient.invalidateQueries(['administrators', 'list']);
      dispatch(clearAlert());
    }
  });
};

export const useResetPassword = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (email: string) => resetPassword(email),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Password Reset Success', { type: 'success' });
      dispatch(clearAlert());
    }
  });
};

export {
  useAdministratorsUsersData,
  useAddAdministrator,
  useEditAdministrator,
  useDeleteAdministrators,
  useAdministratorDetail
};
