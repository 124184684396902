import React from 'react';
import { useField, useFormikContext } from 'formik';
import { BasicDatePicker } from '../../Dashboard/DatePicker';

interface CustomDatePickerProps {
  name: string;
  formatter?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  dateFormat?: string;
}

const FormDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  disabled,
  placeholder,
  dateFormat = 'MM/dd/yyy HH:ss'
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <BasicDatePicker
      disabled={disabled}
      onChange={val => {
        setFieldValue(name, val);
      }}
      selected={(field.value && new Date(field.value)) || null}
      placeholderText={placeholder}
      dateFormat={dateFormat}
    />
  );
};

export default FormDatePicker;
