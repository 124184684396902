import React, { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ColorName } from '../../theme';
import Spinner from '../Spinner';
import { BaseIconProps } from '../Icon/BaseIcon';
import { Body } from '../../theme/typography/Text';

export interface CommonButtonProps {
  label?: string;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  icon?: React.FC<BaseIconProps>;
  form?: string;
  color?: ColorName;
  onClick?: (e?: Event) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const ButtonLabel = styled(Body)<{ disabled: boolean }>(({ disabled, theme }) => {
  return css`
    color: ${disabled ? theme.texts.disabled : theme.texts.onColor};
    font-weight: 700;
  `;
});

const IconWrapper = styled.div`
  margin-bottom: -2px;
`;

const StyledWrapper = styled.button<{ disabled: boolean; color: string; isLoadingProp: boolean }>(
  ({ disabled, color, theme }) => {
    return css`
      border-radius: 4px;
      background: ${!disabled ? color : theme.buttons.disabled};
      background-size: 300% 100%;
      background-position: ${color} left bottom;
      transform: translateZ(0);
      height: 40px;
      border: none;
      padding: 0 15px 0 15px;
      box-shadow: none;
      gap: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    `;
  }
);

export const CommonButton: React.FC<CommonButtonProps> = ({
  label,
  onClick,
  type = 'button',
  color,
  form,
  disabled = false,
  isLoading = false,
  icon: Icon = null,
  ...props
}) => {
  const theme = useTheme();
  const tempColor = color ?? theme.buttons.primary;

  const onClickButton = useCallback(async () => {
    if (type !== 'submit') {
      onClick();
    }
  }, [onClick]);

  return (
    <StyledWrapper
      type={type}
      isLoadingProp={isLoading}
      disabled={disabled}
      form={form}
      color={tempColor}
      onClick={onClickButton}
      {...props}>
      {Icon && (
        <IconWrapper>
          <Icon size='small' color={theme.icons.onColor} />
        </IconWrapper>
      )}
      {label && !isLoading && <ButtonLabel disabled={disabled}>{label}</ButtonLabel>}
      {isLoading && <Spinner onPrimary />}
    </StyledWrapper>
  );
};
