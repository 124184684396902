import { toast, TypeOptions } from 'react-toastify';
import { format } from 'date-fns';
import { ROLE } from './constants';

export const uniqueToast = (message, type: TypeOptions = 'error') =>
  toast(message, {
    toastId: 'toast_unique_id',
    type: type
  });

export const getUserName = (first_name: string, last_name: string) =>
  first_name && last_name ? `${first_name} ${last_name}` : 'Empty';

export const getFormattedDate = (date: string) => (date ? format(date, 'MMM do yy') : 'Never');

export const isEmail = (email: string) => /[\w.-]+@[\w.-]+\.[\w.-]+/.test(email);

export const getDynamicColor = (hex, lum = 0) => {
  const sanitizedHex = String(hex)
    .replace(/[^0-9a-f]/gi, '')
    .substring(0, 6);
  const parsedLum = Math.min(1, Math.max(-1, lum));
  const rgb = ['#'];

  for (let i = 0; i < 3; i++) {
    let colorChannel = parseInt(sanitizedHex.substring(i * 2, i * 2 + 2), 16);
    if (isNaN(colorChannel)) {
      colorChannel = 0;
    }
    const adjustedColorChannel = Math.round(Math.min(Math.max(0, colorChannel + colorChannel * parsedLum), 255));
    const hexString = adjustedColorChannel.toString(16).padStart(2, '0');
    rgb.push(hexString);
  }

  return rgb.join('');
};

export const getRoleLabel = (role: string, entity_name: string) =>
  role === ROLE.INTERNAL ? 'Internal Rollio User' : `${entity_name} Administrator`;
