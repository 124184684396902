import { css } from 'styled-components';

/**
 *
 * @param color A color to apply the opacity to
 * @param opacity A number between 0 and 1
 * @returns A new color with the given opacity
 */
export const alpha = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const cardStyle = css`
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(197, 197, 197, 0.4);
`;
