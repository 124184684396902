import React, { Dispatch, HTMLInputTypeAttribute, SetStateAction } from 'react';
import styled from 'styled-components';
import { RollioTheme } from '../../theme/RollioTheme';

interface CommonInputProps {
  name?: string;
  placeholder?: string;
  value: string;
  onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: Dispatch<SetStateAction<string>> | ((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void);
  isDisabled?: boolean;
  masked?: boolean;
  type?: HTMLInputTypeAttribute;
  autoComplete?: string;
  style?: React.CSSProperties;
}

const StyledWrapped = styled.div<{ theme: RollioTheme }>`
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.layers.first};
  width: 300px;
  padding: 5px 15px 5px 15px;
`;

const StyledInput = styled.input<{ theme: RollioTheme; disabled: boolean }>`
  font-family: Lato, serif;
  font-weight: 500;
  font-size: 1rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: ${({ theme, disabled }) => (disabled ? theme.texts.disabled : theme.texts.primary)};
`;

export const CommonInput: React.FC<CommonInputProps> = ({
  name,
  onChange,
  onChangeText,
  placeholder,
  value,
  isDisabled,
  type,
  autoComplete,
  ...props
}) => {
  const onTextChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <StyledWrapped {...props}>
      <StyledInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChangeText ?? onTextChange}
        disabled={isDisabled}
        type={type}
        autoComplete={autoComplete}
      />
    </StyledWrapped>
  );
};
