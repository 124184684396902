import { useQuery } from '@tanstack/react-query';
import { fetchPaginatedConversationMessages } from './queries';

export const useNewConversationsData = (deploymentId: string, sfUserId: string, pagination: PaginationType) => {
  const { limit, offset } = pagination;

  return useQuery({
    queryKey: ['conversations', deploymentId, sfUserId, { limit, offset }],
    enabled: !!deploymentId && !!sfUserId,
    queryFn: () => fetchPaginatedConversationMessages({ offset, limit, deploymentId, sfUserId }),
    refetchOnWindowFocus: false
  });
};
