import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { RollioAlert } from '../../components/Modals/Custom/RollioAlert';

export type UIState = {
  alert: RollioAlert | null;
  isDarkModeEnabled: boolean;
  isDrawerExpanded: boolean;
  lastSavedChanges: Date;
};

const initialState: UIState = {
  isDarkModeEnabled: true,
  alert: null,
  isDrawerExpanded: false,
  lastSavedChanges: undefined
};

// Slice
export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<RollioAlert>) => {
      state.alert = action.payload;
    },
    clearAlert: state => {
      state.alert = null;
    },
    setDrawerExpanded: (state, action) => {
      state.isDrawerExpanded = action.payload;
    },
    setLastSavedChanges: (state, action) => {
      state.lastSavedChanges = action.payload;
    },
    setDarkModeIsEnabled: (state, action) => {
      state.isDarkModeEnabled = action.payload;
    },
    resetUI: () => initialState
  }
});

// Actions
export const { setAlert, clearAlert, setDrawerExpanded, setLastSavedChanges, setDarkModeIsEnabled } = ui.actions;

// Selectors
export const getIsDarkModeEnabled = (state: RootState) => state.ui.isDarkModeEnabled;

export const getAlert = (state: RootState) => state.ui.alert;
export const getLastSavedChanges = (state: RootState) => state.ui?.lastSavedChanges;

export const getIsDrawerOpen = (state: RootState) => state.ui.isDrawerExpanded;

export default ui.reducer;
