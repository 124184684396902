import { colors } from '../../../../theme';
import styled from 'styled-components';

export const RuleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const RuleHeader = styled.h2`
  margin: 0;
  font-size: 1.2rem;
`;

export const ConditionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

export const Condition = styled.div`
  display: flex;
  margin: 5px 0 5px 0;
  flex-direction: column;
  flex: 1;
  width: 95%;
  border-radius: 5px;
  padding: 5px;
  background-color: ${colors.ruleGrey};
  align-items: center;
`;

export const DeleteRuleButton = styled.button`
  position: absolute;
  top: -10px;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 500px;
  right: -10px;
  background-color: red;
  border: none;
  color: white;
  cursor: pointer;
`;

export const ConditionSelect = styled.select`
  margin-right: 8px;
  background-color: ${colors.inputGrey};
  border: 1px solid ${colors.oldBlue2}
  height: 40px;
  font-family: 'Open Sans', serif;
  border-radius: 8px;
  color: ${colors.oldBlue3};
`;

export const RuleContent = styled.div`
  position: relative;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin: 5px 0 5px 0;
  flex-direction: column;
  flex: 1;
  width: 95%;
  border-radius: 5px;
  padding: 5px;
  background-color: ${colors.ruleGrey};
  align-items: center;
`;

export const ConditionInput = styled.input`
  overflow: hidden;
  background-color: ${colors.inputGrey};
  border: 1px solid ${colors.oldBlue2};
  height: 30px;
  font-family: 'Open Sans', serif;
  border-radius: 8px;
  color: ${colors.oldBlue3};
`;

export const AddNestedRuleButton = styled.button`
  /* Add your desired styles here */
  margin-left: 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
`;

export const ConditionsWrapper = styled.div`
  flex: 1;
  position: relative;
  margin-top: 5px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const handleInputChange = (id, localValues, setLocalValues, updateItem, updatedValue) => {
  const newLocalValues = { ...localValues, [id]: updatedValue };
  setLocalValues(newLocalValues);
  updateItem(id, { ...localValues[id], value: updatedValue });
};
