import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { CommonButton } from '../../Buttons/CommonButton';
import { Body } from '../../../theme/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert, getAlert } from '../../../store/reducers/ui';

type Button = {
  title: string;
  onClick: () => void;
};

type IconType = 'alert' | 'loading' | 'none';

export interface RollioAlert {
  title: string;
  message: string;
  icon?: IconType;
  isOpen?: boolean;
  positiveButton?: Button;
  negativeButton?: Button;
  dismissible?: boolean;
  onDismiss?: () => void;
}

const RollioAlertComponent: React.FC<RollioAlert> = ({
  title,
  message,
  isOpen = false,
  dismissible = true,
  positiveButton,
  negativeButton,
  onDismiss
}) => {
  return (
    <Modal onCloseClick={onDismiss} title={title} isOpen={isOpen}>
      <AlertContainer>
        <TextsContainer>
          <Message>{message}</Message>
        </TextsContainer>
        <ButtonsContainer>
          {negativeButton ? <CommonButton label={negativeButton.title} onClick={negativeButton.onClick} /> : null}
          {positiveButton ? <CommonButton label={positiveButton.title} onClick={positiveButton.onClick} /> : null}
        </ButtonsContainer>
      </AlertContainer>
    </Modal>
  );
};

const ConnectedRollioAlert: React.FC = () => {
  const alert = useSelector(getAlert);
  const dispatch = useDispatch();

  const dismissAlert = useCallback(() => dispatch(clearAlert()), [dispatch]);

  return alert ? <RollioAlertComponent {...alert} onDismiss={dismissAlert} /> : null;
};

const AlertContainer = styled.div`
  flex: 1;
`;

const ButtonsContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  gap: 16px;
`;

const TextsContainer = styled.div`
  align-items: center;
  padding: 0 20px 20px 20px;
`;

const Message = styled(Body).attrs({ secondary: true })`
  text-align: center;
`;

export { RollioAlertComponent, ConnectedRollioAlert };
