import React, { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Body, Title2 } from '../../../../theme/typography/Text';

ChartJS.register(ArcElement, Tooltip, Legend);

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.layers.first};
  height: 280px;
  max-width: 400px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
`;
const ChartWrapper = styled.div`
  max-height: 200px;
  padding: 10px;
  display: flex;
`;

const FirstBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SecondBlock = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
`;

const LabelWrapper = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const StyledTitle = styled(Title2)`
  margin: 0;
`;

const LegendLabel = styled(Body)`
  margin: 0;
`;

const StyledPercentage = styled(Body)`
  color: ${({ theme }) => theme.colors.primary};
`;

const LegendCircle = styled.div(({ color }) => {
  return css`
    color: ${({ theme }) => theme.layers.first};
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 5px solid ${color}; ;
  `;
});

const StyledLegend = ({ label, color, value }) => (
  <LabelWrapper>
    <LegendCircle color={color} />
    <LegendLabel>{label}</LegendLabel>
    <StyledPercentage>{value}</StyledPercentage>
  </LabelWrapper>
);

export const DoughnutChart = () => {
  const theme = useTheme();

  const data = useMemo(
    () => ({
      datasets: [
        {
          data: [30, 70],
          backgroundColor: [theme.colors.primary, theme.colors.secondary],
          borderWidth: 0
        }
      ]
    }),
    [theme]
  );

  return (
    <Wrapper>
      <FirstBlock>
        <StyledTitle>Use of licenses</StyledTitle>
        <ChartWrapper>
          <Doughnut data={data} options={{ cutout: 70 }} />
        </ChartWrapper>
      </FirstBlock>
      <SecondBlock>
        {[
          { color: theme.colors.primary, label: 'Used', value: '70%' },
          {
            color: theme.colors.secondary,
            label: 'Available',
            value: '30%'
          }
        ].map((props, index) => {
          const key = `doughnut_legend_${index}`;
          return <StyledLegend key={key} {...props} />;
        })}
      </SecondBlock>
    </Wrapper>
  );
};
