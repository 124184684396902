export type ColorName =
  | 'primary'
  | 'greyLight'
  | 'offWhite'
  | 'auxiliaryBlue'
  | 'auxiliaryGrey'
  | 'secondaryBlue'
  | 'titleBlue'
  | 'disabledGrey'
  | 'secondaryGrey'
  | 'secondaryPurple'
  | 'offWhite2'
  | 'salesforceBlue'
  | 'offWhite3'
  | 'green'
  | 'yellow'
  | 'textBlue'
  | 'lightRed'
  | 'selectGrey'
  | 'ruleGrey'
  | 'greyDark'
  | 'darkPurple'
  | 'orange'
  | 'selectBlue'
  | 'oldBlue'
  | 'checkboxBlue'
  | 'purple'
  | 'inputGrey'
  | 'oldBlue2'
  | 'oldBlue3'
  | 'rowBlue'
  | 'linkBlue'
  | 'white'
  | 'red';

export const colors: { [key in ColorName]: string } = {
  red: '#ee454f',
  lightRed: '#E197A7',
  orange: '#F07A48',
  yellow: '#FFBA00',
  green: 'rgba(66, 172, 0, 0.7)',
  darkPurple: '#6F86D6',
  secondaryPurple: '#E0E4F4',
  purple: '#97A7E1',
  primary: '#1e147d',
  secondaryBlue: '#27356A',
  selectBlue: '#2A447F',
  titleBlue: '#334D8D',
  auxiliaryBlue: '#47718A',
  linkBlue: '#40657D',
  oldBlue2: '#4E7D96',
  oldBlue: '#6F96AA',
  oldBlue3: '#5D7E93',
  checkboxBlue: '#91AEBE',
  rowBlue: '#DBE4EB',
  textBlue: '#3E445C',
  offWhite: '#f2f2f2',
  offWhite2: '#f9f9f9',
  salesforceBlue: '#00A1E0',
  offWhite3: '#F1F6FA',
  ruleGrey: 'rgb(232, 232, 232)',
  white: '#ffffff',
  selectGrey: '#9FA4A7',
  greyLight: '#898989',
  inputGrey: '#DFE8EE',
  disabledGrey: '#A7B2BA',
  greyDark: '#515151',
  auxiliaryGrey: '#E3EBF1',
  secondaryGrey: '#A5A5A5'
};

type FontName = 'default' | 'mono';

export const fonts: { [key in FontName]: string } = {
  default: 'Open Sans, serif',
  mono: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace'
};
