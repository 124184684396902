import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../store/reducers/ui';
import axiosInstance from '../axiosInstances';
import { ORGANIZATIONS_URL } from '../../../constants';
import { PaginationParams } from '../utils';
import {
  addNewOrganization,
  deleteOrganizations,
  editOrganization,
  fetchOrganizationData,
  fetchOrganizationDatasources,
  fetchPaginatedOrganizationData
} from './queries';

const useAddOrganization = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: OrganizationType) => addNewOrganization(body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: ({ data }: { data: { data: OrganizationType } }) => {
      onSuccess();
      toast(`${data.data.name} Organization Created`, { type: 'success' });
      queryClient.invalidateQueries(['organizations', 'list']);
    }
  });
};

const useOrganizationEdit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: { id: string; body: OrganizationType }) =>
      editOrganization(variables.id, variables.body),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: ({ data }: { data: { data: OrganizationType } }) => {
      toast(`${data.data.name} updated`, { type: 'success' });
      queryClient.invalidateQueries(['organizations', 'list']);
      queryClient.invalidateQueries(['organizations', data.data.id.toString()]);
    }
  });
};

const useOrganizationsDelete = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => deleteOrganizations(ids),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      toast('Organization(s) deleted', { type: 'success' });
      queryClient.invalidateQueries(['organizations', 'list']);
      dispatch(clearAlert());
    }
  });
};

const useOrganizationDetail = (organizationId: string) =>
  useQuery({
    queryKey: ['organizations', organizationId],
    queryFn: () => fetchOrganizationData(organizationId),
    enabled: !!organizationId
  });

const useOrganizationDatasources = (organizationId: string, searchParams?: SearchParams) =>
  useQuery({
    queryKey: ['organizations', 'datasources', 'list', organizationId, searchParams],
    queryFn: () => fetchOrganizationDatasources(organizationId, searchParams),
    enabled: !!organizationId
  });

const generateReport = async (
  organization_ids: number[],
  date_from: string,
  date_to: string,
  include_successful_failed: boolean
) => {
  try {
    const response = await axiosInstance.post(`${ORGANIZATIONS_URL}/report`, {
      organization_ids,
      date_from,
      date_to,
      include_successful_failed
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('Download', `${date_from}_${date_to}-report.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    toast(error.message, { type: 'error' });
  }
};

const useOrganizationsListData = (pagination: PaginationParams, search?: SearchParams) => {
  const { sort, offset, limit } = pagination;
  return useQuery({
    queryKey: ['organizations', 'list', pagination, { search: search?.text }],
    queryFn: () => fetchPaginatedOrganizationData({ sort, offset, limit, search }),
    refetchOnWindowFocus: false
  });
};

export {
  useAddOrganization,
  useOrganizationEdit,
  useOrganizationsDelete,
  useOrganizationDetail,
  useOrganizationDatasources,
  generateReport,
  useOrganizationsListData
};
