import React, { useContext, useState } from 'react';
import FlowChart from '../../components/FlowChart';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../../theme';
import { EditableButtonComponent } from '../../components/Buttons/EditableButton';

import { EntityPanel } from '../../components/Conversations/EntityPanel';
import { ConversationEditorContext } from '../../components/FlowChart/Context';
import { CommonButton } from '../../components/Buttons/CommonButton';
import DraggableDrawer from '../../components/Drawer';
import { DecisionPanel } from '../../components/Conversations/DecisionPanel';
import { NoSelection } from '../../components/Conversations/EntityPanel/Parts/NoSelection';
import { ObjectPanel } from '../../components/Conversations/ObjectPanel';

const componentMap = {
  initial: ObjectPanel,
  decision: DecisionPanel,
  entity: EntityPanel
};

const FlowChartWrapper = styled.div`
  background-color: white;
  min-width: 100%;
  height: 100%;
  border-radius: 0 0 15px 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 97vh;
  width: 87.5vw;
  flex-direction: column;
  margin: 0 30px 30px 30px;
`;

const TextWrapper = styled.div`
  background-color: white;
  flex: 1;
  flex-direction: column;
  margin-top: 30px;
  border-radius: 15px;
  min-height: 250px;
  height: 85vh;
`;

const Header = styled.div`
  height: 25px;
  border-radius: 15px 15px 0 0;
  background-color: ${colors.secondaryBlue};
  width: 100%;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  flex: 1;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.p`
  font-family: 'Open Sans', serif;
  margin: 0 0 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.white};
`;

const Label = styled.label`
  font-family: 'Open Sans', serif;
  display: inline-block;
  font-size: 12px;
  padding: 0;
  text-align: center;
  color: ${colors.greyDark};
`;

const createPanel = (entityType, data, setInputData) => {
  const Component = componentMap[entityType];

  if (!Component) {
    console.warn(`No component found for entity type: ${entityType.value}`);
    return null;
  }

  return <Component node={data} saveNode={setInputData} />;
};

export const ConversationView: React.FC = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('Untitled Flow');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { selectedElement, setSelectedElement, updateNode } = useContext(ConversationEditorContext);

  const onClickNode = node => {
    setSelectedElement(node);
    setDrawerOpen(true);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <EditableButtonComponent buttonText={name} setButtonText={setName} />
        <InfoWrapper>
          <Label>Last draft saved at 3:00pm</Label>
          <CommonButton style={{ marginLeft: 10 }} color='salesforceBlue' label='Sync Metadata with SalesForce' />
          <CommonButton style={{ marginLeft: 10 }} color='purple' label='Save Draft' />
          <CommonButton style={{ marginLeft: 10 }} label='Publish' />
        </InfoWrapper>
      </HeaderWrapper>
      <Header>
        <SubTitle>CONVERSATION BUILDER</SubTitle>
      </Header>
      <FlowChartWrapper>
        <FlowChart onClickNode={onClickNode} />
      </FlowChartWrapper>
      {/*<TextWrapper>*/}
      {/*  <Header>*/}
      {/*    <SubTitle>DEBRIEF SIMU'Open Sans'R</SubTitle>*/}
      {/*  </Header>*/}
      {/*</TextWrapper>*/}
      <DraggableDrawer setIsOpen={setDrawerOpen} isOpen={drawerOpen}>
        {selectedElement && selectedElement.data ? (
          createPanel(selectedElement.data.type, selectedElement, updateNode)
        ) : (
          <NoSelection />
        )}
      </DraggableDrawer>
    </Wrapper>
  );
};
