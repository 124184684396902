import styled from 'styled-components';

interface Props {
  underline?: boolean;
  secondary?: boolean;
}

const BaseText = styled.p<Props>`
  font-family: Lato, serif;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme, secondary }) => (secondary ? theme.texts.secondary : theme.texts.primary)};
  text-decoration-color: ${({ theme, secondary }) => (secondary ? theme.texts.secondary : theme.texts.primary)};
  text-decoration-line: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

const Body = BaseText;

const Callout = styled(BaseText)`
  font-size: 13px;
  line-height: 18px;
`;

const Subhead = styled(BaseText)`
  font-size: 12px;
  line-height: 16px;
`;

const Caption = styled(BaseText)`
  font-size: 11px;
  line-height: 13px;
`;

const LargeTitle = styled(BaseText)`
  font-size: 31px;
  line-height: 38px;
`;

const Title1 = styled(BaseText)`
  font-size: 25px;
  line-height: 31px;
`;

const Title2 = styled(BaseText)`
  font-size: 19px;
  line-height: 24px;
`;

const Title3 = styled(BaseText)`
  font-size: 17px;
  line-height: 17px;
`;

const Headline = styled(BaseText)`
  font-weight: 600;
`;

export { LargeTitle, Title1, Title2, Title3, Headline, Body, Callout, Subhead, Caption };
