import axiosInstance from '../axiosInstances';
import { ORGANIZATIONS_URL } from 'src/constants';
import { PaginationParams, parsePaginatedUrl, parseSearchParams } from '../utils';
import { OrganizationPaginatedData } from './types';

const fetchOrganizationData = async (organizationId: string) =>
  (
    await axiosInstance.get<{
      data: OrganizationType;
    }>(`${ORGANIZATIONS_URL}/${organizationId}`)
  ).data.data;

const fetchPaginatedOrganizationData = async (params: PaginationParams) =>
  (
    await axiosInstance.get<{
      data: OrganizationPaginatedData[];
      total: number;
    }>(parsePaginatedUrl(`${ORGANIZATIONS_URL}/search`, params))
  ).data;

const fetchOrganizationDatasources = async (organizationId: string, searchParams?: SearchParams) =>
  (
    await axiosInstance.get<{
      data: OrganizationInstanceType[];
    }>(parseSearchParams(`${ORGANIZATIONS_URL}/${organizationId}/instances`, searchParams))
  ).data.data;

const addNewOrganization = async (organization: OrganizationType) =>
  await axiosInstance.post<{ data: OrganizationType }>(`${ORGANIZATIONS_URL}`, organization);

const editOrganization = async (id: string, organization: OrganizationType) =>
  await axiosInstance.put<{ data: OrganizationType }>(`${ORGANIZATIONS_URL}/${id}`, organization);

const deleteOrganizations = async (ids: string[]) =>
  await axiosInstance.delete<void>(`${ORGANIZATIONS_URL}`, {
    data: ids
  });

export {
  fetchOrganizationData,
  fetchPaginatedOrganizationData,
  fetchOrganizationDatasources,
  addNewOrganization,
  editOrganization,
  deleteOrganizations
};
