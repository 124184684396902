import React, { useState } from 'react';
import {
  ActionWrapper,
  ConditionInput,
  ConditionSelect,
  ConditionsWrapper,
  DeleteRuleButton,
  handleInputChange
} from './Common';
import { debounce } from 'lodash';
import { IconClose } from '../../../Icon/IconClose';

export const LogicAction = ({ action, deleteAction, updateAction, possibleTargets }) => {
  const debouncedUpdateAction = debounce(updateAction, 300);

  const [localValues, setLocalValues] = useState(action);

  return (
    <ActionWrapper>
      <ConditionsWrapper>
        <ConditionSelect
          value={action.type}
          onChange={e =>
            debouncedUpdateAction(action.id, {
              ...action,
              type: e.target.value
            })
          }>
          <option value=''>Select action</option>
          <option value='action1'>SET VALUE</option>
          <option value='action2'>SKIP</option>
        </ConditionSelect>
        <ConditionSelect
          value={action.target}
          onChange={e =>
            debouncedUpdateAction(action.id, {
              ...action,
              target: e.target.value
            })
          }>
          <option value=''>Select target</option>
          {possibleTargets}
        </ConditionSelect>
        <ConditionInput
          type='text'
          value={localValues[action.id] || ''}
          onChange={e => {
            handleInputChange(
              action.id,
              localValues,
              setLocalValues,
              debouncedUpdateAction, // Replace with your debounced function for updating actions
              e.target.value
            );
          }}
        />
      </ConditionsWrapper>
      <DeleteRuleButton onClick={() => deleteAction(action.id)}>
        <IconClose />
      </DeleteRuleButton>
    </ActionWrapper>
  );
};
