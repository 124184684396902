import { ToggleSwitch } from '../../../ToggleSwitch';
import React, { useMemo, useState } from 'react';
import { GroupFieldWrapper, SingleLineWrapper, Wrapper } from './Common';
import { FieldLabel } from '../../Common';
import RuleEditor from '../../RuleEditor';

export const DefaultValueInput = ({ defaultValue, setData }) => {
  const [logicalRules, setLogicalRules] = useState([]);
  const message = useMemo(
    () => (!logicalRules.length ? 'If no logic rules are specified, the default value will always be set.' : ''),
    [logicalRules]
  );

  return (
    <Wrapper>
      <SingleLineWrapper>
        <FieldLabel label='Set Default Value' info='Select the object name' />
        <ToggleSwitch
          on={defaultValue}
          handleToggle={value => setData('default_value', value.target.checked)}
          name={'default_value'}
          isDisabled={false}
        />
      </SingleLineWrapper>
      {!!defaultValue && (
        <GroupFieldWrapper>
          <RuleEditor rules={logicalRules} setRules={setLogicalRules} message={message} />
        </GroupFieldWrapper>
      )}
    </Wrapper>
  );
};
