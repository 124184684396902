import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from 'src/hooks';
import { CommonInput } from '../../components/Input/CommonInput';
import { CommonButton } from '../../components/Buttons/CommonButton';
import { SimpleButton } from '../../components/Dashboard/SimpleButton';
import IconRollio from '../../components/Icon/IconRollio';
import { Body } from '../../theme/typography/Text';

const Wrapper = styled.div`
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

const BoxWrap = styled.div`
  background-color: ${({ theme }) => theme.layers.first};
  border-radius: 4px;
  padding: 30px 20px 20px;
`;

const FormHeader = styled.header`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const FormWrapper = styled.form`
  align-content: center;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  display: flex;
  justify-content: center;
`;

const StyledInput = styled(CommonInput)`
  background-color: ${({ theme }) => theme.layers.second};
`;

const StyledRollioLabel = styled(Body)`
  letter-spacing: 0.1em;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
`;

const ForgotWrapper = styled.div`
  flex: 1;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorLabel = styled.label`
  font-family: 'Open Sans', serif;
  font-size: 0.9rem;
  min-height: 60px;
  display: inline-block;
  color: ${({ theme }) => theme.support.error};
  margin: 0;
`;

export const Login: React.FC = () => {
  const { login, isLoggingIn, loginError } = useAuth();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!isLoggingIn) {
        return login(username, password);
      }
      return null;
    },
    [login, username, password, isLoggingIn]
  );

  return (
    <Wrapper>
      <BoxWrap>
        <FormHeader>
          <IconRollio size={45} />
          <StyledRollioLabel>STUDIO</StyledRollioLabel>
        </FormHeader>
        <FormWrapper onSubmit={onFormSubmit}>
          <StyledInput autoComplete='username' placeholder='Username' value={username} onChange={setUserName} />
          <StyledInput
            value={password}
            placeholder='Password'
            key='password'
            type='password'
            autoComplete='current-password'
            onChange={setPassword}
          />
          <ErrorLabel>{loginError}</ErrorLabel>
          <CommonButton label='Login' type='submit' isLoading={isLoggingIn} />
        </FormWrapper>
        <ForgotWrapper>
          <SimpleButton
            label='Forgot password?'
            onClick={() => {
              alert('Forgot');
            }}
          />
        </ForgotWrapper>
      </BoxWrap>
    </Wrapper>
  );
};
