import React from 'react';
import styled from 'styled-components';
import userPlaceholder from 'src/assets/avatar-low.jpg';
import { colors, fonts } from '../theme';
import { BaseIconProps } from './Icon/BaseIcon';

interface ListItemProps {
  id: number | string;
  title: string;
  description?: string;
  avatar?: string;
  icon?: React.FC<BaseIconProps>;
  onClick?: () => void;
}

export interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  items: Array<Omit<ListItemProps, 'onClick'>>;
  onRowClick?: (id: number | string) => void;
}

interface ListItemTextProps {
  primary: string;
  secondary?: string;
}

interface ListItemAvatarProps {
  src: string;
  alt: string;
}

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  flex: 1;
  position: relative;
  list-style: none;
`;

const StyledListItem = styled.li`
  height: 90px;
  cursor: pointer;
  width: 400px;
  border-radius: 10px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  padding: 15px;
  border: 2px solid #a7cade;

  &:active {
    background-color: #edf4f8;
  }

  &:hover {
    background-color: #edf4f8;
  }
`;

const StyledListItemAvatar = styled.div`
  min-width: 56px;
  flex-shrink: 0;
`;

const StyledAvatar = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  font-family: ${fonts.default};
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

const StyledAvatarImg = styled.img`
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
`;

const StyledListItemIcon = styled.div`
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
`;

const StyledListItemText = styled.div`
  text-align: center;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonTitle = styled.p`
  font-family: 'Open Sans', serif;
  font-size: 0.9rem;
  color: black;
  margin: 0 0 0 0;
`;

const ButtonSubtitle = styled.p`
  font-family: 'Open Sans', serif;
  font-size: 0.7rem;
  color: ${colors.greyLight};
  margin: 0 0 0 0;
`;

const ListItemText: React.FC<ListItemTextProps> = ({ primary, secondary }) => (
  <StyledListItemText>
    <ButtonTitle>{primary}</ButtonTitle>
    {secondary && <ButtonSubtitle>{secondary}</ButtonSubtitle>}
  </StyledListItemText>
);

const ListItemAvatar: React.FC<ListItemAvatarProps> = ({ src, alt }) => {
  return (
    <StyledListItemAvatar>
      <StyledAvatar>
        <StyledAvatarImg alt={alt} src={src ? userPlaceholder : userPlaceholder} />
      </StyledAvatar>
    </StyledListItemAvatar>
  );
};

const ListItem: React.FC<ListItemProps> = ({ title, description, avatar, icon: IconComponent, onClick }) => (
  <StyledListItem onClick={onClick}>
    <Wrapper>
      <ListItemAvatar alt={title} src={avatar} />
      {IconComponent && (
        <StyledListItemIcon>
          <IconComponent />
        </StyledListItemIcon>
      )}
      <ListItemText primary={title} secondary={description} />
    </Wrapper>
  </StyledListItem>
);

export const List: React.FC<ListProps> = ({ items, onRowClick, children, ...props }) => (
  <StyledList {...props}>
    {items.map(item => (
      <ListItem
        key={item.id}
        onClick={(): void => {
          if (onRowClick) {
            onRowClick(item.id);
          }
        }}
        {...item}
      />
    ))}
    {children}
  </StyledList>
);
