import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Global styles
import 'react-toastify/dist/ReactToastify.css';
import 'reactflow/dist/style.css';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';

import { App } from './App';
import store from './store';
import { modalSetAppElement } from './components/Modals/Modal';

const container = document.getElementById('root');
const root = createRoot(container);

if (root) {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );

  modalSetAppElement(container);
}
