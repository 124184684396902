import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { SortButton } from './parts/SortButton';
import { CheckBox } from './parts/CheckBox';
import Spinner from '../Spinner';
import { TableSortingType } from './hooks/usePaginationHook';
import { TableHeader } from './parts/Header';
import { ActionButton } from './parts/ActionButton';
import { TableFooter } from './parts/Footer';
import { Title3 } from '../../theme/typography/Text';

export interface ActionType {
  name: string;
  icon?: React.FC;
  onClick: (row: Row) => void;
  isDisabled?: (row: Row) => boolean;
}

interface NoResultsMessageProps {
  amountOfColumns: number;
}

export interface Row {
  name?: string;
  id: string;
}

type CellValue = string | boolean | number | { name: string; onClick: (row: Row) => void };

interface Column {
  key: string;
  label: string;
  sortable?: boolean;
  formatter?: (cell: CellValue, row?: Row) => React.ReactElement;
}

interface TableProps {
  data: Row[];
  header?: any;
  total?: number;
  selected?: number;
  setSearch?: Dispatch<SetStateAction<SearchParams>>;
  pagination: TableSortingType;
  isLoading?: boolean;
  columns: Column[];
  actions?: ActionType[];
  toggleSelected?: (rowId: string) => void;
  isSelected?: (rowId: string) => boolean;
  deselectAll?: () => void;
  selectable?: boolean;
  selectAll?: (rows: Row[]) => void;
}

interface TableRowProps {
  rowData: Row;
  selected: boolean;
  selectable?: boolean;
  onSelect: (id: string) => void;
  columns: { key: string; label: string }[];
  actions?: ActionType[];
}

const MainContainer = styled.div`
  display: block;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.layers.first};
  border-radius: 8px;
  flex-direction: column;
`;

const StyledTable = styled.table`
  caption-side: top;
  border: none;
  width: 100%;
  border-collapse: collapse;
`;

const StyledCell = styled.td`
  display: table-cell;
  color: ${({ theme }) => theme.texts.secondary};
  vertical-align: middle;
  text-align: center;
  font-family: Lato, serif;
`;

const ActionsWrapper = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

const TableRowWrapper = styled.tr`
  height: 60px;

  :hover {
    background-color: ${({ theme }) => theme.layers.second};
  }
`;

const LabelHead = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.texts.secondary};
`;

const HeadContainer = styled.div<{ sortable: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  flex: 1;
  margin-left: ${({ sortable }) => (sortable ? '10px' : '0')};
  align-items: center;
  flex-direction: row;
`;

const TableHead = styled.thead`
  height: 60px;
  background-color: ${({ theme }) => theme.borders.second};
`;

const RowTitle = styled.p<{ size?: number }>`
  font-family: 'Open Sans', serif;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.texts.secondary};
  font-size: 0.9rem;
  line-height: 0.9rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NoResultsContainer = styled.tr`
  height: 200px;

  td {
    text-align: center;
    font-style: italic;
  }
`;

const CheckboxCellStyle = styled.th`
  min-width: 50px;
  padding-left: 20px;
`;

const LoadingIndicator: React.FC<{ amountOfColumns: number }> = ({ amountOfColumns }) => (
  <tr>
    <td colSpan={amountOfColumns}>
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    </td>
  </tr>
);

const formatCell = (column: Column, rowData: Row, index: number) => {
  const cell = rowData[column.key];
  const cellId = `cell_row_${rowData.id}_cell_${index}`;

  return column.formatter ? (
    <StyledCell key={cellId}>{column.formatter(cell, rowData)}</StyledCell>
  ) : (
    <StyledCell key={cellId}>{cell}</StyledCell>
  );
};

const TableRow: React.FC<TableRowProps> = ({ rowData, selected, onSelect, columns, actions, selectable = true }) => (
  <TableRowWrapper onClick={selectable ? () => onSelect(rowData.id) : undefined}>
    {selectable ? (
      <th scope='row' style={{ paddingLeft: 20 }}>
        <CheckBox checked={selected} onChange={() => onSelect(rowData.id)} />
      </th>
    ) : null}
    {columns.map((column, index) => formatCell(column, rowData, index))}
    {actions?.length ? (
      <ActionsWrapper>
        <ActionButton row={rowData} actions={actions} />
      </ActionsWrapper>
    ) : null}
  </TableRowWrapper>
);

const NoResultsPlaceholder: React.FC<NoResultsMessageProps> = ({ amountOfColumns }) => (
  <NoResultsContainer>
    <td colSpan={amountOfColumns}>
      <Title3>Oops, there are no results for that search</Title3>
    </td>
  </NoResultsContainer>
);

export const RecordTable: React.FC<TableProps> = ({
  columns,
  header: Header,
  pagination,
  setSearch,
  total,
  isLoading,
  actions,
  selected,
  data,
  toggleSelected,
  isSelected,
  deselectAll,
  selectAll,
  selectable = true
}) => {
  const [masterCheck, setMasterCheck] = useState(false);
  const amountOfColumns = columns.length + 1 + (actions ? 1 : 0);

  return (
    <MainContainer>
      <Wrapper>
        {Header || setSearch ? (
          <TableHeader selected={selected} setSearch={setSearch}>
            {Header ? <Header /> : null}
          </TableHeader>
        ) : null}
        <StyledTable>
          <TableHead>
            <tr>
              {selectable ? (
                <CheckboxCellStyle>
                  <CheckBox
                    checked={masterCheck}
                    onChange={() => {
                      if (masterCheck) {
                        deselectAll();
                      } else {
                        selectAll(data);
                      }
                      setMasterCheck(!masterCheck);
                    }}
                  />
                </CheckboxCellStyle>
              ) : null}
              {columns.map(column => (
                <LabelHead key={column.key}>
                  <HeadContainer sortable={column.sortable}>
                    <RowTitle>{column.label}</RowTitle>
                    {column.sortable && (
                      <SortButton
                        column={column.key}
                        direction={pagination.sorting.direction}
                        updateSorting={pagination.updateSorting}
                      />
                    )}
                  </HeadContainer>
                </LabelHead>
              ))}
              {actions && (
                <LabelHead>
                  <RowTitle size={0.7}>Actions</RowTitle>
                </LabelHead>
              )}
            </tr>
          </TableHead>
          <tbody>
            {isLoading ? (
              <LoadingIndicator amountOfColumns={amountOfColumns} />
            ) : data && data.length ? (
              data.map(row => (
                <TableRow
                  key={row.id}
                  rowData={row}
                  columns={columns}
                  actions={actions}
                  onSelect={toggleSelected}
                  selectable={selectable}
                  selected={isSelected ? isSelected(row.id) : false}
                />
              ))
            ) : (
              <NoResultsPlaceholder amountOfColumns={amountOfColumns} />
            )}
          </tbody>
        </StyledTable>
      </Wrapper>
      <TableFooter total={total} pagination={pagination} />
    </MainContainer>
  );
};
