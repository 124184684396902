import { PaginationParams, parsePaginatedUrl } from '../utils';
import axiosInstance from '../axiosInstances';
import { ADMINISTRATORS_URL, AUTHENTICATION_URL } from '../../../constants';

export interface RawAdministratorData {
  id: number;
  first_name: string;
  last_name: string;
  is_active: boolean;
  email: string;
  organization_user: {
    organization: {
      id: string;
      name: string;
    };
  };
}

const fetchAdministratorsUserData = async (params: PaginationParams) =>
  (
    await axiosInstance.get<{
      data: RawAdministratorData[];
      total: number;
    }>(parsePaginatedUrl(`${ADMINISTRATORS_URL}/search`, params))
  ).data;

const fetchAdministratorDetail = async (id: string) =>
  (
    await axiosInstance.get<{
      data: RawAdministratorData;
    }>(`${ADMINISTRATORS_URL}/${id}?extraData=true`)
  ).data.data;

const resetPassword = async (email: string) => axiosInstance.post(`${AUTHENTICATION_URL}/reset-password`, { email });

const addNewAdministrator = async (administrator: AdministratorType) =>
  await axiosInstance.post<{ data: AdministratorType }>(`${ADMINISTRATORS_URL}`, administrator);

const editAdministrator = async (id: string, administrator: AdministratorType) =>
  await axiosInstance.put<{ data: AdministratorType }>(`${ADMINISTRATORS_URL}/${id}`, administrator);

const deleteAdministrators = async (ids: string[]) =>
  await axiosInstance.delete<void>(`${ADMINISTRATORS_URL}`, {
    data: ids
  });

export {
  addNewAdministrator,
  editAdministrator,
  deleteAdministrators,
  resetPassword,
  fetchAdministratorsUserData,
  fetchAdministratorDetail
};
