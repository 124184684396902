import React, { useMemo } from 'react';
import { Badge } from '../../components/Dashboard/Badges';
import styled from 'styled-components';
import { BasicDatePicker } from '../../components/Dashboard/DatePicker';
import { ConversationLogs } from '../../components/Dashboard/Charts';
import { DoughnutChart } from '../../components/Dashboard/Charts/Parts/DoughnutChart';
import { ComparingLineChart } from '../../components/Dashboard/Charts/Parts/ComparingLineChart';
import { Title1 } from '../../theme/typography/Text';
import { IconUser } from '../../components/Icon/IconUser';
import { IconDeployment } from '../../components/Icon/IconDeployment';
import { IconDatasource } from '../../components/Icon/IconDatasource';
import { IconAdministrator } from '../../components/Icon/IconAdministrator';

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 18px;
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;
`;

const GridItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

export const Dashboard: React.FC = () => {
  const badges = useMemo(
    () => [
      {
        label: 'Users',
        value: '124',
        icon: IconUser
      },
      {
        label: 'Deployments',
        value: '6',
        icon: IconDeployment
      },
      {
        label: 'Datasources',
        value: '4',
        icon: IconDatasource
      },
      {
        label: 'Organization Admins',
        value: '3',
        icon: IconAdministrator
      }
    ],
    []
  );

  return (
    <Container>
      <Header>
        <Title1>Dashboard</Title1>
        <BasicDatePicker onChange={() => null} />
      </Header>
      <BadgeContainer>
        {badges.map((item, index) => (
          <GridItem key={`badge_${index}`}>
            <Badge value={item.value} name={item.label} icon={item.icon} />
          </GridItem>
        ))}
      </BadgeContainer>
      <ConversationLogs />
      <SecondaryContainer>
        <ComparingLineChart />
        <DoughnutChart />
      </SecondaryContainer>
      {/*<SecondaryContainer>*/}
      {/*  <MostUsed mostUsed={MostTableMainTestData} otherResults={MostTableOtherTestData} />*/}
      {/*  <MostTable elements={MostTableTestData} />*/}
      {/*</SecondaryContainer>*/}
    </Container>
  );
};
