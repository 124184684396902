import React from 'react';

import { usePermission } from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';

interface Props {
  resource: RESOURCE;
  action: ACTION;
  children: any;
}

export const Permission: React.FC<Props> = ({ resource, action, children }) => {
  const { checkPermission } = usePermission();

  const hasAccess = checkPermission(resource, action);
  return hasAccess && <>{children}</>;
};
