import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../Modal';
import { CRMLogin } from './parts/CrmLogin';
import { useSfAuth } from '../../../../hooks';
import SyncUserManagement from './parts/UserManagement';
import { Body } from '../../../../theme/typography/Text';

interface SyncUsersModalProps {
  instanceId: string;
  deploymentId: string;
  deploymentName: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  min-height: 250px;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SubtitleText = styled(Body)`
  margin: 0;
  color: ${({ theme }) => theme.texts.secondary};
`;

const getSubtitle = (isLoggedIn: boolean) =>
  isLoggedIn ? 'Add/Remove your Salesforce instance users.' : 'Add new deployment users directly from salesforce';

const ManageSyncUsersModal: React.FC<SyncUsersModalProps> = ({
  instanceId,
  deploymentId,
  deploymentName,
  visible,
  setVisible
}) => {
  const { isLoggedIn, sfLogout, sfToken, user } = useSfAuth(instanceId);
  const title = !isLoggedIn ? 'Sync with Salesforce' : 'Transfer users from Salesforce';

  return (
    <Modal isOpen={visible} onCloseClick={() => setVisible(false)} title={title}>
      <StyledDiv>
        <TitleWrapper>
          <SubtitleText>{getSubtitle(isLoggedIn)}</SubtitleText>
        </TitleWrapper>
        <ContentWrapper>
          {!isLoggedIn ? (
            <CRMLogin deploymentId={deploymentId} />
          ) : (
            <SyncUserManagement
              deploymentId={deploymentId}
              setModalVisible={setVisible}
              deploymentName={deploymentName}
              logout={sfLogout}
              sfToken={sfToken}
              userEmail={user?.email}
            />
          )}
        </ContentWrapper>
      </StyledDiv>
    </Modal>
  );
};

export default ManageSyncUsersModal;
