import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getActiveDatasource, getActiveDeployment, getActiveOrganization } from '../../../store/reducers/globalFilter';

export const useQueryFilter = () => {
  const selectedOrganization = useSelector(getActiveOrganization);
  const selectedDatasource = useSelector(getActiveDatasource);
  const selectedDeployment = useSelector(getActiveDeployment);

  return useMemo(() => {
    let filter = null;

    if (selectedOrganization && selectedOrganization.value !== 'none' && selectedOrganization.value !== 'all') {
      filter = { organization: [selectedOrganization.value] };
    }

    if (selectedDatasource && selectedDatasource.value !== 'none' && selectedDatasource.value !== 'all') {
      filter = { instance: [selectedDatasource.value] };
    }
    if (selectedDeployment && selectedDeployment.value !== 'none' && selectedDeployment.value !== 'all') {
      filter = { deployment: [selectedDeployment.value] };
    }

    return filter;
  }, [selectedOrganization, selectedDatasource, selectedDeployment]);
};
