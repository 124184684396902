import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePaginationAndSorting, useRowSelectorHook } from '../../../components/Table/hooks';
import { RecordTable, Row } from '../../../components/Table';
import { getFormattedDate, getUserName } from '../../../utils';
import ManageSyncUsersModal from '../../../components/Modals/Custom/SyncUsers/ManageSyncUsersModal';
import { IconTrash } from '../../../components/Icon/IconTrash';
import { SimpleButton } from '../../../components/Dashboard/SimpleButton';
import { IconEye } from '../../../components/Icon/IconEye';
import { UserConversationsModal } from '../Parts/UserConversationsModal';
import { booleanCellFormatter } from '../../../components/Table/utils';
import { useUsersListData } from '../../../hooks/api/Users/hooks';
import { useDeleteDeploymentUsers } from '../../../hooks/api/Deployments/hooks';

interface UserComponentProps {
  deploymentName: string;
  deploymentId: string;
  instanceId: string;
  setModalVisible: (visible: boolean) => void;
}

const COLUMNS = [
  { key: 'name', label: 'Name', sortable: true },
  { key: 'email', label: 'Email' },
  { key: 'is_active', label: 'Active', formatter: booleanCellFormatter },
  { key: 'updated_at', label: 'Last Update Date', sortable: true }
];

const UserManagement: React.FC<UserComponentProps> = ({
  deploymentName,
  deploymentId,
  instanceId,
  setModalVisible
}) => {
  const paginationAndSorting = usePaginationAndSorting();
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [userDetailModal, setUserDetailModal] = useState({ visible: false, user: null });
  const [crmModalVisible, setCrmModalVisible] = useState(false);
  const queryFilter = useMemo(() => ({ deployment: [deploymentId] }), [deploymentId]);
  const userPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );
  const [tableRows, setTableRows] = useState([]);
  const { mutate: unlinkUsersFromDeployment } = useDeleteDeploymentUsers(deploymentId);
  const { isLoading, data: response } = useUsersListData(userPagination, searchParams, queryFilter);

  const parseUsersToTableData = useCallback(
    data =>
      data.map(({ id, first_name, last_name, updated_at, email, is_active }) => ({
        id,
        name: getUserName(first_name, last_name),
        email,
        updated_at: getFormattedDate(updated_at),
        is_active
      })),
    []
  );

  useEffect(() => {
    if (response?.data.length) {
      const rows = parseUsersToTableData(response.data);
      setTableRows(rows);
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const actions = useMemo(
    () => [
      {
        name: 'Conversations Detail',
        icon: IconEye,
        onClick: async (row: Row) => {
          setUserDetailModal({ visible: true, user: row });
        }
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: async (row: Row) => unlinkUsersFromDeployment([row.id])
      }
    ],
    [unlinkUsersFromDeployment]
  );

  const deleteSelected = async () => {
    unlinkUsersFromDeployment(selectedIds.map(String));
    deselectAll();
  };

  const Header = useCallback(() => {
    return (
      <>
        {selectedIds.length ? (
          <SimpleButton outline label='Delete Selected' icon={IconTrash} onClick={() => deleteSelected()} />
        ) : null}
        <SimpleButton outline label='Manage Users from Salesforce' onClick={() => setCrmModalVisible(true)} />
      </>
    );
  }, [selectedIds]);

  return (
    <>
      <ManageSyncUsersModal
        instanceId={instanceId}
        deploymentId={deploymentId}
        deploymentName={deploymentName}
        visible={crmModalVisible}
        setVisible={setCrmModalVisible}
      />
      <UserConversationsModal
        deploymentId={deploymentId}
        visible={userDetailModal.visible}
        setVisible={() => setUserDetailModal({ visible: false, user: null })}
        sfUser={userDetailModal.user}
      />
      <RecordTable
        header={Header}
        data={tableRows}
        total={response?.total ?? 0}
        setSearch={setSearchParams}
        isLoading={isLoading}
        pagination={paginationAndSorting}
        actions={actions}
        columns={COLUMNS}
        isSelected={isSelected}
        toggleSelected={toggleSelected}
        selectAll={selectAll}
        deselectAll={deselectAll}
      />
    </>
  );
};

export default UserManagement;
