import styled from 'styled-components';
import { BaseIcon, BaseIconProps } from './BaseIcon';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

export const IconEye = styled(BaseIcon).attrs<BaseIconProps>(() => ({
  as: IoEyeOutline
}))``;

export const IconEyeOff = styled(BaseIcon).attrs<BaseIconProps>(() => ({
  as: IoEyeOffOutline
}))``;
