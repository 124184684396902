import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addSyncedUsersToDeployment,
  addUsers,
  fetchLinkedAdminSfUsers,
  fetchPaginatedUserListData,
  linkAdminSfUser,
  RawUserData,
  syncUsers,
  unlinkAdminSfUsers
} from './queries';
import { FilterType, PaginationParams } from '../utils';
import { toast } from 'react-toastify';

const useUsersListData = (pagination: PaginationParams, search?: SearchParams, filter?: FilterType) => {
  const { sort, offset, limit } = pagination;
  return useQuery({
    queryKey: ['users', 'list', filter, pagination, { search: search?.text }],
    queryFn: () => fetchPaginatedUserListData({ sort, offset, limit, search, filter }),
    refetchOnWindowFocus: false
  });
};

const useLinkedSfUsers = (deploymentId: string) =>
  useQuery({
    queryKey: ['linked-admin-sf-users', 'deployment', deploymentId],
    queryFn: () => fetchLinkedAdminSfUsers(deploymentId),
    enabled: !!deploymentId
  });

const useLinkSfUser = (deploymentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: string) => linkAdminSfUser(deploymentId, username),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['linked-admin-sf-users', 'deployment', deploymentId]);
    }
  });
};

const useUnlinkSfUsers = (deploymentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => unlinkAdminSfUsers(deploymentId, ids),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['linked-admin-sf-users', 'deployment', deploymentId]);
    }
  });
};

const useAddUsers = (onSuccess, onError) =>
  useMutation({
    mutationFn: async (variables: { deploymentId: string; emails: string[] }) =>
      addUsers(variables.deploymentId, variables.emails),
    onError,
    onSuccess
  });

const useSyncSfUsers = (sfToken: string, deploymentId: string) =>
  useQuery({
    queryKey: ['salesforce-sync', 'deployment', deploymentId],
    queryFn: () => syncUsers(deploymentId, sfToken),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => toast('Users synced successfully', { type: 'success' }),
    enabled: !!sfToken && !!deploymentId,
    staleTime: 3 * 1000 * 60,
    refetchOnWindowFocus: false
  });

const useAddSyncedSfUsers = (sfToken: string, deploymentId: string, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: { usersToAdd: RawUserData[]; usersToRemove: RawUserData[] }) =>
      addSyncedUsersToDeployment(deploymentId, sfToken, variables),
    onError: (error: Error) => toast(error.message, { type: 'error' }),
    onSuccess: () => {
      onSuccess();
      toast('Users added/removed successfully', { type: 'success' });
      queryClient.invalidateQueries(['users', 'list']);
      queryClient.invalidateQueries(['salesforce-sync', 'deployment', deploymentId]);
    }
  });
};

export {
  useAddUsers,
  useAddSyncedSfUsers,
  useLinkedSfUsers,
  useLinkSfUser,
  useUnlinkSfUsers,
  useUsersListData,
  useSyncSfUsers
};
