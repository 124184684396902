import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableHeaderWrapper, Title, Wrapper } from '../../layouts/Common';
import { ROUTES } from '../../routes';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { RecordTable, Row } from '../../components/Table';
import { ColorName } from '../../theme';
import { CommonButton } from '../../components/Buttons/CommonButton';
import { GenerateReportModal, ReportParams } from '../../components/Modals/Custom/GenerateReportModal';
import { usePaginationAndSorting, useRowSelectorHook } from '../../components/Table/hooks';
import { useDeploymentsDelete, useDeploymentsListData } from '../../hooks/api/Deployments/hooks';
import { getFormattedDate } from '../../utils';
import { useQueryFilter } from '../../hooks/api/common/common';
import { setAlert } from '../../store/reducers/ui';
import { useDispatch } from 'react-redux';
import { IconEye } from '../../components/Icon/IconEye';
import { IconTrash } from '../../components/Icon/IconTrash';
import { SimpleButton } from '../../components/Dashboard/SimpleButton';
import { IconReport } from '../../components/Icon/IconReport';
import { generateReport, RawDeploymentData } from '../../hooks/api/Deployments/queries';
import { toast } from 'react-toastify';
import { NewDeploymentModal } from '../../layouts/Deployment/NewDeployment';
import { booleanCellFormatter } from '../../components/Table/utils';

const COLUMNS = [
  { key: 'name', label: 'Deployment', sortable: true },
  { key: 'is_active', label: 'Active', formatter: booleanCellFormatter },
  { key: 'updated_at', label: 'Last Update Date', sortable: true }
];

export const Deployments: React.FC = () => {
  const navigate = useNavigate();
  const { mutate: deleteRecords } = useDeploymentsDelete();
  const dispatch = useDispatch();
  const paginationAndSorting = usePaginationAndSorting();
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const { selectedIds, isSelected, toggleSelected, selectAll, deselectAll } = useRowSelectorHook();
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [tableRows, setTableRows] = useState([]);
  const queryFilter = useQueryFilter();
  const deploymentPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );

  const { isLoading, data: response } = useDeploymentsListData(deploymentPagination, searchParams, {
    organization_id: queryFilter?.organization,
    instance_id: queryFilter?.instance
  });

  const parseDeploymentsToTableData = useCallback(
    (data: RawDeploymentData[]) =>
      data?.length
        ? data.map(({ id, name, updated_at, users_count, is_active }) => ({
          id,
          name,
          updated_at: getFormattedDate(updated_at),
          is_active,
          users_count: { name: users_count }
        }))
        : [],
    []
  );

  useEffect(() => {
    if (response?.data?.length) {
      setTableRows(parseDeploymentsToTableData(response.data));
    } else {
      setTableRows([]);
    }
  }, [response?.data]);

  const onGenerateReportFormSubmit = useCallback(
    async (values: ReportParams) => {
      const { dateTo, dateFrom, includeSuccessfulFailed } = values;
      try {
        await generateReport(selectedIds, dateFrom, dateTo, includeSuccessfulFailed);
        setIsGenerateReportModalOpen(false);
      } catch (error) {
        toast('There was an error generating the report', { type: 'error' });
      }
    },
    [selectedIds]
  );

  const deleteDeployments = async (ids: string[]) => deleteRecords(ids);

  const actions = useMemo(
    () => [
      {
        name: 'View',
        icon: IconEye,
        color: 'darkPurple' as ColorName,
        onClick: (row: Row) => {
          navigate(`/${ROUTES.deployments}/${row.id}`);
        }
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: Row) =>
          dispatch(
            setAlert({
              title: `Delete ${row.name}`,
              message: `Are you sure you want to delete ${row.name}?`,
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => deleteDeployments([row.id])
              }
            })
          )
      }
    ],
    [ROUTES.deployment, navigate]
  );

  const Header = useCallback(() => {
    return (
      <>
        {selectedIds.length ? (
          <SimpleButton
            outline
            label='Delete Selected'
            icon={IconTrash}
            onClick={() =>
              dispatch(
                setAlert({
                  title: 'Delete deployments',
                  message: 'Are you sure you want to delete the selected deployments?',
                  isOpen: true,
                  positiveButton: {
                    title: 'Confirm and Delete',
                    onClick: () => deleteDeployments(selectedIds)
                  }
                })
              )
            }
          />
        ) : null}
        <SimpleButton
          outline
          disabled={!selectedIds?.length}
          info='Select deployments to generate a report.'
          icon={IconReport}
          label='Generate Report'
          onClick={() => setIsGenerateReportModalOpen(true)}
        />
      </>
    );
  }, [selectedIds]);

  return (
    <Wrapper>
      <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
        <GenerateReportModal
          title='Generate Deployment Report'
          visible={isGenerateReportModalOpen}
          setVisible={setIsGenerateReportModalOpen}
          onClick={onGenerateReportFormSubmit}
        />
        <NewDeploymentModal visible={isNewModalVisible} setVisible={setIsNewModalVisible} />
        <TableHeaderWrapper>
          <Title>Deployments</Title>
          <CommonButton disabled={false} label='+ Add Deployment' onClick={() => setIsNewModalVisible(true)} />
        </TableHeaderWrapper>
        <RecordTable
          data={tableRows}
          header={Header}
          setSearch={setSearchParams}
          pagination={paginationAndSorting}
          total={response?.total ?? 0}
          isLoading={isLoading}
          actions={actions}
          columns={COLUMNS}
          isSelected={isSelected}
          toggleSelected={toggleSelected}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      </Permission>
    </Wrapper>
  );
};
