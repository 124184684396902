import React, { useCallback, useState } from 'react';
import { ToolTip } from '../../../ToolTip';
import { CheckBox } from '../../parts/CheckBox';
import { RawUserData } from '../../../../hooks/api/Users/queries';
import styled, { css } from 'styled-components';
import { SearchInput } from '../../../Input/SearchInput';
import { colors } from '../../../../theme';
import { RollioTheme } from '../../../../theme/RollioTheme';

interface BasicTableProps {
  users: RawUserData[];
  onRowSelect: (userId: number) => void;
  checkedEntities: number[];
  deploymentId: string;
}

interface RowProps {
  user: RawUserData;
  disabled?: boolean;
  checked: boolean;
  onRowSelect: (userId: number) => void;
}

const TableContainer = styled.table`
  border: none;
  width: 100%;
  border-collapse: collapse;
  padding-right: 0;
  padding-bottom: 20px;
`;

const TableRow = styled.tr<{ disabled: boolean }>(({ disabled }) => {
  return css`
    max-height: 40px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    border: none;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.layers.first};
  `;
});

const TableData = styled.td<{ disabled: boolean; theme: RollioTheme }>(
  ({ disabled, theme }) => css`
    padding: 8px;
    text-align: left;
    font-family: 'Open Sans', serif;
    color: ${disabled ? theme.texts.disabled : theme.texts.primary};
    font-style: ${disabled ? 'italic' : 'normal'};
  `
);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.layers.first};
  overflow-y: auto;
  flex-direction: column;
  border-radius: 0 0 4px 4px;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.disabledGrey};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.layers.first};
  }
`;

const TableHead = styled.div`
  max-height: 50px;
  width: 100%;
  display: flex;
  flex: 1;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.layers.second};
  border-radius: 5px 5px 0 0;
  color: ${({ theme }) => theme.texts.primary};
  font-weight: bold;
  font-family: 'Open Sans', serif;
`;

const WrapperTable = styled.div`
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding-bottom: 3px;
  flex-direction: column;
  max-width: 400px;
  max-height: 550px;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  border: none;
  padding-left: 20px;
  border-radius: 0;
  border-left: 2px solid ${({ theme }) => theme.layers.first};
  border-right: 2px solid ${({ theme }) => theme.layers.first};
`;
const Row: React.FC<RowProps> = ({ user, checked, onRowSelect, disabled }) => {
  const getLabel = () => {
    if (user.deployments?.length && !!user.deployments[0]) {
      const { name } = user.deployments[0];
      return <ToolTip message={`User is assigned to ${name} deployment`}>{user.email}</ToolTip>;
    }

    return user.email;
  };

  return (
    <TableRow disabled={disabled} onClick={() => onRowSelect(user.id)}>
      <th scope='row' style={{ paddingLeft: 20 }}>
        <CheckBox disabled={disabled} checked={checked} />
      </th>
      <TableData disabled={disabled}>{getLabel()}</TableData>
    </TableRow>
  );
};

const BasicTable: React.FC<BasicTableProps> = ({ users, onRowSelect, checkedEntities, deploymentId }) => (
  <React.Fragment>
    <tbody>
      <tr>
        <td colSpan={2}>
          <TableContainer>
            <tbody>
              {users.map((user: RawUserData) => (
                <Row
                  key={user.salesforce_id}
                  onRowSelect={() => onRowSelect(user.id)}
                  disabled={user?.deployments?.length && user?.deployments[0]?.id.toString() !== deploymentId}
                  checked={checkedEntities.some(id => user.id === id)}
                  user={user}
                />
              ))}
            </tbody>
          </TableContainer>
        </td>
      </tr>
    </tbody>
  </React.Fragment>
);

export const TransferTable = ({
  title,
  users,
  onSelection,
  onSelectAll,
  setSearchResults,
  logo,
  selectedUserIds,
  deploymentId
}) => {
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAllChange = (isChecked: boolean) => {
    setIsAllSelected(isChecked);
    onSelectAll(isChecked);
  };

  const search = useCallback((searchTerm: SearchParams) => setSearchResults(searchTerm.text), [setSearchResults]);

  return (
    <WrapperTable>
      <TableHead>
        <CheckBox checked={isAllSelected} color='white' onChange={() => handleSelectAllChange(!isAllSelected)} />
        <TitleWrapper>{title}</TitleWrapper>
        {logo}
      </TableHead>
      <StyledSearchInput onChange={search} placeholder='Search users' />
      <Wrapper>
        <TableContainer>
          <BasicTable
            users={users}
            onRowSelect={onSelection}
            checkedEntities={selectedUserIds}
            deploymentId={deploymentId}
          />
        </TableContainer>
      </Wrapper>
    </WrapperTable>
  );
};
