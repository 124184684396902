import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { CommonButton } from '../../Buttons/CommonButton';
import { Rule } from './Parts/Rule';
import { colors } from '../../../theme';

const Subtitle = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: 200;
  color: ${colors.greyLight};
  line-height: 10px;
  padding: 0;
  text-align: center;
  font-size: 12px;
  margin: 0 0 10px 0;
`;

const RuleEditor = ({ rules, setRules, showActions = true, message = '' }) => {
  const addRule = () => {
    setRules([
      ...rules,
      {
        id: nanoid(8),
        conditions: [],
        actions: []
      }
    ]);
  };
  const updateRule = (id, updatedRule) => {
    setRules(rules.map(rule => (rule.id === id ? updatedRule : rule)));
  };

  const deleteRule = ruleId => {
    setRules(rules.filter(rule => rule.id !== ruleId));
  };

  return (
    <>
      {!!message && <Subtitle>{message}</Subtitle>}
      {rules.map(rule => (
        <Rule key={rule.id} rule={rule} showActions={showActions} onUpdate={updateRule} onDeleteRule={deleteRule} />
      ))}
      <CommonButton style={{ width: '100%' }} onClick={addRule} color='darkPurple' label='Add Logic rule' />
    </>
  );
};

export default RuleEditor;
