import React, { HTMLInputTypeAttribute, useMemo } from 'react';
import { useField } from 'formik';
import { CommonInput } from '../../Input/CommonInput';

interface CustomInputProps {
  name: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  placeholder?: string;
}

const FormInput: React.FC<CustomInputProps> = ({ name, disabled, placeholder = '', type = 'text' }) => {
  const [field] = useField(name);
  const value = useMemo(() => field.value ?? '', [field.value]);

  return (
    <CommonInput
      {...field}
      type={type}
      isDisabled={disabled}
      onChangeText={field.onChange}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default FormInput;
