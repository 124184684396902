import React from 'react';
import styled, { useTheme } from 'styled-components';
import { BaseIconProps } from '../../Icon/BaseIcon';
import { Title1, Title3 } from '../../../theme/typography/Text';

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.FC<BaseIconProps>;
  value?: string;
  name?: string;
  id?: string;
}

const StyledBadge = styled.div`
  background-color: ${({ theme }) => theme.layers.first};
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px 8px 8px 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: -30px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledHeader = styled(Title1)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  margin: 0;
`;

const StyledText = styled(Title3)`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`;

const IconWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layers.second};
  height: 55px;
  display: flex;
  width: 55px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 40px 40px 40px 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Badge: React.FC<BadgeProps> = ({ id, value, name, icon: BadgeIcon }) => {
  const theme = useTheme();

  return (
    <StyledBadge key={id}>
      <Wrapper>
        <IconWrapper>
          <BadgeIcon color={theme.icons.primary} />
        </IconWrapper>
        <InfoWrapper>
          <StyledText>{name}</StyledText>
          <StyledHeader>{value}</StyledHeader>
        </InfoWrapper>
      </Wrapper>
    </StyledBadge>
  );
};
