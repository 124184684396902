import { ToggleSwitch } from '../../../ToggleSwitch';
import React from 'react';
import { GroupFieldWrapper, SingleLineWrapper, Wrapper } from './Common';
import { FieldLabel } from '../../Common';

export const DatePanel = ({ value, setData }) => {
  const { datetime } = value;

  return (
    <Wrapper>
      <FieldLabel label='Date Settings' info='Select the object name' />
      {!!value && (
        <GroupFieldWrapper>
          <SingleLineWrapper>
            <FieldLabel label='Date & Time?' info='Select the object name' />
            <ToggleSwitch
              on={!!datetime}
              handleToggle={value => setData('datetime', value.target.checked)}
              name={'datetime'}
              isDisabled={false}
            />
          </SingleLineWrapper>
        </GroupFieldWrapper>
      )}
    </Wrapper>
  );
};
