import { SearchInput } from '../../Input/SearchInput';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Body } from '../../../theme/typography/Text';

interface HeaderProps {
  selected?: number;
  setSearch?: Dispatch<SetStateAction<SearchParams>>;
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  height: 80px;
  padding: 20px;
`;

const ChildrenContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const TableHeader: React.FC<HeaderProps> = ({ selected, setSearch, children }) => {
  return (
    <Container>
      <ChildrenContainer>
        {setSearch ? <SearchInput placeholder='Search Here' onChange={setSearch} /> : null}
        {selected > 0 ? <Body>{selected} selected</Body> : null}
      </ChildrenContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
