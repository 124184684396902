import React, { useEffect, useState } from 'react';
import { CommonInput } from '../../Input/CommonInput';
import styled from 'styled-components';
import { CommonButton } from '../../Buttons/CommonButton';
import { FieldLabel, FieldWrapper } from '../Common';
import RuleEditor from '../RuleEditor';

interface NodeObject {
  data: any;
  id?: number;
}

interface PanelProps {
  node: NodeObject;
  saveNode: (id, data) => void;
}

const GroupFieldWrapper = styled.div`
  margin: 5px 0 5px 0;
  padding: 15px;
  width: 100%;
  border: 1px solid rgba(71, 20, 125, 0.1);
  background-color: #f2f2f2;
  border-radius: 5px;
`;

export const DecisionPanel: React.FC<PanelProps> = ({ node, saveNode }) => {
  if (!node?.data) {
    return;
  }

  const [nodeData, setNodeData] = useState(node.data);
  const [logicalRules, setLogicalRules] = useState([]);
  useEffect(() => {
    setNodeData(node.data);
  }, [node]);

  const save = () => {
    saveNode(node.id, nodeData);
  };

  const handleInputChange = (id, value) => {
    setNodeData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  return (
    <ul>
      <li key='test1'>
        <FieldWrapper>
          <FieldLabel label='Name' info='Select the object name' />
          <CommonInput
            name='title'
            style={{ width: 400 }}
            value={nodeData.title}
            onChange={value => handleInputChange('title', value)}
            placeholder='Enter the slot name.'
          />
        </FieldWrapper>
        <FieldWrapper>
          <GroupFieldWrapper>
            <RuleEditor showActions={false} rules={logicalRules} setRules={setLogicalRules} />
          </GroupFieldWrapper>
        </FieldWrapper>
        <div style={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CommonButton style={{ height: 50, width: 150 }} onClick={save} label='Save' />
        </div>
      </li>
    </ul>
  );
};
