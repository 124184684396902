import React from 'react';
import styled from 'styled-components';
import UserPlaceholder from '../../../assets/avatar-gfacb9c7f1_640.png';

interface UserInfoProps {
  name: string;
  role: string;
  avatar?: string;
}

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
`;

const NameLabel = styled.p`
  font-family: Lato, serif;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  margin: 0;
  font-size: 16px;
`;

const UserPhoto = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 25px;
`;

const RoleLabel = styled.p`
  font-family: Lato, serif;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;
const UserBadgeWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const UserInfo: React.FC<UserInfoProps> = ({ name, role, avatar }) => {
  const userPhoto = avatar || UserPlaceholder;

  return (
    <Wrapper>
      <UserBadgeWrapper>
        <NameLabel>{name}</NameLabel>
        <RoleLabel>{role}</RoleLabel>
      </UserBadgeWrapper>
      <UserPhoto src={userPhoto} />
    </Wrapper>
  );
};
