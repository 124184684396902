import React from 'react';
import { pathToRegexp } from 'path-to-regexp';

import { ACTION, RESOURCE, ROLE } from 'src/constants';
import { Administrator, Dashboard, Datasource, Deployment, Login, Organization, Organizations } from 'src/pages';

import { Datasources } from '../pages/Datasources/Datasources';
import { Users } from '../pages/Users/Users';
import { Conversation } from '../pages/Conversations/Conversation';
import { IconOrganization } from '../components/Icon/IconOrganization';
import { IconUser } from '../components/Icon/IconUser';
import { IconFlow } from '../components/Icon/IconFlow';
import { IconDashboard } from '../components/Icon/IconDashboard';
import { IconDeployment } from '../components/Icon/IconDeployment';
import { IconDatasource } from '../components/Icon/IconDatasource';
import { IconAdministrator } from '../components/Icon/IconAdministrator';
import { BaseIconProps } from '../components/Icon/BaseIcon';
import { Administrators } from '../pages/Administrators/Administrators';
import { Conversations } from '../pages/Conversations/Conversations';
import { Deployments } from '../pages/Deployments/Deployments';

interface MatchParam {
  match: boolean;
  url: string;
  params: Record<string, any>;
}

interface MatchParamAll extends MatchParam {
  exactMatch: boolean;
}

export interface Route {
  path: string;
  id: string;
  label: string;
  LabelComponent?: React.FC;
  Component?: React.FC;
  icon?: React.FC<BaseIconProps>;
  roles?: ROLE[];
  permission?: { resource: RESOURCE; action: ACTION };
}

export const ROUTES = {
  home: 'home',
  login: 'login',
  organizations: 'organizations',
  deployments: 'deployments',
  conversations: 'conversations',
  conversation: 'conversation',
  addDeployment: 'addDeployment',
  addDatasource: 'addDatasource',
  addOrganization: 'addOrganization',
  organizationDetail: 'organizationDetail',
  data: 'data',
  users: 'users',
  administrators: 'administrators',
  addAdministrator: 'addAdministrator',
  detailAdministrator: 'detailAdministrator',
  deploymentDetail: 'deploymentDetail',
  organization: 'organization',
  deployment: 'deployment',
  datasource: 'datasource',
  configurations: 'configurations',
  configurationDetail: 'configurationDetail',
  instance: 'instance',
  datasources: 'datasources',
  instanceDetail: 'instanceDetail',
  flows: 'flows',
  flowDetail: 'flowDetail'
};

export const routes: Route[] = [
  {
    path: '/',
    id: ROUTES.home,
    label: 'Dashboard',
    Component: Dashboard,
    icon: IconDashboard
  },
  { path: '/login', id: ROUTES.login, label: 'Login', Component: Login },
  {
    path: '/organizations',
    id: ROUTES.organizations,
    label: 'Organizations',
    Component: Organizations,
    icon: IconOrganization,
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ }
  },
  {
    path: '/organizations/:organizationId',
    id: ROUTES.organizationDetail,
    label: 'Organization',
    Component: Organization,
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ }
  },
  {
    path: '/deployments/:deploymentId',
    id: ROUTES.deploymentDetail,
    label: 'Deployment',
    Component: Deployment,
    roles: [ROLE.INTERNAL, ROLE.ORGANIZATION],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/datasources/:datasourceId',
    id: ROUTES.datasource,
    label: 'Datasource',
    Component: Datasource,
    roles: [ROLE.INTERNAL, ROLE.ORGANIZATION],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/conversations/:convId',
    id: ROUTES.conversation,
    label: 'Conversations',
    icon: IconFlow,
    Component: Conversation,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT, ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/conversations',
    id: ROUTES.conversations,
    label: 'Conversations',
    icon: IconFlow,
    Component: Conversations,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT, ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/deployments',
    id: ROUTES.deployments,
    label: 'Deployments',
    icon: IconDeployment,
    Component: Deployments,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT, ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/datasources',
    id: ROUTES.datasources,
    label: 'Datasources',
    icon: IconDatasource,
    Component: Datasources,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT, ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/users',
    id: ROUTES.users,
    label: 'Users',
    icon: IconUser,
    Component: Users,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT, ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ }
  },
  {
    path: '/administrators',
    id: ROUTES.administrators,
    label: 'Administrators',
    icon: IconAdministrator,
    Component: Administrators,
    roles: [ROLE.ORGANIZATION, ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ }
  },
  {
    path: '/administrators/:userId',
    id: ROUTES.detailAdministrator,
    label: 'Detail Administrator',
    Component: Administrator,
    roles: [ROLE.ORGANIZATION, ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ }
  }
];

export const getRouteById = (id: string): Route => routes.find(route => route.id === id);

export const getRouteUrlById = (id: string, params: Record<string, any> = {}): string => {
  const { path } = getRouteById(id);
  return Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), path);
};

const matchUrlWithPath = (url: string, path: string): MatchParam => {
  const keys = []; //keys are populated by pathToRegexp
  const regexp = pathToRegexp(path, keys);
  const match = regexp.exec(url);

  if (!match) {
    return { match: false, url, params: {} };
  }

  const [matchUrl, ...values] = match;
  const params = keys.reduce((acc, key, index) => ({ ...acc, [key.name]: values[index] }), {});

  return { match: true, url: matchUrl, params };
};

const matchUrlWithPathAll = (url: string, path: string): MatchParamAll => {
  const parts = url.split('/');
  const urlsToMatch = parts.map((_part, index) => parts.slice(0, parts.length - index).join('/') || '/');
  return urlsToMatch.reduce(
    (acc, urlToCheck) => {
      if (!acc.match) {
        const { match, params } = matchUrlWithPath(urlToCheck, path);
        acc = {
          match,
          url: urlToCheck,
          params,
          exactMatch: url === urlToCheck
        };
      }
      return acc;
    },
    { match: false, url, params: {} } as MatchParamAll
  );
};

interface RouteWithMatchParam extends Route, Pick<MatchParam, 'match' | 'params'> {}

export const getBreadcrumbs = (url: string): RouteWithMatchParam[] => {
  return routes.reduce((acc, route) => {
    const { path } = route;
    const { match, params, exactMatch } = matchUrlWithPathAll(url, path);
    return match ? [...acc, { ...route, match: exactMatch, params }] : acc;
  }, []);
};

export const getSidebarItems = (url: string, routeIds: string[]): RouteWithMatchParam[] => {
  return routeIds.map(id => {
    const route = getRouteById(id);
    const { match, params, exactMatch } = matchUrlWithPathAll(url, route.path);
    return {
      ...route,
      match: id === ROUTES.home ? exactMatch : match,
      params
    };
  });
};

export const getRouteByUrl = (url: string) => {
  return routes.find(route => {
    const { match } = matchUrlWithPath(url, route.path);
    return match;
  });
};
