import React from 'react';
import { ToolTip } from './ToolTip';
import { IconInfo } from './Icon/IconInfo';

export const InfoBadge = ({ message }) => (
  <div style={{ height: 25, width: 25, marginLeft: 5 }}>
    <ToolTip message={message}>
      <IconInfo />
    </ToolTip>
  </div>
);
