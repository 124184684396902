import React from 'react';
import { ActivitySubtitle, GroupFieldWrapper, SingleLineWrapper, Wrapper } from './Common';
import { FieldLabel } from '../../Common';

export const TextPanel = ({ value, setData }) => {
  return (
    <Wrapper>
      <FieldLabel label='Text Settings' info='Select the object name' />
      {!!value && (
        <GroupFieldWrapper>
          <SingleLineWrapper>
            <ActivitySubtitle>Currently there are no available settings.</ActivitySubtitle>
          </SingleLineWrapper>
        </GroupFieldWrapper>
      )}
    </Wrapper>
  );
};
