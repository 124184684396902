import styled from 'styled-components';
import { InfoBadge } from '../InfoBadge';
import React from 'react';

export const Label = styled.label`
  font-family: 'Open Sans', serif;
  display: inline-block;
  font-size: 18px;
  color: black;
  margin: 0 5px 5px 0;
`;

export const FieldWrapper = styled.div`
  margin: 15px 0 5px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const SingleLineWrapper = styled.div`
  display: flex;
  margin: 15px 0 5px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FieldLabel = ({ label, info }) => (
  <LabelWrapper>
    <Label>{label}</Label>
    <InfoBadge message={info} />
  </LabelWrapper>
);
