import dagre from 'dagre';

const nodeWidth = 160;
const nodeHeight = 45;

export const NODE_TYPES = {
  INITIAL: 'initial',
  ENTITY: 'entity',
  DECISION_POINT: 'decision',
  BOT_ACTION: 'bot',
  CONFIRMATION: 'end'
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const addNodesToGraph = nodes =>
  nodes.forEach(node =>
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight
    })
  );

const addEdgesToGraph = edges => edges.forEach(edge => dagreGraph.setEdge(edge.source, edge.target));

export const removeEdgesFromGraph = edgesToRemove => {
  edgesToRemove.forEach(edge => {
    dagreGraph.removeEdge(edge.source, edge.target);
  });
};

export const resetDagreGraph = () => {
  dagreGraph.nodes().forEach(node => dagreGraph.removeNode(node));

  dagreGraph.setGraph({});
  dagreGraph.setDefaultEdgeLabel(() => ({}));
};

export const deleteNodeFromDagreGraph = nodeId => dagreGraph.removeNode(nodeId);

const updateNodePositions = nodes =>
  nodes.map(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'top';
    node.sourcePosition = 'bottom';
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    return node;
  });

export const findPathEdges = (edges, initialNodeId, selectedNodeId) => {
  const visitedNodes = new Set();
  const path = [];

  const dfs = currentNodeId => {
    if (visitedNodes.has(currentNodeId)) {
      return false;
    }
    if (currentNodeId === selectedNodeId) {
      return true;
    }

    visitedNodes.add(currentNodeId);

    const outgoingEdges = edges.filter(edge => edge.source === currentNodeId);

    for (const edge of outgoingEdges) {
      if (dfs(edge.target)) {
        path.push(edge.id);
        return true;
      }
    }

    return false;
  };

  dfs(initialNodeId);
  return path;
};

export const getLayoutElements = (nodes, edges, edgesToRemove = []) => {
  dagreGraph.setGraph({ rankdir: 'TB' });

  addNodesToGraph(nodes);
  addEdgesToGraph(edges);
  removeEdgesFromGraph(edgesToRemove);
  dagre.layout(dagreGraph);

  const updatedNodes = updateNodePositions(nodes);

  return { nodes: updatedNodes, edges };
};

export const isLeafNode = (nodeId, edges) => {
  const hasChild = edges.some(edge => edge.source === nodeId);

  return !hasChild;
};
