import styled from 'styled-components';
import { colors } from '../../../../theme';
import { IconRobot } from '../../../Icon/IconRobot';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  font-family: 'Open Sans', serif;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0 0 20px 0;
  color: ${colors.auxiliaryBlue};
  line-height: 1.3rem;
`;

export const NoSelection = () => {
  return (
    <Wrapper>
      <Title>Click on a slot to open the edit menu</Title>
      <IconRobot />
    </Wrapper>
  );
};
