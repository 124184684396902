import { useMemo, useState } from 'react';
import { SortDirectionType } from '../parts/SortButton';

interface SortState {
  column: string;
  direction: SortDirectionType;
}

interface PaginationState {
  offset: number;
  limit: number;
}

export interface TableSortingType {
  limits: number[];
  pagination: PaginationState;
  sorting: SortState;
  currentPage: number;
  setOffset: (offset: number) => void;
  setLimit: (limit: number) => void;
  updatePagination: (offset: number, limit: number) => void;
  updateSorting: (column: string, direction: SortDirectionType) => void;
  reset: () => void;
}

export const usePaginationAndSorting = (limits = [20, 50, 100]): TableSortingType => {
  const [pagination, setPagination] = useState<PaginationState>({
    offset: 0,
    limit: limits[0]
  });
  const [sorting, setSorting] = useState<SortState>({
    column: '',
    direction: 'OFF'
  });
  const currentPage = useMemo(() => {
    return Math.floor(pagination.offset / pagination.limit) + 1;
  }, [pagination.offset, pagination.limit]);

  const updatePagination = (offset: number, limit: number) => {
    setPagination({ offset, limit });
  };

  const setOffset = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  const setLimit = (limit: number) => {
    setPagination({ ...pagination, limit });
  };

  const updateSorting = (column: string, direction: SortDirectionType) => {
    setSorting({ column, direction });
  };

  const reset = () => {
    setSorting({
      column: '',
      direction: 'OFF'
    });
    setPagination({
      offset: 0,
      limit: limits[0]
    });
  };

  return {
    limits,
    pagination,
    updatePagination,
    sorting,
    currentPage,
    setOffset,
    setLimit,
    updateSorting,
    reset
  };
};
