import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserProfile } from '../../../hooks';
import { usePaginationAndSorting } from '../../../components/Table/hooks';
import { CommonButton } from '../../../components/Buttons/CommonButton';
import { RecordTable, Row } from '../../../components/Table';
import { getUserName } from '../../../utils';
import { TableHeaderWrapper } from '../../Common';
import { ColorName } from '../../../theme';
import { ROUTES } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../../store/reducers/ui';
import { useDispatch } from 'react-redux';
import { IconEye } from '../../../components/Icon/IconEye';
import { IconReset } from '../../../components/Icon/IconReset';
import { IconTrash } from '../../../components/Icon/IconTrash';
import { booleanCellFormatter } from '../../../components/Table/utils';
import {
  useAdministratorsUsersData,
  useDeleteAdministrators,
  useResetPassword
} from '../../../hooks/api/Administrators/hooks';
import { RawAdministratorData } from '../../../hooks/api/Administrators/queries';
import { Title3 } from '../../../theme/typography/Text';
import { AddAdministratorModal } from '../../Administrators/AddAdministratorModal';

interface AdminComponentProps {
  organizationId: string;
}

export interface AdministratorRow extends Row {
  email: string;
  is_active: boolean;
}

const COLUMNS = [
  { key: 'name', label: 'Name', sortable: true },
  { key: 'email', label: 'Email' },
  { key: 'is_active', label: 'Active', formatter: booleanCellFormatter }
];

const AdminManagement: React.FC<AdminComponentProps> = ({ organizationId }) => {
  const paginationAndSorting = usePaginationAndSorting();
  const navigate = useNavigate();
  const { id } = useUserProfile();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState<SearchParams>({ text: '' });
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const { mutate: deleteAdmin } = useDeleteAdministrators();
  const { mutate: resetPassword } = useResetPassword();
  const queryFilter = useMemo(() => ({ organization_id: [organizationId] }), [organizationId]);
  const userPagination = useMemo(
    () => ({
      ...paginationAndSorting.pagination,
      sort: paginationAndSorting.sorting
    }),
    [paginationAndSorting]
  );
  const [tableRows, setTableRows] = useState([]);
  const { isLoading, data: users } = useAdministratorsUsersData(userPagination, searchParams, queryFilter);

  const parseUsersToTableData = useCallback(
    (data: RawAdministratorData[]) =>
      data?.length
        ? data.map(({ id, first_name, last_name, email, is_active }) => ({
          id,
          name: getUserName(first_name, last_name),
          email,
          is_active
        }))
        : [],
    []
  );

  useEffect(() => {
    if (users?.data?.length) {
      setTableRows(parseUsersToTableData(users.data));
    } else {
      setTableRows([]);
    }
  }, [users]);

  const handleReset = useCallback((row: AdministratorRow) => resetPassword(row.email), [resetPassword]);

  const actions = useMemo(
    () => [
      {
        name: 'Detail',
        icon: IconEye,
        color: 'darkPurple' as ColorName,
        onClick: (row: AdministratorRow) => {
          navigate(`/${ROUTES.administrators}/${row.id}`);
        }
      },
      {
        name: 'Reset Password',
        icon: IconReset,
        onClick: handleReset
      },
      {
        name: 'Delete',
        icon: IconTrash,
        onClick: (row: AdministratorRow) =>
          dispatch(
            setAlert({
              title: 'Delete deployments',
              message: 'Are you sure you want to delete the selected deployments?',
              isOpen: true,
              positiveButton: {
                title: 'Confirm and Delete',
                onClick: () => deleteAdmin([row.id])
              }
            })
          ),
        isDisabled: (row: AdministratorRow) => id === row.id
      }
    ],
    [navigate, handleReset, id, deleteAdmin]
  );

  return (
    <>
      <TableHeaderWrapper>
        <Title3>Administrators</Title3>
        <CommonButton label='Add Administrator' onClick={() => setShowAddAdminModal(true)} />
      </TableHeaderWrapper>
      <AddAdministratorModal
        visible={showAddAdminModal}
        setVisible={setShowAddAdminModal}
        defaultOrg={organizationId}
      />
      <RecordTable
        data={tableRows}
        setSearch={setSearchParams}
        pagination={paginationAndSorting}
        total={users?.total}
        isLoading={isLoading}
        actions={actions}
        columns={COLUMNS}
        selectable={false}
      />
    </>
  );
};

export default AdminManagement;
