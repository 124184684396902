import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageWrap } from 'src/components/PageWrap';

import { usePermission } from '../../hooks';
import { formatAdministrator } from '../../parser';
import { useAdministratorDetail, useEditAdministrator } from '../../hooks/api/Administrators/hooks';
import GenericForm, { FieldStructure } from '../../components/Forms/Form';
import { user } from '@rollioforce/rollio-schemas';
import { RawAdministratorData } from '../../hooks/api/Administrators/queries';
import Spinner from '../../components/Spinner';

export const Administrator: React.FC = () => {
  const { isInternal } = usePermission();
  const { userId } = useParams();
  const { data: initialData, isLoading } = useAdministratorDetail(userId);
  const { mutate: editRecord } = useEditAdministrator();

  const onFormSubmit = useCallback(
    (data: RawAdministratorData) => {
      try {
        editRecord({ id: userId, body: formatAdministrator(data) });
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [editRecord, userId]
  );

  const FORM_FIELDS: FieldStructure[] = useMemo(
    () => [
      {
        key: 'first_name',
        label: 'First Name',
        type: 'text'
      },
      {
        key: 'last_name',
        label: 'Last Name',
        type: 'text'
      },
      {
        key: 'profile_image_url',
        label: 'Profile Picture',
        placeholder: 'E.g. https://example.com/image.jpg',
        type: 'text'
      },
      {
        key: 'email',
        label: 'Email',
        placeholder: 'Enter the administrator mail',
        type: 'text'
      }
    ],
    [isInternal]
  );

  return (
    <PageWrap>
      {!isLoading ? (
        <GenericForm
          name={`edit_administrator_${userId}`}
          title={`Administrator ${initialData?.first_name} ${initialData?.last_name}`}
          fields={FORM_FIELDS}
          isLoading={isLoading}
          columns={3}
          schema={user}
          initialValues={initialData}
          onSubmit={onFormSubmit}
        />
      ) : (
        <Spinner fullscreen />
      )}
    </PageWrap>
  );
};
