import jwtDecode from 'jwt-decode';
import { format } from 'date-fns';

import { DATE_FORMATS, SLOT_OPTION } from 'src/constants';
import { OrganizationWithInstancesType } from '../hooks/api/Organizations/types';

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const openPopup = (url: string): Window | null => {
  const width = 500;
  const height = 580;
  const top = window.screen.height / 2 - height / 2;
  const left = window.screen.width / 2 - width / 2;

  const options = `width=${width},height=${height},top=${top},left=${left}`;

  return window.open(url, '', options);
};

export const decodeAuthToken = (authToken): DecodedAuthToken | null => {
  try {
    return jwtDecode(authToken);
  } catch (e) {
    return null;
  }
};

export const getProfileFromToken = (authToken: string) => {
  const decodedToken = decodeAuthToken(authToken);
  return decodedToken?.user;
};

export const verifyAuthToken = authToken => {
  const profile = getProfileFromToken(authToken);
  return profile?.salesforce_id ?? false;
};

export const formatDate = (value: string | Date | number, dateFormat: string = DATE_FORMATS.DEFAULT): string => {
  const date = typeof value === 'string' ? new Date(value) : value;
  return format(date, dateFormat);
};

export const parseOrganizationToSelect = (organizations: OrganizationWithInstancesType[], isInternal: boolean) => {
  const mapOption = (d, organizationName) => ({
    label: `${d.name} ${isInternal && `[${d.id}]`} - ${organizationName}`,
    value: d.id.toString()
  });

  return organizations.map(organization => {
    const { name, instances } = organization;
    return {
      label: name,
      options: instances.map(instance => {
        return mapOption(instance, name);
      })
    };
  });
};

export const getSlotsDataFromTemplate = (template: string) => {
  const markups = template.match(/@\[(.*?)\]\((.*?)\)/g);

  return (markups || []).map(markup => ({
    id: markup.match(/\((.*?)\)/)[1],
    name: markup.match(/\[(.*?)\]/)[1]
  }));
};

export const sliceText = (text: string, limit: number) => {
  return text?.length < limit ? text : text.slice(0, limit).concat('...');
};

export const findSlot = (slots: SlotType[], node_path: string): SlotType =>
  slots.reduce((acc: SlotType, slot) => {
    if (slot.node_path === node_path) {
      return slot;
    }
    if (slot.condition_true) {
      const slotT = findSlot(slot.condition_true, node_path);
      if (slotT) {
        return slotT;
      }
    }
    if (slot.condition_false) {
      const slotF = findSlot(slot.condition_false, node_path);
      if (slotF) {
        return slotF;
      }
    }
    return acc;
  }, null);

export const updateSlot = (slots: SlotType[], node_path: string, payload: Partial<SlotType>): SlotType[] =>
  slots.map(slot => {
    if (slot.node_path === node_path) {
      return { ...slot, ...payload };
    }
    if (slot.condition_true) {
      slot.condition_true = updateSlot(slot.condition_true, node_path, payload);
    }
    if (slot.condition_false) {
      slot.condition_false = updateSlot(slot.condition_false, node_path, payload);
    }
    return slot;
  });

export const insertBeforeSlot = (slots: SlotType[], node_path: string, newSlot: SlotType): SlotType[] =>
  slots.reduce((acc: SlotType[], slot) => {
    if (slot.condition_true) {
      slot.condition_true = insertBeforeSlot(slot.condition_true, node_path, newSlot);
    }
    if (slot.condition_false) {
      slot.condition_false = insertBeforeSlot(slot.condition_false, node_path, newSlot);
    }
    if (slot.node_path === node_path) {
      acc.push(newSlot);
    }
    acc.push(slot);
    return acc;
  }, []);

export const deleteSlot = (slots: SlotType[], node_path: string): SlotType[] =>
  slots.reduce((acc: SlotType[], slot) => {
    if (slot.condition_true) {
      slot.condition_true = deleteSlot(slot.condition_true, node_path);
    }
    if (slot.condition_false) {
      slot.condition_false = deleteSlot(slot.condition_false, node_path);
    }
    if (slot.node_path !== node_path) {
      acc.push(slot);
    }
    return acc;
  }, []);

export const reorderSlots = (slots: SlotType[], startIndex: number, endIndex: number) => {
  const result = Array.from(slots);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const updateNodePaths = (slots: SlotType[], parent?: SlotType, condition?: boolean): SlotType[] =>
  slots.map((slot, i) => {
    slot.node_path = parent ? `${parent.node_path}.${String(i + 1)}${condition ? 'T' : 'F'}` : String(i + 1);
    if (slot.condition_true) {
      slot.condition_true = updateNodePaths(slot.condition_true, slot, true);
    }
    if (slot.condition_false) {
      slot.condition_false = updateNodePaths(slot.condition_false, slot, false);
    }
    return slot;
  });

export const makeInternalId = (): string => Math.random().toString(36)
  .substring(2);

export const makeNewSlot = (type: SLOT_OPTION, nodePath: string): SlotType => ({
  type,
  node_path: nodePath,
  name: '',
  internal_id: makeInternalId()
});
