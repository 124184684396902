import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSfTokens, setSfToken as setAuthToken } from 'src/store/actions';
import { auth, utils } from 'src/services';
import { getSfTokens } from '../store/reducers/user';
import { decodeAuthToken } from '../services/utils';

export const useSfAuth = (instanceId?: string) => {
  const dispatch = useDispatch();
  const sfTokens = useSelector(getSfTokens);
  const sfToken = useMemo(() => {
    if (sfTokens && instanceId) {
      return sfTokens[instanceId];
    }
    return null;
  }, [instanceId, sfTokens]);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState('');
  const callback = useRef(null);

  const isLoggedIn = useMemo(() => instanceId && sfTokens && !!sfTokens[instanceId], [instanceId, sfTokens]);

  const updateToken = useCallback(
    (newAuthToken: string) => {
      if (instanceId) {
        dispatch(setAuthToken({ instanceId, token: newAuthToken }));
      }
    },
    [dispatch, instanceId]
  );

  const setCallback = cb => {
    callback.current = cb;
  };

  const runCallback = useCallback((success: boolean, data?: string) => {
    if (callback.current) {
      callback.current(success, data);
      callback.current = null;
    }
  }, []);

  const login = useCallback(
    async (username: string, deploymentId?: number, cb = null) => {
      setLoginError('');
      setIsLoggingIn(true);
      setCallback(cb);
      const popupUrlResponse = await auth.getRedirectUrl(username, true, deploymentId);
      if (popupUrlResponse.success) {
        const popupWindow = utils.openPopup(popupUrlResponse.url);
        const interval = setInterval(() => {
          if (popupWindow.closed) {
            setIsLoggingIn(false);
            clearInterval(interval);
            runCallback(false);
          }
        }, 500);
        return popupWindow;
      }
      setIsLoggingIn(false);
      runCallback(false, popupUrlResponse.message);
      setLoginError(popupUrlResponse.message);
    },
    [runCallback]
  );

  const user = useMemo(() => {
    if (sfToken) {
      const { user } = decodeAuthToken(sfToken);

      return user;
    }

    return { first_name: '', last_name: '', email: '' };
  }, [sfToken]);

  const resetSfAuth = useCallback(() => dispatch(resetSfTokens()), [dispatch]);

  const sfLogout = useCallback(() => {
    if (instanceId) {
      dispatch(setAuthToken({ instanceId, token: null }));
    }
  }, [dispatch, instanceId]);

  useEffect(() => {
    const listener = event => {
      if (event?.data?.type === 'ROLLIO/AUTH_TOKEN' && utils.verifyAuthToken(event.data.payload)) {
        updateToken(event.data.payload);
        runCallback(true, event.data.payload);
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateToken, runCallback]);

  return {
    isLoggedIn,
    isLoggingIn,
    updateToken,
    user,
    sfToken,
    loginError,
    login,
    resetSfAuth,
    sfLogout
  };
};
