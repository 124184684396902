import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { CommonSelect } from '../../Select';

export interface Option {
  value: string;
  label: string;
}

interface CustomSelectFieldProps {
  name: string;
  options: Option[];
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  onChange?: (option: Option | null) => void;
}

const FormSelect: React.FC<CustomSelectFieldProps> = ({
  name,
  options,
  disabled,
  placeholder,
  onChange,
  isLoading
}) => {
  const [field, meta, helpers] = useField(name);
  const [selectedOption, setSelectedOption] = useState<Option>(
    options.find(option => option.value === field.value) ?? null
  );
  const handleChange = (selectedOption: Option | null) => {
    const selectedValue = (selectedOption as Option)?.value || '';
    setSelectedOption(selectedOption);
    helpers.setValue(selectedValue);
    onChange?.(selectedOption);
  };

  useEffect(() => {
    if (field.value) {
      setSelectedOption(options.find(option => option.value === field.value) ?? null);
    }
  }, [field.value, options]);

  return (
    <CommonSelect
      options={options}
      onChange={handleChange}
      value={selectedOption}
      disabled={disabled}
      isLoading={isLoading}
      placeholder={placeholder}
    />
  );
};

export default FormSelect;
