import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { CommonSelect } from '../Select';
import { useUserProfile } from '../../hooks';
import { ToolTip } from '../ToolTip';
import { setActiveDatasource, setActiveDeployment, setActiveOrganization } from '../../store/reducers/globalFilter';
import { useGlobalFilter } from '../../hooks/useGlobalFilter';
import { IconOrganization } from '../Icon/IconOrganization';
import { IconDatasource } from '../Icon/IconDatasource';
import { UserInfo } from '../Dashboard/UserInfo';
import { getRoleLabel, getUserName } from '../../utils';
import { useLocation } from 'react-router-dom';
import { IconDeployment } from '../Icon/IconDeployment';

interface SelectItem {
  label: string;
  value: string;
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-right: 20px;
`;

const FilterContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 3px 6px;
  justify-content: space-between;
  flex-direction: row;
  height: 80px;
  padding: 20px;
`;

export const TopBar: React.FC = () => {
  const dispatch = useDispatch();
  const { first_name, last_name, profile_image_url, roles, organization_id, organization_name } = useUserProfile();
  const location = useLocation();

  const showDatasourceComponent = ['/users', '/deployments', '/dashboard'].includes(location.pathname);
  const showDeploymentComponent = ['/users'].includes(location.pathname);

  const organization = useMemo(
    () => ({
      name: organization_name,
      id: organization_id?.toString()
    }),
    [organization_name, organization_id]
  );
  const { datasources, organizations, selectedOrg, selectedDatasource, deployments, selectedDeployment } =
    useGlobalFilter(roles[0], organization);
  const organizationsSelect = useMemo(() => {
    const parsedOrganizations = organizations
      ? [
        ...organizations.map(({ id, name }) => ({
          value: id.toString(),
          label: name
        }))
      ]
      : [];
    if (organizations.length > 1) {
      parsedOrganizations.unshift({ label: 'All Organizations', value: 'all' });
    }

    if (!parsedOrganizations.length) {
      parsedOrganizations.unshift({ label: 'No Organization', value: 'none' });
    }
    return parsedOrganizations;
  }, [organizations]);

  const datasourceSelect = useMemo(() => {
    const parsedDatasources = datasources
      ? [
        ...datasources.map(({ id, name }) => ({
          value: id.toString(),
          label: name
        }))
      ]
      : [];

    if (parsedDatasources.length > 1) {
      parsedDatasources.unshift({ label: 'All Datasources', value: 'all' });
    }

    if (!parsedDatasources.length) {
      parsedDatasources.unshift({ label: 'No Datasources', value: 'none' });
    }
    return parsedDatasources;
  }, [datasources]);

  const deploymentSelect = useMemo(() => {
    const parsedDeployments = deployments
      ? [
        ...deployments.map(({ id, name }) => ({
          value: id.toString(),
          label: name
        }))
      ]
      : [];

    if (parsedDeployments.length > 1) {
      parsedDeployments.unshift({ label: 'All Deployments', value: 'all' });
    }

    if (!parsedDeployments.length) {
      parsedDeployments.unshift({ label: 'No Deployments', value: 'none' });
    }
    return parsedDeployments;
  }, [deployments]);

  const onSelectOrganization = (value: SelectItem) => {
    dispatch(setActiveOrganization(value));
  };

  const onSelectDatasource = (value: SelectItem) => {
    dispatch(setActiveDatasource(value));
  };

  const onSelectDeployment = (value: SelectItem) => {
    dispatch(setActiveDeployment(value));
  };

  return (
    <Container>
      <FilterContainer>
        <SelectWrapper>
          <IconOrganization />
          <CommonSelect options={organizationsSelect} value={selectedOrg} onChange={onSelectOrganization} />
        </SelectWrapper>
        {datasources?.length > 0 && showDatasourceComponent ? (
          <SelectWrapper>
            <IconDatasource />
            <ToolTip
              message={
                !selectedDatasource || selectedDatasource.value === 'none'
                  ? 'This organization does not have any datasources'
                  : null
              }>
              <CommonSelect options={datasourceSelect} value={selectedDatasource} onChange={onSelectDatasource} />
            </ToolTip>
          </SelectWrapper>
        ) : null}
        {deployments?.length > 0 && showDeploymentComponent ? (
          <SelectWrapper>
            <IconDeployment />
            <ToolTip
              message={
                !selectedDeployment || selectedDeployment.value === 'none'
                  ? 'This organization does not have any deployments'
                  : null
              }>
              <CommonSelect options={deploymentSelect} value={selectedDeployment} onChange={onSelectDeployment} />
            </ToolTip>
          </SelectWrapper>
        ) : null}
      </FilterContainer>
      <UserInfo
        name={getUserName(first_name, last_name)}
        role={getRoleLabel(roles[0].name, organization_name)}
        avatar={profile_image_url}
      />
    </Container>
  );
};
